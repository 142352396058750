


import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { ProbeContext } from '../ProbeContext'

export default function Popup() {
    const { setPopup, popup } = useContext(ProbeContext)
    return(<>
        <Modal show={!!popup} onHide={()=>setPopup("")}>
            <Modal.Header closeButton>
                <b>Important!</b>
            </Modal.Header>
            <Modal.Body className="text-center overflow-hidden">
                <p className='col-lg-10 mx-auto'>{popup}</p>
                <Button onClick={()=>setPopup("")} variant="secondary" className="position-relative move-forward m-3">Continue</Button>
            </Modal.Body>
        </Modal>
    </>)
}
