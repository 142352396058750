import React from 'react';
import { Link } from 'react-router-dom';

function Help() {

    return (
        <React.Fragment>
            
            <h1 className="section-header">Help &amp; Support</h1>

            <div className="row">
                <div className="col-sm-10 offset-sm-1 col-md-6 offset-md-0">
                    <a href="https://greatleaps.com/index.php?main_page=contact_us" className="btn btn-secondary btn-xl">Customer Support</a>
                </div>

                <div className="col-sm-10 offset-sm-1 col-md-6 offset-md-0">
                    <a href="https://greatleaps.com/index.php?main_page=contact_us" className="btn btn-secondary btn-xl">Sales Support</a>
                </div>

                <div className="col-sm-10 offset-sm-1 col-md-6 offset-md-0">
                    <Link to="frequently-asked-questions" className="btn btn-secondary btn-xl d-none d-sm-inline">Frequently Asked Questions</Link>
                    <Link to="frequently-asked-questions" className="btn btn-secondary btn-xl d-block d-sm-none">FAQs</Link>
                </div>

                <div className="col-sm-10 offset-sm-1 col-md-6 offset-md-0">
                    <Link to="/account/recover" className="btn btn-secondary btn-xl">Account Recovery</Link>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Help;