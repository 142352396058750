import React from 'react';
import { Link } from 'react-router-dom';

class SendSalesCodeEmailSuccess extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="redirect-container">
                    <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                        <Link to="/admin" className="btn btn-secondary btn-lg">Return to Administration Tools</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default SendSalesCodeEmailSuccess;
