import React from 'react'
import { Link } from 'react-router-dom'
import { useFormikContext } from "formik"
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

type LoggedInProps = {
    submitting: boolean
    purchaseView: "add" | "extend" | "default"
    readingLicenses: number
    extendSubscription?: { readingLicenses?: number, mathLicenses?: number, exp: string }
}

function LoggedInLinks(props: LoggedInProps) {
    const { isSubmitting, values } = useFormikContext()
    const disabled = isSubmitting || (values["math"] === 0 && values["reading"] === 0)
    let licenseText: string
    if (props.extendSubscription?.readingLicenses > 0 && props.extendSubscription?.mathLicenses > 0) {
        licenseText = `${props.extendSubscription.readingLicenses} reading license(s) and ${props.extendSubscription.mathLicenses} math license(s)`
    } else if (props.extendSubscription?.readingLicenses > 0) {
        licenseText = `${props.extendSubscription.readingLicenses} reading license(s)`
    } else if (props.extendSubscription?.mathLicenses > 0) {
        licenseText = `${props.extendSubscription.mathLicenses} math license(s)`
    } else {
        licenseText = "0 licenses"
    }
    return (<>
    <Row>
            <div className="col-12">
                <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled-checkout" className={disabled ? "tooltip-disabled" : "d-none"}>
                        You have no licenses selected to buy
                    </Tooltip>
                }>
                    <span className="d-inline-block">
                        <Button
                            type="submit"
                            className="btn-lg"
                            disabled={disabled}
                            {...(props.purchaseView === "extend" && {
                                "type": "button",
                                "data-toggle": "modal",
                                "data-target": "#warning-modal",
                            })}
                        >
                            Complete Checkout
                        </Button>
                    </span>
                </OverlayTrigger>
            </div>
        </Row>
        <div className='font-light'>OR</div>
        <Row>
            <div className="col-12">
                <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled-purchase-order" className={disabled ? "tooltip-disabled" : "d-none"}>
                        You have no licenses selected to buy
                    </Tooltip>
                }>
                    <span className="d-inline-block">
                        <Link to={`/subscribe/purchase-order?readingLicenses=${values["reading"]}&mathLicenses=${values["math"]}&discount=${values["discountCode"]}`}>
                            <Button disabled={disabled} className="btn-lg">Pay with Purchase Order</Button>
                        </Link>
                    </span>
                </OverlayTrigger>
            </div>
        </Row>
        {props.purchaseView === "extend" && (
            <div className="modal fade" id="warning-modal" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Warning</h4>
                            <button className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body text-start">
                            <p>Please note that the new license count will not change from {licenseText} until after this selected subscription expires on {props.extendSubscription != null && props.extendSubscription.exp}. If you need to immediately add students to a subscription, please use the <Link to="/subscribe/extend/summary" onClick={() => window.$("#warning-modal").modal("hide")}>Manage Subscriptions</Link> screen to add students.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <Button data-dismiss="modal">Cancel</Button>
                            <Button type="submit" name="paypal-submit" value="submit" className="button-inline">Buy</Button>
                        </div>
                    </div>
                </div>
            </div>
        )}

    </>)
}

export default LoggedInLinks;
