import { modelRequest } from '@stem-sims/great-leaps-react-common'

export type SuggestedPhonicsType = "phonics" | "alphabet"
export type SuggestedStoriesType = "high" | "middle" | "elementary"

export interface SuggestedResponse {
    suggestedPhonicsType: SuggestedPhonicsType
    suggestedStory: SuggestedStoriesType
    suggestedProgram: "Reading" | "Math"
    valid: boolean
    message: string
}


export function get({studentID}: { studentID: string }): Promise<SuggestedResponse> {
    return modelRequest.get("/api/suggested", {
        params: {
            studentID: studentID
        }
    })
}

