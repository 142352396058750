/*
    API endpoints for available countries and states/provinces

    GET /api/counties - returns list of available countries

    ex:
        [
            "United States",
            "Mexico",
            "Canada"
        ]

    GET /api/states/:country - returns list of available states (or provinces) given a country

    ex:
    [
        {
            "State": "Alabama",
            "Short Code": "AL"
        },
        {
            "State": "Alaska",
            "Short Code": "AK"
        },
        {
            "State": "Arizona",
            "Short Code": "AZ"
        },
        {
            "State": "Arkansas",
            "Short Code": "AR"
        },
        {
            "State": "California ",
            "Short Code": "CA"
        },
        {
            "State": "Colorado ",
            "Short Code": "CO"
        },
        {
            "State": "Connecticut",
            "Short Code": "CT"
        },
        {
            "State": "Delaware",
            "Short Code": "DE"
        },
        {
            "State": "Florida",
            "Short Code": "FL"
        },
        {
            "State": "Georgia",
            "Short Code": "GA"
        },
        {
            "State": "Hawaii",
            "Short Code": "HI"
        },
        {
            "State": "Idaho",
            "Short Code": "ID"
        },
        {
            "State": "Illinois",
            "Short Code": "IL"
        },
        {
            "State": "Indiana",
            "Short Code": "IN"
        },
        {
            "State": "Iowa",
            "Short Code": "IA"
        },
        {
            "State": "Kansas",
            "Short Code": "KS"
        },
        {
            "State": "Kentucky",
            "Short Code": "KY"
        },
        {
            "State": "Louisiana",
            "Short Code": "LA"
        },
        {
            "State": "Maine",
            "Short Code": "ME"
        },
        {
            "State": "Maryland",
            "Short Code": "MD"
        },
        {
            "State": "Massachusetts",
            "Short Code": "MA"
        },
        {
            "State": "Michigan",
            "Short Code": "MI"
        },
        {
            "State": "Minnesota",
            "Short Code": "MN"
        },
        {
            "State": "Mississippi",
            "Short Code": "MS"
        },
        {
            "State": "Missouri",
            "Short Code": "MO"
        },
        {
            "State": "Montana",
            "Short Code": "MT"
        },
        {
            "State": "Nebraska",
            "Short Code": "NE"
        },
        {
            "State": "Nevada",
            "Short Code": "NV"
        },
        {
            "State": "New Hampshire",
            "Short Code": "NH"
        },
        {
            "State": "New Jersey",
            "Short Code": "NJ"
        },
        {
            "State": "New Mexico",
            "Short Code": "NM"
        },
        {
            "State": "New York",
            "Short Code": "NY"
        },
        {
            "State": "North Carolina",
            "Short Code": "NC"
        },
        {
            "State": "North Dakota",
            "Short Code": "ND"
        },
        {
            "State": "Ohio",
            "Short Code": "OH"
        },
        {
            "State": "Oklahoma",
            "Short Code": "OK"
        },
        {
            "State": "Oregon",
            "Short Code": "OR"
        },
        {
            "State": "Pennsylvania",
            "Short Code": "PA"
        },
        {
            "State": "Rhode Island",
            "Short Code": "RI"
        },
        {
            "State": "South Carolina",
            "Short Code": "SC"
        },
        {
            "State": "South Dakota",
            "Short Code": "SD"
        },
        {
            "State": "Tennessee",
            "Short Code": "TN"
        },
        {
            "State": "Texas",
            "Short Code": "TX"
        },
        {
            "State": "Utah",
            "Short Code": "UT"
        },
        {
            "State": "Vermont",
            "Short Code": "VT"
        },
        {
            "State": "Virginia ",
            "Short Code": "VA"
        },
        {
            "State": "Washington",
            "Short Code": "WA"
        },
        {
            "State": "West Virginia",
            "Short Code": "WV"
        },
        {
            "State": "Wisconsin",
            "Short Code": "WI"
        },
        {
            "State": "Wyoming",
            "Short Code": "WY"
        }
    ]
*/

var countriesAndStates = {
    countries: [
        "United States",
        "Mexico",
        "Canada"
    ],
    states: [
        {
            "State": "Alabama",
            "Short Code": "AL"
        },
        {
            "State": "Alaska",
            "Short Code": "AK"
        },
        {
            "State": "Arizona",
            "Short Code": "AZ"
        },
        {
            "State": "Arkansas",
            "Short Code": "AR"
        },
        {
            "State": "California ",
            "Short Code": "CA"
        },
        {
            "State": "Colorado ",
            "Short Code": "CO"
        },
        {
            "State": "Connecticut",
            "Short Code": "CT"
        },
        {
            "State": "Delaware",
            "Short Code": "DE"
        },
        {
            "State": "Florida",
            "Short Code": "FL"
        },
        {
            "State": "Georgia",
            "Short Code": "GA"
        },
        {
            "State": "Hawaii",
            "Short Code": "HI"
        },
        {
            "State": "Idaho",
            "Short Code": "ID"
        },
        {
            "State": "Illinois",
            "Short Code": "IL"
        },
        {
            "State": "Indiana",
            "Short Code": "IN"
        },
        {
            "State": "Iowa",
            "Short Code": "IA"
        },
        {
            "State": "Kansas",
            "Short Code": "KS"
        },
        {
            "State": "Kentucky",
            "Short Code": "KY"
        },
        {
            "State": "Louisiana",
            "Short Code": "LA"
        },
        {
            "State": "Maine",
            "Short Code": "ME"
        },
        {
            "State": "Maryland",
            "Short Code": "MD"
        },
        {
            "State": "Massachusetts",
            "Short Code": "MA"
        },
        {
            "State": "Michigan",
            "Short Code": "MI"
        },
        {
            "State": "Minnesota",
            "Short Code": "MN"
        },
        {
            "State": "Mississippi",
            "Short Code": "MS"
        },
        {
            "State": "Missouri",
            "Short Code": "MO"
        },
        {
            "State": "Montana",
            "Short Code": "MT"
        },
        {
            "State": "Nebraska",
            "Short Code": "NE"
        },
        {
            "State": "Nevada",
            "Short Code": "NV"
        },
        {
            "State": "New Hampshire",
            "Short Code": "NH"
        },
        {
            "State": "New Jersey",
            "Short Code": "NJ"
        },
        {
            "State": "New Mexico",
            "Short Code": "NM"
        },
        {
            "State": "New York",
            "Short Code": "NY"
        },
        {
            "State": "North Carolina",
            "Short Code": "NC"
        },
        {
            "State": "North Dakota",
            "Short Code": "ND"
        },
        {
            "State": "Ohio",
            "Short Code": "OH"
        },
        {
            "State": "Oklahoma",
            "Short Code": "OK"
        },
        {
            "State": "Oregon",
            "Short Code": "OR"
        },
        {
            "State": "Pennsylvania",
            "Short Code": "PA"
        },
        {
            "State": "Rhode Island",
            "Short Code": "RI"
        },
        {
            "State": "South Carolina",
            "Short Code": "SC"
        },
        {
            "State": "South Dakota",
            "Short Code": "SD"
        },
        {
            "State": "Tennessee",
            "Short Code": "TN"
        },
        {
            "State": "Texas",
            "Short Code": "TX"
        },
        {
            "State": "Utah",
            "Short Code": "UT"
        },
        {
            "State": "Vermont",
            "Short Code": "VT"
        },
        {
            "State": "Virginia ",
            "Short Code": "VA"
        },
        {
            "State": "Washington",
            "Short Code": "WA"
        },
        {
            "State": "West Virginia",
            "Short Code": "WV"
        },
        {
            "State": "Wisconsin",
            "Short Code": "WI"
        },
        {
            "State": "Wyoming",
            "Short Code": "WY"
        }
    ]
}

export default countriesAndStates;
