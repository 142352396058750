import { modelRequest } from '@stem-sims/great-leaps-react-common'

/**
 * Switches to organization/group
 * 
 * @param id organization ID
 * @param groupName name of group
 */
export function post(id: string, groupName: string) : Promise<{ valid: boolean, message: string }> {
    return modelRequest.post('/api/organizations/switch', {
        id: id,
        groupName: groupName
    })
}
