


import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment, { MomentInput } from 'moment'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import customRequest from '../../helpers/customRequest'
import message from '../../components/message/message'
import buildQuery from '../../helpers/buildQuery'
import ProbeHelp from '../../components/help/ProbeHelp'
import HelpButton from '../../components/help/HelpButton'
import Timer from './components/Timer'
import { ProbeContext } from './ProbeContext'
import Popup from "./components/Popup"
import { isStoryType } from '../../models/probeList'
import GradingKey from './components/GradingKey'
import AccessibilityModal from './components/AccessibilityModal'

export default function Probe({type, probeNumber, studentId, previousScreen, nextScreen, inner, previewQuestions, children}) {

    const history = useHistory()

    const {currentResponseCount, setCurrentResponseCount, errors, setErrors, timer, setPopup, fontSize} = useContext(ProbeContext)
    const [submitting,setSubmitting] = useState<boolean>(false)
    const [gradingKey,setGradingKey] = useState<boolean>(false)
    const [warnings,setWarnings] = useState<string[]>([])
    const [timeError,setTimeError] = useState<boolean>(false)
    const [studentCode,setStudentCode] = useState<{expires: MomentInput, code: string}>({expires: null,code: ""})
    const [responsesMax,setResponsesMax] = useState<number>()

    const [commonProps,setCommonProps] = useState<any>({
        onClick : responseClicked,
        onDoubleClick : responseDoubleClicked,
        exerciseNumber : probeNumber,
        title : "",
        responses : [],
        showAnswer: true,
        subject: type,
        groupLength: 1,
        indentedParagraphs: false,
        sectionClass: "large",
        Help: ProbeHelp
    })

    let checkForValidCodeTimer = useRef<number>()
    const ProbeCommon = inner
    const PreviewQuestionsBtn = () => <>
        {previewQuestions && // is a story exercise
            <Button onClick={previewQuestions} variant="secondary-outline" className='flex-nowrap'>
                Preview Questions
            </Button>
        }
    </>

    /****************
    * Custom Functions
    *****************/
    const checkForValidCode = () => {
        if (!studentCode?.expires) return

        let now = moment()
        
        if (now.isAfter(studentCode.expires)) {
            redirectProbeList(previousScreen).then(() => {
                message.error("Your student code just expired, a new one has been generated. Please take note and let your student know.")
            })
        }
    }
    /* 
    *   Selects a response as an error
    */
    const selectError = (response, errorObject) =>
    {
        var htmlResponse = window.$(`#response-${response}`)
        if (htmlResponse.hasClass("error")) {
            htmlResponse.removeClass("error")
            var newErrors = errors.filter((error) => error.response !== response)
        }
        else {
            htmlResponse.addClass("error")
            newErrors = errors.concat({
                response: response,
                raw: errorObject
            })
        }
        setErrors(newErrors)
    }

    let doubleClickHappened = false //double click happened in the last 400ms
    let clickWaiting = false // used for when a click is waiting for a double click
    /** 
    * @abstract Callback for when a response is clicked
    */
    function responseClicked (error, errorObj) {
        if (!timer) {
            if (!clickWaiting) {
                clickWaiting = true
                setTimeout(() => {
                    if (!doubleClickHappened)
                    {
                        selectError(error, errorObj)
                    }
                    doubleClickHappened = false
                    clickWaiting = false
                }, 800)
            }
            else {
                //if two clicks come through then there was a double click. Some tablets don't support the double click event
                responseDoubleClicked(error)
            }
        }
        else {
            selectError(error, errorObj)
        }
    }
    /** 
    * @abstract Callback for when a response is double clicked
    */
    function responseDoubleClicked (response) {
        if (!timer) {
            setCurrentResponseCount(response)
            doubleClickHappened = true
        }
    }
    /** 
    * @abstract Redirects to probe list
    */
    const redirectProbeList = (toRedirect) => {
        return new Promise<void>((resolve, reject) => {
            const standardVariables = buildQuery({ id: studentId })
            history.push(`/probes/${toRedirect}?${standardVariables}`, { type: type })
            resolve()
        })
    }



    /**
     * @abstract Callback for when the form is submitted
     */
    const submitForm = (event,time) => {
        event.preventDefault()
        message.clear()
        setSubmitting(true)
        let errorMessages = []
        if (time === 60 && currentResponseCount <= 0)
            errorMessages.push("Please double click the last word read to record the number of responses.")
        
        if (time <= 0)
            errorMessages.push("Please enter the amount of time the student took to read the exercise, up to a maximum of 60 seconds.")
        
        if (errorMessages.length > 0) {
            setSubmitting(false)
            message.error(errorMessages)
            return
        }
        const formattedErrors = errors.map((error) => error.raw)
        customRequest.post("/api/probe",{
            "type": type,
            "probeNumber": probeNumber,
            "studentID": studentId,
            "time": time,
            "responses": (time < 60 && currentResponseCount !== responsesMax) ? responsesMax : currentResponseCount,
            "errors": formattedErrors
        }).then(({data}) => { 
            setSubmitting(false)
            if (data.valid) { // TODO: Return 400 when data is not valid instead of using conditional
                redirectProbeList(nextScreen).then(() => {
                    if (time < 60 && currentResponseCount !== responsesMax) {
                        message.info("OOPS! You didn't double click/tap the last word read. Because it was submitted before time was up, we assumed the student completed the exercise in under 1 minute.")
                    }
                    message.success("Your student's results have been recorded for this exercise.")
                })
            }
            else {
                console.error({
                    "type": type,
                    "probeNumber": probeNumber,
                    "studentID": studentId,
                    "time": time,
                    "responses": (time < 60 && currentResponseCount !== responsesMax) ? responsesMax : currentResponseCount,
                    "errors": formattedErrors
                })
                message.error(data.message ?? "There has been an error handling your request.")
            }
        })
    }
    
    /**
    * @abstract Sets number of response read
    */
    useEffect(() => {
        window.$(".response").removeClass("read")
        let responseElements = document.getElementsByClassName("response")

        for (var index = 0; index < currentResponseCount; index++)
            responseElements[index].classList.add("read")
    },[currentResponseCount])

    /**
     * @abstract To prevent stale state, pass in callbacks anytime dependent vars change
     */
     useEffect(() => {
        setCommonProps((prevRouteProps) => ({
            ...prevRouteProps,
            onClick : responseClicked,
            onDoubleClick : responseDoubleClicked
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[timer,errors])
    
    /**
     * @abstract init exercise probe data
     */
    useEffect(() => {
        if (isNaN(probeNumber)) {
            redirectProbeList(previousScreen).then(() => {
                message.error("There has been an error finding your probe. Please try again.")
            })
        }
      
        customRequest.get('/api/probe', {
            params: {
                studentID: studentId,
                type: type,
                number: parseInt(probeNumber)
            }
        }).then((response) => {
            if (!response.data || response.data.valid === false) {
                redirectProbeList(previousScreen).then(() => {
                    message.error(response.data.message)
                })
            }
            setTimeError(response.data.hasTimeError)
            setStudentCode(response.data.studentCode)
            setResponsesMax(response.data.responsesMax)
            setWarnings(response.data.warnings)
            setCommonProps((prevRouteProps) => ({
                    ...prevRouteProps,
                    title : response.data.title,
                    responses : response.data.responses,
                    groupLength: response.data.groupLength,
                    indentedParagraphs: response.data.indentedParagraphs,
                    sectionClass: response.data.sectionClass
            }))
            checkForValidCodeTimer.current = window.setInterval(checkForValidCode, 1000)

            if (isStoryType(type) && response.data.firstTime) {
                setPopup("Make sure your student begins each story timing by reading the title!")
            }   

            const probeNumberInt = parseInt(probeNumber)
            if (type === "phonics") {
                if (probeNumberInt === 1) { // instructions for placement exercises
                    setPopup("Complete the phonics placement exercises on the first day with your student. Once an error is made, or they are unable to finish in time, our program will place the student on the appropriate exercise to begin the next session.")
                }
                else if(probeNumberInt <= 11 && probeNumberInt >= 4) {
                    setPopup("Instruct your student to sound out each letter sound separately, then read the full word. Before beginning the first exercise, give an example of what is expected by modeling the first line.")
                }
                else if(probeNumberInt === 70 && !response.data.attemptedBefore) { // exercise 67 when referencing to Collin
                    setPopup('Inform your student that “Sometimes two words come together to make a new word."')
                }
                else if(probeNumberInt === 76 && !response.data.attemptedBefore) { // exercise 73 when referencing to Collin
                    setPopup("Each syllable must be pronounced before the word is read. Errors need to be marked as usual. Remember to immediately correct with the required response.")
                }
                else if(probeNumberInt <= 86 && probeNumberInt >= 79) { // exercises 76-83 when referencing to Collin
                    setPopup("The student reads the affix and any accompanying definition. If a response is missed, it is an error.")
                }
                else if(probeNumberInt === 89) { // exercise 86 when referencing to Collin
                    setPopup(`This section is optional. The words are often not phonetically perfect. 
                        Through these activities, students can gain added pride in their culture and its impact on the English language, finding an affinity for words from their background as well as learning about others. 
                        This should be fun and educational. Do not hesitate to explain where a country is and what we have gotten from the culture(s) other than language. You may wish to point out just how many of these borrowed words deal with food, plants, and animals.
                    `)
                }
            }
        })
        return () => {
            clearInterval(checkForValidCodeTimer.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[probeNumber,previousScreen,studentId])

    return(<ProbeContext.Consumer>
        { ({currentResponseCount, errors}) => (<>
            {warnings.map((warning) => 
                <div className="alert alert-info">{warning}</div>
            )}
            <div className='d-flex d-xl-none sub-header  bg-light align-items-center justify-content-between'>
                <Button onClick={() => redirectProbeList(previousScreen)} variant="outline-light" className='d-flex gl-blue move-forward align-items-center m-0'>
                    <i className='fa fa-arrow-left fa-lg px-2'/>
                    <span className='d-none d-md-inline'>Return to Exercises</span>
                </Button>
                <div className={`d-inline lead text-center`}>
                    Student Code: 
                    <span className="readable"><strong> {studentCode?.code}</strong></span>
                </div>
                <AccessibilityModal />
                <PreviewQuestionsBtn/>
                <HelpButton modelId={'probe-help'}/>
            </div>
            <Row id="probe" className='flex-1 flex-nowrap g-0'>
                <Col xl={2} className='d-none d-xl-block col-xxxl-2 g-xl-0 ps-xxl-4 text-center'>
                    <Button onClick={() => redirectProbeList(previousScreen)} variant="outline-light" className='gl-blue align-items-center border-0 m-3 m-xl-2'>
                        <i className='fa fa-arrow-left pe-1 pr-sm-2'/>
                        Return to Exercises
                    </Button>
                    <p className='text-center mt-5'>
                        <label className='h3'>Student Code:</label>
                        <span className="h3 readable"><strong> {studentCode?.code}</strong></span>
                    </p>
                    <Card className='d-none d-xxl-block shadow bg-light mx-1 mt-5 py-3 pe-1'>
                        <h2 className='h5 text-center pb-3'>Grading Key</h2>
                        <div className='medium-small text-start ps-3'>
                            <p>
                                <span className='d-inline-block bg-danger key-box me-1'/>
                                Single Left Click - Mark/clear incorrect
                            </p>
                            <p>
                                <span className='d-inline-block bg-light-red key-box me-1'/>
                                Marked incorrect in previous session
                            </p>
                            <p>
                                <span className='d-inline-block bg-primary key-box me-1'/>
                                Double Left Click - Mark last word read by student
                            </p>
                        </div>
                    </Card>
                    <Button onClick={()=>setGradingKey(true)} className="d-xxl-none">Grading Key</Button>
                </Col>
                <Col lg={10} xl={8} className={`font-${fontSize} ${isStoryType(type) ? "story-probe" : ""} d-flex flex-column text-center px-0 pt-4 mx-auto px-xl-3`}>
                    {commonProps && <ProbeCommon {...commonProps}/>}
                    <p className='pt-4'>({responsesMax} total responses)</p>
                    <Row className="justify-content-center mx-auto">
                        <Col className="text-nowrap">
                            <label className="control-label pe-2">Responses:</label>
                            <span className="form-control-static text-start bold">{currentResponseCount}</span>
                        </Col>
                        <Col className="text-nowrap">
                            <label className="control-label pe-2">Errors:</label>
                            <span className="form-control-static text-start bold">{errors.length}</span>
                        </Col>
                    </Row>
                    <Timer timeHasError={timeError} submitForm={submitForm} submitting={submitting}/>
                </Col>
                <Col xl={2} className='d-none d-xl-block col-xxxl-2'>
                    <div className='text-center my-3 mx-2'>
                        <PreviewQuestionsBtn/>
                        <AccessibilityModal />
                    </div>
                </Col>
            </Row>
            <GradingKey gradingKey={gradingKey} setGradingKey={setGradingKey} />
            <Popup />
            {children}
        </>)}
        </ProbeContext.Consumer>)
}
