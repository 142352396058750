function EventDispatcher()
{
	this.callbacks = {};
}

EventDispatcher.prototype.addEventListener = function(type, callback)
{
	if (this.callbacks[type] === undefined)
	{
		this.callbacks[type] = [];
	}
	
	this.callbacks[type].push(callback);
};

EventDispatcher.prototype.removeEventListener = function(type, callback)
{
	if (this.callbacks[type] !== undefined)
	{
		var length = this.callbacks[type].length;
		for (var index = 0; index < length; index++)
		{
			if (this.callbacks[type][index] === callback)
			{
				this.callbacks[type].splice(index, 1);
			}
		}
	}
};

EventDispatcher.prototype.dispatchEvent = function(event)
{
	event.target = this;
	var type = event.type;
	
	if (this.callbacks[type] !== undefined)
	{
		var completedCallbacks = {};
		
		var callbacks = this.callbacks[type];
		var length = callbacks.length;
		for (var index = 0; index < length; index++)
		{
			if (completedCallbacks[callbacks[index].name] === undefined)
			{
				completedCallbacks[callbacks[index].name] = true;
				
				callbacks[index](event);
			}
			
			else
			{
				callbacks.splice(index, 1);
			}
		}
	}
};

export default EventDispatcher;
