
import React from 'react';
import { Route, Link } from "react-router-dom";
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'
import TeacherLogin from '../pages/account/log-in/Teacher'
import redirect from '../components/redirect/redirect'
import message from '../components/message/message'
import Common from '../context/Common'
import { StudentLogin, models } from '@stem-sims/great-leaps-react-common'

class LogIn extends React.Component
{
    state = {
        submitting: false
    }
    submit = () =>
    {
        this.setState({submitting: true}, () => {
            var usernameInput = document.getElementById("username")
            var passwordInput = document.getElementById("password")
            
            models.login.post(usernameInput.value, passwordInput.value, this.props.type)
            .then((data) =>
            {
                this.setState({submitting: false})
                if (data.valid === true)
                {
                    this.props.updatePermissions()
                    .then(() => {
                        if (this.props.type === "customer") {
                            redirect.send("/dashboard", this.props)
                        }
                        else if (this.props.type === "student") {
                            redirect.send("/student/dashboard", this.props)
                        }
                        
                    })
                }

                else
                {
                    message.clear()
                    message.error(data.message)
                    this.checkAuth()
                    if (this.props.type === "student") {
                        document.getElementById("password").value = ""
                    }
                }
            })
        })
    }

    checkAuth = () => {
        this.props.updatePermissions()
        .then(() => {
            let auth = this.context?.auth
            if (auth.includes("customer")) {
                redirect.send("/dashboard", this.props, () => {
                    message.error("You are already logged in.")
                })
            }
            else if (auth.includes("student")) {
                redirect.send("/student/dashboard", this.props, () => {
                    message.error("You are already logged in.")
                })
            }
        })
    }

    static contextType = Common

    componentDidMount() {
        if (this.state.submitting === false) {
            this.checkAuth()
        }
    }

    render()
    {
        var { submitting } = this.state
        var { type } = this.props
        return (
            <Col lg={10} className='align-self-center align-items-center text-center'>
                <h1 className='section-header my-4'>Log in to Great Leaps Digital</h1>
                <Row className="justify-content-center subheader-text">
                    <Col sm={4} xl={3} className="">
                        <Link to = "/account/log-in/teacher" className={`w-100 btn btn-secondary btn-lg ${type === "customer" ? "active" : ""}`}>Teacher</Link>
                    </Col>
                    <Col sm={4} xl={3} className="">
                        <Link to = "/account/log-in/student" className={`w-100 btn btn-secondary btn-lg ${type === "student" ? "active" : ""}`}>Student</Link>
                    </Col>
                </Row>
                <Route exact path="/account/log-in/student" render={(props) => <StudentLogin  {...props} submitting = {submitting} submit = {this.submit} />} />
                <Route exact path="/account/log-in/teacher" render={(props) => <TeacherLogin  {...props} submitting = {submitting} submit = {this.submit} />} />
            </Col>
        )
    }
}

export default LogIn
