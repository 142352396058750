import React from 'react';
import { Link } from 'react-router-dom';
import message from '../../../components/message/message';
import getURLParameters from '../../../helpers/getURLParams';

class ForceActivateCodesSuccess extends React.Component {

    componentDidMount() {
        let params = getURLParameters(this.props);

        if (!params.expiration) {
            message.error("You seem to have navigated to this page accidentally. If you're trying to access this page, please proceed back to the administration page using the button below..")
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="redirect-container">
                    <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                        <Link to="/admin" className="btn btn-secondary btn-lg">Return to Administration Tools</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default ForceActivateCodesSuccess;
