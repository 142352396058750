
/**
 * Groups an object by given property
 */
export default function groupBy<T extends Array<Record<K, any>>, K extends string | number | symbol>(ary: T, key: K): Map<any, T> {
    return ary.reduce((acc, obj) => {
        acc.get(obj[key]) || acc.set(obj[key], [])
        acc.get(obj[key]).push(obj)
        return acc
    }, new Map())
}
