import React from 'react';
import moment from 'moment';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';
import customRequest from '../../../helpers/customRequest';

import ClipLoader from "react-spinners/ClipLoader";
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import getURLParams from '../../../helpers/getURLParams';

class GenerateCodes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            created: "",
            "number-codes": 1,
            students: 10,
            tier: "",
            expiration: "",

            loading: false
        }
    }

    componentDidMount() {
        initializeFormValidation();

        initializeAutosubmit();
        initializeTextAreaSubmit();
        initializeSubmissionPrevention();

        let params = getURLParams(this.props);

        if (params.name) {
            this.setState({name: params.name})
        }

        if (params.created) {
            this.setState({
                created: params.created,
                expiration: moment(params.created).add(1, 'years').add(7, 'days').format('YYYY-MM-DD')
            })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var generateCodeForm = document.getElementById("generate-code-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(generateCodeForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            customRequest.post("/api/admin/activation-codes", {
                transactionContactName: this.state.name,
                transactionCreated: this.state.created,
                numberOfCodes: this.state["number-codes"],
                students: this.state.students,
                tier: this.state.tier,
                expiration: this.state.expiration
            })
                .then(({ data }) => {
                    if (!data.valid) {
                        this.setState({ loading: false })
                        return message.error(data.message)
                    }

                    let redirectUrl = "/admin/activation-codes/generate/success?name=" + this.state.name + "&created=" + this.state.created + 
                    "&students=" + this.state.students + "&expiration=" + moment(this.state.expiration).format('YYYY-MM-DD HH:mm:ss')

                    data.codes.forEach(element => {
                        redirectUrl += ("&codes[]=" + element)
                    });

                    redirect.send(redirectUrl)
                    message.success("Your activation code(s) was successfully generated, with details below.")
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    return message.error("There has been an internal error.")
                })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1>Generate Activation Codes</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Please enter the negotiated number of activation codes and subscription configuration options below. Note that all activation codes must have the same configuration options (number of students, etc). If multiple codes need to have different configuration options, you must submit this form multiple times.</p>
                    </div>
                </div>

                <form id="generate-code-form" className="form-horizontal form-validate" noValidate onSubmit={this.submitForm} method="post">
                    <div className="mb-3 row">
                        <label for="name" className="col-sm-3 control-label text-end">Transaction Contact Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="name" name="name" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.name} placeholder="Name" required data-description="enter the transaction's contact name" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="created" className="col-sm-3 control-label text-end">Transaction Created</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control" id="created" name="created" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.created} placeholder="YYYY-MM-DD HH:MM:SS" maxlength="19" required data-description="enter the transaction's creation date and time" />

                                <span className="input-group-text">YYYY&#8209;MM&#8209;DD HH:MM:SS</span>
                            </div>
                        </div>
                    </div>


                    <div className="mb-3 row">
                        <label for="number-codes" className="col-sm-3 control-label text-end">Number of Codes</label>

                        <div className="col-sm-6">
                            <input type="number" className="form-control" id="number-codes" name="number-codes" min="1" step="1" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["number-codes"]} required data-description="enter the number of codes to generate" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="students" className="col-sm-3 control-label text-end">Students</label>

                        <div className="col-sm-6">
                            <input type="number" className="form-control" id="students" name="students" min="1" step="1" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.students} required data-description="enter the number of students for the subscription" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="students" className="col-sm-3 control-label text-end">Tier</label>

                        <div className="col-sm-6">
                            <select className="form-control" id="tier" name="tier" required disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.tier} data-description="select a subscription tier.">
                                <option value="" disabled selected >Tier:</option>
                                {
                                    ["Trial", "Basic"].map((tier) => {
                                        return <option value={tier}>{tier}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="expiration" className="col-sm-3 control-label text-end">Expiration</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="date" name="expiration" id="expiration" className="form-control" min="2020-01-01" max="9999-12-31" disabled={this.state.loading} onChange={this.handleInputChange} maxlength="10" value={this.state.expiration} required data-description="enter the expiration date of the subscription link" />

                                <span className="input-group-text">MM/DD/YYYY</span>
                            </div>
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">
                    {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Generate"}
                    </button>
                </form>

            </React.Fragment>
        )
    }

}

export default GenerateCodes;
