import { modelRequest } from "@stem-sims/great-leaps-react-common"

function removeUser({ orgId, customerUsername }: { orgId: string, customerUsername: string }) {
    return modelRequest.delete("/api/organizations/remove-instructor", {
        data: {
            id: orgId,
            customerUsername
        }
    })
}

const organizationRemoveInstructor = {
    removeUser
}

export default organizationRemoveInstructor
