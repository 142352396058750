function initializeTextAreaSubmit()
{	
	var forms = document.getElementsByTagName("form");
	
	var formsLength = forms.length;
	for (var formIndex = 0; formIndex < formsLength; formIndex++)
	{
		var form = forms[formIndex];
		
		var textAreas = form.getElementsByTagName("textarea");
		var textAreasLength = textAreas.length;
		for (var textAreaIndex = 0; textAreaIndex < textAreasLength; textAreaIndex++)
		{
			var textArea = textAreas[textAreaIndex];
			textArea.addEventListener("keydown", submitForm.bind(form));
		}
	}
}

function submitForm(event)
{
	if (event.keyCode === 13 && (event.metaKey === true || event.ctrlKey === true))
	{
		var submitEvent = document.createEvent("Event");
		submitEvent.initEvent("submit", false, true);
		
		var success = this.dispatchEvent(submitEvent);
		
		if (success === true)
		{
			this.submit();
		}
	}
}

export default initializeTextAreaSubmit;
