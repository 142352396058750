import React from 'react'
import customRequest from '../../../helpers/customRequest'
import StudentModal from './StudentModal'
import { useRoster } from './StudentsContext'

type DeleteProps = {
    show: boolean
}

function DeleteForm() {
    const roster = useRoster()
    return(<>
        <div className= "mb-3 row">
            <label className= "d-none d-sm-inline col-sm-6 control-label text-end">Name</label>
            <div className= "col-sm-6">
                <p className= "form-control-static text-start text-center-xs break-words">{roster?.selectedStudent?.name}</p>
            </div>
        </div>
    </>)
}
function Delete(props: DeleteProps) {

    const roster = useRoster()

    const deleteStudent = async () => {
        const res = await customRequest.post('/api/students/remove', {
            studentID: roster.selectedStudent.id
        })
        roster.refreshStudents()
        return res
    }

    return (<>
        <StudentModal show={props.show} hide={roster.hideModals} action="Delete" submitAction={deleteStudent} size="sm">
            <DeleteForm />
        </StudentModal>
    </>)
}

export default Delete
