import React from 'react'
import { initializeFormValidation } from '../../helpers/forms/form-validation'

export default class Form extends React.Component<{submit: (values: Object) => Promise<void>, method?: "post" | "get", children: (submitting) => React.ReactNode}, { submitting: boolean }> {
    state = {
        submitting: false
    }

    componentDidMount() {
        initializeFormValidation(this.formSubmit)
    }

    formSubmit = (event) => {
        this.setState({ submitting: true }, () => {
            let elements = event.target.elements
            this.props.submit(elements)
            .then(() => {
                this.setState({ submitting: false })
            })
        })
    }


    render() {
        return (
            <form className = "form-horizontal form-validate" method = {this.props.method ?? "post"} noValidate>
                {this.props.children(this.state.submitting)}
            </form>
        )
    }
}

