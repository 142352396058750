import React, { useContext } from "react"
import { PhonicsDonut, AlphabetDonut, PhrasesDonut, StoriesDonut, AdditionDonut, SubtractionDonut, MultiplicationDonut, DivisionDonut} from "@stem-sims/great-leaps-react-common"
import Form from "react-bootstrap/Form"
import { isStoryType } from "../../models/probeList"
import { storyLevels } from "../../models/reading"
import { subjectPath } from "./SubjectTabs"
import { useHistory } from "react-router-dom"
import { RecordContext } from "../../pages/records/RecordsPage"

export default function Donut({studentID,type}) {
    const context = useContext(RecordContext)
    const history = useHistory()

    const changeExerciseSubType = (e) => {

        const typePath = "stories/" + e.target.value
        const redirectLink = subjectPath({studentID: studentID, typePath: typePath})
        history.push(redirectLink, {type: e.target.value, currentChart: context.currentChart})
    }

    return (<>
        <div className="flex-shrink-1 ps-3 pt-3 p-1">
            {type === "alphabet" && <AlphabetDonut studentID={studentID}  />}
            {type === "phonics" && <PhonicsDonut studentID={studentID} />}
            {type === "phrases" && <PhrasesDonut studentID={studentID} />}
            {isStoryType(type) && <>
                <StoriesDonut studentID={studentID} />
                <Form.Control as="select" onChange={changeExerciseSubType} defaultValue={type}>
                    {storyLevels.map((storyLevel) => 
                        <option value={storyLevel} key={storyLevel}>{storyLevel}</option>
                    )}
                </Form.Control>
            </>}
            {type === "addition" && <AdditionDonut studentID={studentID} />}
            {type === "subtraction" && <SubtractionDonut studentID={studentID} />}
            {type === "multiplication" && <MultiplicationDonut studentID={studentID} />}
            {type === "division" && <DivisionDonut studentID={studentID} />}
        </div>
    </>)
}
