import Message from './message';

function PreviousMessage()
{
	this.parent.constructor.call(this, "", "");
}

PreviousMessage.prototype = new Message();
PreviousMessage.prototype.parent = Message.prototype;
PreviousMessage.prototype.constructor = PreviousMessage;

export default PreviousMessage;
