import React from 'react';
import ResearchSummary from './ResearchSummary'

const teachingMethods = [
    {
        url: "http://www.behavior.org/resources/295.pdf",
        start: "Binder, C., & Watkins, C. L. (1990). ",
        linkText: "Precision Teaching and Direct Instruction: Measurably superior instructional technology in schools.",
        end: " Performance Improvement Quarterly, 3(4), 74-96.",
        bullet: `Sacajawea Elementary School where students who received 20-30 minutes daily of Precision Teaching advanced an average of 19-40 
        percentile points compared to their peers. “Improvements of two or more grade-levels per year of instruction are common in Precision 
        Teaching classroom.”`
    },
    {
        url: "https://www.researchgate.net/publication/275724141_From_the_laboratory_to_the_field_and_back_again_Morningside_Academy's_32_years_of_improving_students'_academic_performance",
        start: "Johnson, K., & Street, E.M. (2012). ",
        linkText: "From the Laboratory to the Field and Back Again: Morningside Academy's 32 Years of Improving Students' Academic Performance.",
        end: "",
        bullet: `Morningside utilizes the precision teaching methodology that Great Leaps is built on and offers a full refund of tuition (approx. 
            $23,000) for any students that do not advance at least 2 years ahead in all the areas they are struggling in. As of 2012 they have 
            returned less than 1% of school year tuition.`
    },
    {
        url: "https://www.amazon.com/Beginning-Read-Thinking-Learning-about/dp/0262510766",
        start: "Adams, Marilyn J. (1990). ",
        linkText: "Beginning to Read: Thinking and Learning About Print.",
        end:  " University of Illinois at Urbana-Champaign: Center for the Study of Reading. ",
        bullet: `This book is written for parents, teachers and administrators and speaks to the proven ways to teach reading based on a collection of 
        research that is presented.`
    },
    {
        url: "https://psycnet.apa.org/record/1969-17933-001",
        start: "Heckelman, R. G. (1969). ",
        linkText: "A neurological-impress method of remedial-reading instruction.",
        end: " Academic Therapy Quarterly, 5(4), pp. 277–282.",
        bullet: `Gave 24 students with reading difficulties 7 1/4 hr. neurological-impress reading instruction. The mean gain in reading comprehension 
        was 1.9 grade levels significant at the .001 and .005% levels. The results of the teaching method were discussed and it was hypothesized 
        that this method is "one of the most direct and fundamental systems of reading" involving a "combination of reflexive neurological 
        systems.`
    },
    {
        url: "https://textproject.org/library/books/a-focus-on-fluency-booklet",
        start: "Osborn, J. & Lehr, F. (2004). ",
        linkText: "A focus on fluency.",
        end: " Honolulu, HI: Pacific Resources for Education and Learning. ",
        bullet: `Intended for practitioners, this is the first booklet in the Research-Based Practices in Early Reading series published by the Regional 
        Educational Laboratory at Pacific Resources for Education and Learning. The 31-page booklet summarizes research on fluency and fluency 
        instruction and describes strategies for fluency instruction. It also explains various ways of conducting repeated oral reading, the use of 
        independent silent reading, an integrated fluency instruction approach, the role of texts, and fluency assessment.`
    },
]
const programDesign = [
    {
        url: "https://www.jstor.org/stable/pdf/20200198.pdf?seq=1",
        start: "Dowhower, S. (1989). ",
        linkText: "Repeated Reading: Research into Practice.",
        end: " The Reading Teacher, 42(7), 502-507.",
        bullet: `Repeated reading is a similar concept to practice makes perfect and it is essential to the effectiveness of Great Leaps. Students stay 
        on an exercise, reading it multiple times until a goal is made.`
    },
    {
        url: "https://www.semanticscholar.org/paper/Oral-Reading-Fluency-as-an-Indicator-of-Reading-A-Fuchs-Fuchs/b2a741914b27ce76f1e1284e48ea5074372ace72",
        start: "Fuchs, L. S., Fuchs, D., Hosp, M. K., & Jenkins, J. R. (2001). ",
        linkText: "Repeated Reading: Research into Practice.",
        end: " Scientific Studies of Reading, 5(3), pp. 239–256.",
        bullet: `This evaluates the idea that oral reading fluency is a good measure of overall reading competence. Great Leaps uses oral reading 
        instruction and gauges student reading growth off of their oral reading performance.`
    },
    {
        url: "https://www.readingrockets.org/article/students-who-are-not-yet-fluent-silent-reading-not-best-use-classroom-time",
        start: "Hasbrouck, J. (2006). ",
        linkText: "For Students Who Are Not Yet Fluent, Silent Reading Is Not the Best Use of Classroom Time.",
        end: " American Educator, Summer 2006, 30(2).",
        bullet: `Teachers have often utilized ineffective reading strategies such as silent reading to improve fluency in the past. Improving fluency 
        takes practice, support and guided out loud reading and research-based instruction.`
    },
    {
        url: "https://www.readingrockets.org/article/screening-diagnosing-and-progress-monitoring-fluency-details",
        start: "Hasbrouck, J. (2006). ",
        linkText: "Screening, diagnosing, and progress monitoring for fluency: The details.",
        end: " American Educator, Summer 2006, 30(2).",
        bullet: `To help students you must first determine the need for an intervention, then identify their instructional level of reading to work at 
        in oral reading instruction and finally monitor their growth which Great Leaps addresses through our standard celeration chart measuring 
        words per minute against errors per minute on each passage.`
    },
    {
        url: "https://www.researchgate.net/publication/250055778_Oral_Reading_Fluency_Norms_A_Valuable_Assessment_Tool_for_Reading_Teachers",
        start: "Hasbrouck, J., & Tindal, G. (2006). ",
        linkText: "Oral reading fluency norms: A valuable assessment tool for reading teachers.",
        end: "  International Reading Association, 59 (7), 636–644.",
        bullet: `In 1992, the authors collaborated to develop a set of norms for oral reading fluency for grades 2–5. Since then, interest in and 
        awareness of fluency has greatly increased, and Hasbrouck and Tindal have collaborated further to compile an updated and expanded set 
        of norms for grades 1–8.This article discusses the application of these norms to three important assessment activities related to 
        improving students' reading achievement:Screening students for possible reading problems. Diagnosing deficits in students' fluency. 
        Monitoring the progress of students receiving supplementary instruction or intensive intervention in reading. An overview of the 
        history and purpose for developing measures of oral reading fluency is also presented.`
    },
    {
        url: "https://www.fcrr.org/publications/publicationspdffiles/hudson_lane_pullen_readingfluency_2005.pdf",
        start: "Hudson, R. F., H. B. Lane, and P. C. Pullen. (2005). ",
        linkText: "Reading fluency assessment and instruction: What, why, and how.",
        end: "  Reading Teacher, 58(8), pp. 702-714.",
        bullet: `This article explains the elements of reading fluency and ways to assess and teach them. Fluent reading has three elements: accurate reading of 
        connected text, at a conversational rate with appropriate prosody. Word reading accuracy refers to the ability to recognize or decode words correctly. 
        Reading rate refers to both word-level automaticity and speed in reading text. Prosodic features are variations in pitch, stress patterns, and duration 
        that contribute to expressive reading of a text. To assess reading fluency, including all its aspects, teachers listen to students read aloud. Students' 
        accuracy can be measured by listening to oral reading and counting the number of errors per 100 words or a running record. Measuring reading rate 
        includes both word-reading automaticity and speed in reading connected text using tests of sight-word knowledge and timed readings. A student's reading 
        prosody can be measured using a checklist while listening to the student. To provide instruction in rate and accuracy, variations on the repeated 
        readings technique are useful. To develop prosody, readers can listen to fluent models and engage in activities focused on expression and meaning. 
        Opportunities to develop all areas of reading fluency are important for all readers, but especially for those who struggle.`
    },
    {
        url: "https://www.sciencedirect.com/science/article/abs/pii/0010028574900152",
        start: "LaBerge, D., & Samuels, S. J. (1974). ",
        linkText: "Toward a theory of automatic information processing in reading.",
        end: " Cognitive Psychology, 6, pp. 292–323.",
        bullet: `A model of information processing in reading is described in which visual information is transformed through a series of processing 
        stages involving visual, phonological and episodic memory systems until it is finally comprehended in the semantic system. The 
        processing which occurs at each stage is assumed to be learned and the degree of this learning is evaluated with respect to two 
        criteria: accuracy and automaticity. At the accuracy level of performance, attention is assumed to be necessary 
        for processing; at the automatic level it is not. Experimental procedures are described which attempt to measure the degree of 
        automaticity achieved in perceptual and associative learning tasks. Factors which may influence the development of automaticity in 
        reading are discussed.`
    },
    {
        url: "https://www.tandfonline.com/doi/abs/10.1207/s1532799xssr0902_1",
        start: "Mehta, P. D., Foorman, B. R., Branum-Martin, L., & Taylor, W. P. (2005). ",
        linkText: "Literacy as a unidimensional construct: Validation, sources of influence and implications in a longitudinal study in grades 1–4.",
        end: " Scientific Studies of Reading, 9(2), pp. 85–116.",
        bullet: `This study examined the extent to which literacy is a unitary construct, the differences between literacy and general language 
        competence, and the relative roles of teachers and students in predicting literacy outcomes. Much of past research failed to make a 
        distinction between variability in outcomes for individual students and variability for outcomes in the classrooms students share 
        (i.e., the classroom level). Utilizing data from 1,342 students in 127 classrooms in Grades 1 to 4 in 17 high-poverty schools, 
        confirmatory factor models were fit with single- and two-factor structures at both student and classroom levels. Results support a 
        unitary literacy factor for reading and spelling, with the role of phonological awareness as an indicator of literacy declining across 
        the grades. Writing was the least related to the literacy factor but the most impacted by teacher effects. Language competence was 
        distinct at the student level but perfectly correlated with literacy at the classroom level. Implications for instruction and 
        assessment of reading comprehension are discussed.`
    },
    {
        url: "https://journals.sagepub.com/doi/10.1177/002221948201501013",
        start: "Moyer, S.B. (1982). ",
        linkText: "Repeated reading.",
        end: " Journal of Learning Disabilities, 45, 619-623.",
        bullet: `The last two topical reviews focused on individual differences in the cognitive processes related to reading. The topic of this month's 
        review is again reading, but the focus is on educational technology rather than individual differences. Dr. Moyer's paper makes at 
        least two important contributions to the literature on remedial reading techniques. First, it provides a thorough discussion and review 
        of the empirical evidence related to the use of a relatively new remedial technique, multiple oral rereading. Second, it establishes a 
        theoretical context for the potential effectiveness of this technique with problem readers. This latter contribution seems vital 
        because of its potential to add to our understanding of both the reading process itself and the difficulties learning disabled children 
        experience in learning to read.`
    },
    {
        url: "https://www.nichd.nih.gov/sites/default/files/publications/pubs/nrp/Documents/report.pdf",
        start: "National Reading Panel. (2000). ",
        linkText: "Teaching children to read: An evidence-based assessment of the scientific research literature on reading and its implications for reading instruction.",
        end: " Washington, DC.",
        bullet: `This report summarizes the work and findings of the National Reading Panel, charged by Congress to assess the status of research-based 
        knowledge, including the effectiveness of various approaches to teaching children to read.`
    },
    {
        url: "https://onlinelibrary.wiley.com/doi/abs/10.1002/pits.20542",
        start: "Neddenriep, C. E., Fritz, A. M., Carrier, M.E., (2011). ",
        linkText: "Assessing for generalized improvements in reading comprehension by intervening to improve reading fluency.",
        end: " Washington, DC.",
        bullet: `The relationship between reading fluency and comprehension was evaluated in five 4th‐grade students. These students were identified as 
        being at risk of not meeting yearly goals in reading fluency and comprehension based on fall benchmark assessment data. A brief 
        intervention assessment was used to determine which intervention components would be essential to improving reading fluency across the 
        five participants. As a result, the combination of repeated practice with performance feedback and error correction was implemented 
        using instructional‐level reading materials twice per week for 30‐minute sessions with progress monitored weekly using AIMSweb measures 
        of oral reading fluency and comprehension. Empirical, single‐case designs were used to evaluate the impact of the program across these 
        five students with assessed, generalized improvements in comprehension. Results indicated increased rate of words read correctly per 
        minute with generalized increases in comprehension for four of five participants. Implications for practice and directions for future 
        research are discussed. © 2010 Wiley Periodicals, Inc.`
    },
    {
        url: "http://www.eduplace.com/state/author/pik_chard_fluency.pdf",
        start: "Pikulski, J. J. & Chard, D. J. (2005). ",
        linkText: "Fluency: Bridge between decoding and comprehension.",
        end: " The Reading Teacher, 58(6), 510–518.",
        bullet: `A deep, developmental construct and definition of fluency, in which fluency and reading comprehension have a reciprocal relationship, 
        is explicated and contrasted with superficial approaches to that construct. The historical development of fluency is outlined, along 
        with conclusions of the U.S. National Reading Panel, to explore why fluency has moved from being "the neglected aspect of reading" to 
        a popular topic in the field. A practical, developmental instructional program based largely on the theoretical framework and research 
        findings of Linnea Ehri is delineated. The nine essential components of that program include building the graphophonic foundations for 
        fluency; building and extending vocabulary and oral language skills; providing expert instruction and practice in the recognition of 
        high-frequency vocabulary; teaching common word parts and spelling patterns; teaching, modeling, and providing practice in the application 
        of a decoding strategy; using appropriate texts to coach strategic behaviors and to build reading speed; using repeated reading 
        procedures as an intervention approach for struggling readers; extending growing fluency through wide independent reading; and 
        monitoring fluency development through appropriate assessment procedures. The position taken throughout the piece is that teaching, 
        developing, and assessing fluency must always be done in the context of reading comprehension.`
    },
    {
        url: "https://www.jstor.org/stable/747754?seq=1",
        start: "Rashotte, C.A., & Torgeson, J.K. (1985). ",
        linkText: "Repeated reading and reading fluency in learning disabled children.",
        end: " Reading Research Quarterly, 20, 180-188.",
        bullet: `This study investigated whether improved fluency and comprehension across different stories in repeated reading depend on the degree 
        of word overlap among passages and whether repeated reading is more effective than an equivalent amount of nonrepetitive reading. 
        Non-fluent, learning disabled students read passages presented and timed by a computer under three different conditions. Results 
        suggest that over short periods of time, increases in reading speed with the repeated reading method depend on the amount of shared 
        words among stories, and that if stories have few shared words, repeated reading is not more effective for improving speed than an 
        equivalent amount of nonrepetitive reading.`
    },
    {
        url: "https://www.researchgate.net/publication/240640542_Reading_Fluency_Instruction_Moving_Beyond_Accuracy_Automaticity_and_Prosody",
        start: "Rasinski, T. (2006). ",
        linkText: "Reading fluency instruction: Moving beyond accuracy, automaticity, and prosody.",
        end: " The Reading Teacher, 59(7), 704–706.",
        bullet: `Three key elements of reading fluency are accuracy in word decoding, automaticity in recognizing words, and appropriate use of prosody 
        or meaningful oral expression while reading. These three components are a gateway to comprehension. Readers must be able to decode words 
        correctly and effortlessly (automaticity) and then put them together into meaningful phrases with the appropriate expression to make 
        sense of what they read.`
    },
    {
        url: "https://communityreading.org/documents/Samuels(1979)reprint_Repeated_Reading.pdf",
        start: "Samuels, S. J. (1979). ",
        linkText: "The method of repeated reading.",
        end: " The Reading Teacher, 32, 403-408.",
        bullet: `Describes the method of repeated readings, discussing the procedure, comprehension, and theoretical rationale. Compares it with music 
        and sports and notes how versions of this method were used in early schooling.`
    },
    {
        url: "https://www.amazon.com/Overcoming-Dyslexia-Complete-Science-Based-Problems-ebook/dp/B000S1LEMY",
        start: "Shaywitz, S. (2003). ",
        linkText: "Overcoming dyslexia: A new and complete science-based program for reading problems at any level.",
        end: " New York: Alfred A. Knopf.",
        bullet: `Yale neuroscientist Shaywitz demystifies the roots of dyslexia (a neurologically based reading difficulty affecting one in five children) 
        and offers parents and educators hope that children with reading problems can be helped. Shaywitz delves deeply into how dyslexia occurs, 
        explaining that magnetic resonance imaging has helped scientists trace the disability to a weakness in the language system at the 
        phonological level. According to Shaywitz, science now has clear evidence that the brain of the dyslexic reader is activated in a 
        different area than that of the nonimpaired reader. Interestingly, the dyslexic reader may be strong in reasoning, problem solving 
        and critical thinking, but invariably lacks phonemic awareness-the ability to break words apart into distinct sounds-which is critical 
        in order to crack the reading code. The good news, Shaywitz claims, is that with the use of effective training programs, the brain can 
        be rewired and dyslexic children can learn to read. She walks parents through ways to help children develop phonemic awareness, become 
        fluent readers, and exercise the area of the brain essential for reading success. Early diagnosis and effective treatment, the author 
        claims, are of utmost importance, although even older readers can learn to read skillfully with proper intervention. Shaywitz's 
        groundbreaking work builds an important bridge from the laboratory to the home and classroom.`
    },
    {
        url: "https://www.tandfonline.com/doi/abs/10.1080/00220671.1990.10885959",
        start: "Sindelar, P.T., Monda, L.E., & O’Shea, L.J. (1990). ",
        linkText: "Effects of repeated reading on instructional and mastery level readers.",
        end: " Journal of Educational Research, 83, 220-226.",
        bullet: `25 3rd–5th graders with learning disabilities were matched on fluency and comprehension with 25 nondisabled students, as determined by 
        performance on 2 screening passages. In both groups, 17 Ss read the screening passages at instructional level and 8 Ss read them at 
        mastery level. All the Ss read one additional passage once and another 3 times. In final readings, measures of fluency and accuracy 
        were obtained, and after each final reading, measures of recall were derived. Significant main effects were obtained for number of 
        readings on all 3 measures, and for level on fluency and accuracy. Results indicate that regardless of classification or level of 
        functioning, repeated readings constituted more fluent reading and greater recall.`
    },
    {
        url: "https://www.researchgate.net/publication/249835323_Fluency_and_Comprehension_Gains_as_a_Result_of_Repeated_Reading_A_Meta-Analysis",
        start: "Therrien, W.J. (2004). ",
        linkText: "Fluency and comprehension gains as a result of repeated reading: A meta analysis.",
        end: " Remedial and Special Education, 25(4) 252-261.",
        bullet: `Repeated reading is an evidenced-based strategy designed to increase reading fluency and comprehension. The author conducted a 
        meta-analysis to ascertain essential instructional components of repeated reading and the effect of repeated reading on reading 
        fluency and comprehension. This analysis indicates that repeated reading can be used effectively with nondisabled students and 
        students with learning disabilities to increase reading fluency and comprehension on a particular passage and as an intervention 
        to increase overall fluency and comprehension ability. Essential instructional components of repeated reading varied as a function 
        of the type of repeated reading (i.e., whether effectiveness was evaluated reading the same passage or different passages). 
        Implications for future research are also presented.`
    },
    {
        url: "https://www.researchgate.net/publication/231842060_Effects_of_prosodic_modeling_and_repeated_reading_on_poor_readers'_fluency_and_comprehension",
        start: "Young, A.R., Bowers, P.G., & MacKinnon, G.E. (1996). ",
        linkText: "Effects of prosodic modeling and repeated reading on poor readers’ fluency and comprehension.",
        end: " Applied Psycholinguistics, 17, 59-84.",
        bullet: `Repeated reading of meaningful text has been shown to produce improvements in reading rate, fluency, and comprehension in readers of 
        varying ability. The assisted repeated reading (ARR) method, which provides a fluent and expressive (i.e., prosodic) model, has been 
        proposed as being particularly helpful in this regard. However, it is unclear which component of the ARR method (prosodic modeling or 
        reading practice with intact text) is the most influential factor. The present study examined the effects of text practice and prosodic 
        modeling on the reading rate, accuracy, expressiveness, and comprehension of 40 grade 5 disabled readers. Text practice and prosodic 
        modeling were systematically varied to create four training conditions. Each subject read the first half of a set of stories three times 
        under one of the four experimental conditions. Pretest and posttest measures of the dependent variables were analyzed for both the 
        training passages and the second half of each story, on which no training occurred (transfer passages). While reading performance 
        improved across all conditions, substantial additional gains were produced by the conditions that included the practice of intact text. 
        Modeling of prosody did not produce significant additional gains. Transfer effects were limited, with only the ARR condition producing 
        improved accuracy on the second half of the stories.`
    },
    {
        url: "https://www.researchgate.net/publication/259311929_Phonemic_Awareness_Contributes_to_Text_Reading_Fluency_Evidence_From_Eye_Movements",
        start: "Ashby J. ",
        linkText: "Phonemic Awareness Contributes to Text Reading Fluency: Evidence From Eye Movements. School Psychology Review",
        end: " [serial online]. June 2013; 42(2):157-170.",
        bullet: `Although phonemic awareness is a known predictor of early decoding and word recognition, less is known about relationships between 
        phonemic awareness and text reading fluency. This longitudinal study is the first to investigate this relationship by measuring eye 
        movements during picture matching tasks and during silent sentence reading. Time spent looking at the correct target during phonemic 
        awareness and receptive spelling tasks gauged the efficiency of phonological and orthographic processes. Children’s eye movements 
        during sentence reading provided a direct measure of silent reading fluency for comprehended text. Results indicate that children who 
        processed the phonemic awareness targets more slowly in Grade 2 tended to be slower readers in Grade 3. Processing difficulty during a 
        receptive spelling task was related to reading fluency within Grade 2. Findings suggest that inefficient phonemic processing contributes 
        to poor silent reading fluency after second grade.`
    },
]
function SupportResearch() {


    return (<>
        <div id="supportingGL" className="research-section">
                <h2>TEACHING METHODS</h2>
                {teachingMethods.map((article) => {
                    return(<div key={article.url}>
                        <ResearchSummary {...article}/>              
                    </div>)
                })}
                <h2>PROGRAM DESIGN</h2>
                {programDesign.map((article) => {
                    return(<div key={article.url}>
                        <ResearchSummary {...article}/>              
                    </div>)
                })}
        </div>
    </>)

}

export default SupportResearch;
