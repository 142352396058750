function Request()
{
	var request = new XMLHttpRequest();
	this.request = request;
	
	this.path = "";
	this.method = "POST";
	this.data = {};
	
	this.validator = function(){};
}

Request.prototype.send = function()
{
	var request = this.request;
	
	request.open(this.method, this.path, true);
	request.send(this.data);
};

Request.prototype.addEventListener = function(type, callback)
{
	this.request.addEventListener(type, this.dispatchEvent.bind(this, callback));
};

Request.prototype.dispatchEvent = function(callback, event)
{
	var target = event.target;
	
	event.readyState = target.readyState;
	event.status = target.status;
	event.responseText = target.responseText;
	
	event.validator = this.validator;
	
	callback(event);
};

export default Request;
