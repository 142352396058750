import React from 'react'
import axios from 'axios'
import * as studentsModel from '../../../shared/students'
import { Programs } from '../../../shared/students'
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { useRoster } from './StudentsContext'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import EditLicenses from './EditLicenses'

type ArchiveProps = {
    show: boolean
}

function ArchiveSubmit({ invalidPrograms }: { invalidPrograms: any[] }) {
    const { values, isSubmitting } = useFormikContext<{ programs: any[] }>()
    const newLicenses = values.programs.filter(license => !invalidPrograms.includes(license))
    return <OverlayTrigger placement="right" overlay={
        <Tooltip id="tooltip-disabled-archive" className={newLicenses.length === 0 ? "tooltip-disabled" : "d-none"}>
            You have no licenses selected to archive
        </Tooltip>
    }>
        <div className="text-center px-2 py-3">
            <Button variant="secondary" size="lg" type="submit" disabled={isSubmitting || newLicenses.length === 0}>
                <i className="fas fa-archive pe-2"></i>
                Archive
            </Button>
        </div>
    </OverlayTrigger>
}

function Archive(props: ArchiveProps) {

    const alreadyArchivedTooltip = "This license has already been archived"
    const roster = useRoster()
    const archivablePrograms = []
    const invalidPrograms = Programs.reduce((prev, program) => {
        const existingLicense = roster.selectedStudent?.licenses?.find((license) => license.program === program)
        if (!existingLicense?.hasDoneProbe || existingLicense.status === "Archived") {
            prev.push(program)
        }
        else if(existingLicense.hasDoneProbe) archivablePrograms.push(program)
        return prev
    }, []) ?? []

    return (<>
        <Modal show={props.show} onHide={roster.hideModals} size="lg" id="archive-modal">
            <Modal.Header closeButton className="border-bottom-0">
                <h2 className="w-100">Archive</h2>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    programs: archivablePrograms
                }}
                onSubmit={async (values) => {
                    try {
                        const programs = values.programs.filter((program) => {
                            return !invalidPrograms.includes(program)
                        })
                        await axios.post('/api/students/archive', {
                            studentID: roster.selectedStudent.id,
                            programs
                        }).then((res) => {
                            toast.success("Archive successful.")
                        }).catch((err) => {
                            toast.error("Error occurred while archiving")
                        })
                        roster.refreshStudents()
                        roster.hideModals()
                    }
                    catch(error) {
                        toast.error(error?.response?.data?.message ?? "There has been an error.")
                    }
                }}
            >
            {({isSubmitting}) => (
                <FormikForm>
                <Modal.Body className="mx-5">
                    <p>Select the following license(s) you want to archive for:</p>

                    <Row className="pb-3 d-none d-md-flex">
                        <Col>Name</Col>
                        {studentsModel.Programs.map((program) => (
                            <Col className='mx-1'>{program[0].toUpperCase() + program.slice(1)} License</Col>
                        ))}
                    </Row>
                    <Row className={"row-cols-md-3"}>
                        <Col>{roster?.selectedStudent?.name}</Col>
                        <EditLicenses disabledPrograms={invalidPrograms} checkedDisabledTooltip={alreadyArchivedTooltip}/>
                    </Row>
                </Modal.Body>
                <Row className="pt-4 archive-warning">
                    <Col xs={2} sm={1}>
                        <i className="fas fa-exclamation-circle text-danger fa-2x"></i>
                    </Col>
                    <Col>
                        <p><span className="text-danger">ATTENTION:</span> Please note that archiving a student license <strong> cannot </strong> be undone online.</p>
                        <p>If you would like to restore an archived student, please
                            <strong>
                                <a href="https://greatleaps.com/index.php?main_page=contact_us"> contact us using our contact form </a>
                            </strong>
                            for assistance. We recommend archiving licenses for students that are no longer going to be active in that Great Leaps program.
                        </p>
                    </Col>
                </Row>
                <Modal.Body>
                    <ArchiveSubmit invalidPrograms={invalidPrograms}></ArchiveSubmit>
                </Modal.Body>
            </FormikForm>
            )}
            </Formik>
        </Modal>
    </>)
}

export default Archive
