import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { Error } from './types/error'
import { StoryLevels } from './reading'
export interface Status extends Error {
    reading: {
        active : number,
        used : number,
        available : number,
        delegated: number
    }
    math: {
        active : number,
        used : number,
        available : number,
        delegated: number
    }
}

export type ChartData = {
    adjacent: {
        prev: {
            id: string
        },
        next: {
            id: string
        }
    }
    valid?: boolean, // todo remove when backend doesnt change response types
    message?: string, // todo remove when backend doesnt change response types
    startDate: Date,
    endDate: Date,
    chartGoals: Date[],
    suggestedStory: StoryLevels,
    rawRecords: {
        date: Date,
        number: number,
        teacherName: string,
        responses: number,
        maxResponses: number,
        time: number,
        wpm: number,
        errors: number,
        responseGoal: boolean,
        errorGoal: boolean
    }[]
}

/**
 * Gets the subscription status of the account
 */
export function get(studentID: string, type: string) : Promise<ChartData> {
    return modelRequest.get('/api/records', {
        params: {
            studentID: studentID,
            type: type
        }
    })
}
export function getWeeklySessionCounts(studentID: string) : Promise<any> {
    return modelRequest.get('/api/fidelity', {
        params: {
            studentID: studentID,
        }
    })
}
