import React from 'react'
import StudentModal from './StudentModal'
import customRequest from '../../../helpers/customRequest'
import { useRoster } from './StudentsContext'

type RemoveSharedProps = {
    show: boolean
}

function RemoveShared(props: RemoveSharedProps) {
    const roster = useRoster()
    const removeSharedStudent = async () => {
        const res = await customRequest.delete('/api/students/share', {data: {
            studentID: roster.selectedStudent.id
        }})
        roster.refreshStudents()
        return res
    }
    return (<React.Fragment>
        <StudentModal show={props.show} hide={roster.hideModals} action="Remove Shared" submitAction={removeSharedStudent}>
            <div className="mb-3 row">
                <label className="d-none d-sm-inline col-sm-6 control-label text-end">Sharing User</label>
                <div className="col-sm-6">
                    <p className="form-control-static text-start text-center-xs break-words">{roster?.selectedStudent?.owner?.username}</p>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="d-none d-sm-inline col-sm-6 control-label text-end">Student Name</label>

                <div className="col-sm-6">
                    <p className="form-control-static text-start text-center-xs break-words">{roster?.selectedStudent?.name}</p>
                </div>
            </div>
        </StudentModal>
    </React.Fragment>)
}

export default RemoveShared
