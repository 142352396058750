import React from "react"
import { useFormik } from "formik"
import ClipLoader from "react-spinners/ClipLoader"
import * as Yup from "yup"
import customRequest from "../../../helpers/customRequest"
import message from "../../../components/message/message"
import initializeLocations from "../../../helpers/forms/location-form"
import { initializeFormValidation } from "../../../helpers/forms/form-validation"
import passwordValidator from "../../../validators/passwordValidator"

export default function AccountPassword() {
    React.useEffect(() => {
        initializeLocations()
        initializeFormValidation()
    }, [])
    
    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            passwordConfirmation: ""
        },
        validationSchema: Yup.object().shape({
            newPassword: passwordValidator()
        }),
        onSubmit: async (values) => {
            const GENERIC_ERROR = "There was an error resetting your password. Please try again."
            await customRequest.post("/api/account/change-password", {
                "currentPassword": values.currentPassword,
                "newPassword": values.newPassword
            })
            .then(({data}) => {
                message.clear()

                if (data.valid === false) {
                    message.error(data.message ?? GENERIC_ERROR)
                    return
                }

                message.success("Your password has been successfully updated.")
                formik.resetForm()
            })
            .catch(() => {
                message.error(GENERIC_ERROR)
            })
        }
    })

    return (
        <form
            onSubmit={formik.handleSubmit}
            className = "form-horizontal form-validate"
            noValidate
        >
                <div className = "mb-3 row">
                    <label htmlFor = "current-password" className = "d-none d-sm-inline col-sm-3 control-label text-end">Current&nbsp;Password</label>
                    
                    <div className = "col-sm-6">
                        <input
                            type = "password"
                            disabled={formik.isSubmitting}
                            className = "form-control string"
                            id = "current-password"
                            name = "currentPassword"
                            placeholder = "Current Password"
                            value={formik.values.currentPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            data-type = "current password"
                            data-description = "enter your current password"
                            autoFocus
                            required
                        />
                    </div>
                </div>
                
                <div className = "mb-3 row">
                    <label htmlFor = "password"  className = "d-none d-sm-inline col-sm-3 control-label text-end">New&nbsp;Password</label>
                    
                    <div className = "col-sm-6">
                        <input
                            type = "password"
                            disabled={formik.isSubmitting}
                            className = {`form-control string ${formik.touched.newPassword && !!formik.errors.newPassword && "is-invalid"}`}
                            id = "password"
                            name = "newPassword"
                            placeholder = "New Password"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <div className="invalid-feedback" style={{ fontSize: "16px" }}>
                            {formik.errors.newPassword}
                        </div>
                    </div>
                </div>
                
                <div className = "mb-3 row">
                    <label htmlFor = "password-confirmation" className = "d-none d-sm-inline col-sm-3 control-label text-end">Confirm&nbsp;Password</label>
                    
                    <div className = "col-sm-6">
                        <input
                            type = "password"
                            disabled={formik.isSubmitting}
                            className = "form-control confirmation"
                            id = "password-confirmation"
                            name = "passwordConfirmation"
                            placeholder = "Confirm Password"
                            value={formik.values.passwordConfirmation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            data-type = "passwords"
                            data-confirms = "password"
                            data-description = "confirm your new password"
                        />
                    </div>
                </div>
                
                <button type = "submit" className = "btn btn-secondary btn-lg" disabled={formik.isSubmitting}>{formik.isSubmitting ?
                        <ClipLoader
                                size={20}
                                color={"#123abc"}
                                loading={formik.isSubmitting}
                                /> : "Change Password"}
                </button>
            </form>
    )
}
