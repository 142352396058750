import Message from './message';
import Input from '../input';

function ErrorMessage(text)
{
	this.parent.constructor.call(this, Input.error, text);
}

ErrorMessage.prototype = new Message();
ErrorMessage.prototype.parent = Message.prototype;
ErrorMessage.prototype.constructor = ErrorMessage;

export default ErrorMessage;
