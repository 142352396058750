import React, { useContext } from 'react'
import HelpButton from './HelpButton'
import Common from '../../context/Common'

function ProbeHelp() {
    const common = useContext(Common)
    let auth = common?.auth
    if (auth?.[0] !== "customer") {
        return <React.Fragment></React.Fragment>
    }

    return (
        <React.Fragment>
            <HelpButton modelId = 'probe-help' />

            <div className="modal fade" id = "probe-help" role = "dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Exercise Help</h3>
                            <button type = "button" className="close" data-dismiss = "modal">&times;</button>
                        </div>
                        <div className="modal-body row text-start" style={{marginLeft: '10px', marginRight: '10px', fontSize: '17px'}}>
                            <h3 style={{marginTop: '3px'}}>Instruction Tips for Exercises</h3>
                            <ul>
                                <li style={{paddingBottom: '10px'}}>Before beginning, make sure that the student is comfortable and focused.</li>
                                <li style={{paddingBottom: '10px'}}><strong>IMMEDIATE CORRECTION: Immediately correct errors</strong> and click or tap the word once to mark it as an error, highlighting it in red.</li>

                                <li style={{paddingBottom: '10px'}}>If the student <strong>finishes before time is up</strong>, stop the timer and double click/tap the last word of the exercise to provide the word count.</li>

                                <li style={{paddingBottom: '10px'}}><strong>MODELING:</strong> After the timing, choose a section, one or two lines that contained errors, to model with the student. First you read it properly, then read it together with the student and finally have them read it on their own. </li>

                                <li style={{paddingBottom: '10px'}}><strong>POSITIVE REINFORCEMENT:</strong> Frequent encouragement as students work through timings is essential for keeping focus and motivation.</li>
                                <li><strong>You must immediately correct errors, and model segments for fidelity.</strong></li>
                            </ul>
                            <h3 style={{width: '100%'}}>Watch</h3>
                            <p style={{width: '100%'}}>
                                <a href = "https://www.youtube.com/watch?v=h51TfIYyZ1A" target="_blank" rel="noreferrer">Immediate Correction Example</a>
                            </p>
                            <br />
                            <p style={{width: '100%'}}>
                                <a href = "https://www.youtube.com/watch?v=zU0xDgUO5aI" target="_blank" rel="noreferrer">Modeling Example</a>
                            </p>
                            <p style={{marginBottom: '25px', width: '100%'}}>
                                <a href = "https://www.youtube.com/watch?v=2MdUOxKM6sY&t=2s" target="_blank" rel="noreferrer">Positive Reinforcement Example</a>
                            </p>

                            <p className="w-100">For additional reading instructions, <a href={"/file/instructions"} target="_blank" rel="noreferrer">click here</a>.</p>
                            <p className="w-100">For additional math instructions, <a href={"/file/math_instructions"} target="_blank" rel="noreferrer">click here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProbeHelp
