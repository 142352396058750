import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import blobRight from '../../../content/images/blob_right.svg'

export default function ModalTemplate({show, onHide, header, children}) {
    return(<>
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <b>{header}</b>
            </Modal.Header>
            <Modal.Body className="text-center overflow-hidden">
                <Image src={blobRight}  className="position-absolute blob h-100" style={{right:0, bottom:0, opacity:"60%"}}/>
                {children}
            </Modal.Body>
        </Modal>
    </>)
}
