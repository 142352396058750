import React, {useState} from 'react'
import getURLParameters from '../../../helpers/getURLParams'
import customRequest from '../../../helpers/customRequest'
import redirect from '../../../components/redirect/redirect'
import message from '../../../components/message/message'
export default function Unsubscribe() {
    const [submitting, setSubmitting] = useState(false)

    let params = getURLParameters(this.props)

    if (!params.email) {
        redirect.send("/", this.props, () => {
            message.error("There was an error subsubscribing your email. Please navigate back to the email and click the unsubscribe link again.")
        })
    }

    return (
        <React.Fragment>
            <h1>Unsubscribe {params.email}</h1>

            <div className="row subheader-text">
                <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <p>Please click the button below to unsubscribe the following email address from our email listing: {params.email}</p>
                </div>
            </div>

            <form className="form-horizontal form-validate" novalidate method = "post">
                <button disabled={submitting}
                        type = "submit"
                        className="btn btn-secondary btn-lg"
                        onClick = {() => {
                            setSubmitting(true)
                            customRequest.post("/api/account/unsubscribe", {email: params.email})
                            .then(({data: response}) => {
                                setSubmitting(false)
                                if (response.valid) {
                                    redirect.send("/", this.props, () => {
                                        message.success("Your email has been removed from our email list.")
                                    })
                                }
                                else if (response.valid === false) {
                                    message.error(response.message)
                                }
                                else {
                                    message.error("There has been an error processing your request. Please try again later.")
                                }
                            })
                        }}>Unsubscribe</button>
            </form>
        </React.Fragment>
    )

}

