import { modelRequest } from "@stem-sims/great-leaps-react-common"

interface ZipcodeReturn {
    valid: boolean
    country: string
    state: string
    city: string
}

export function get(zipcode: string): Promise<ZipcodeReturn> {
   return modelRequest.get("/api/zipcode?zipcode=" + zipcode)
}
