import graph from './graph'
var $ = require('jquery')

var printCharts = {};

printCharts.printInitialize = function(currentChart="performanceChart")
{
	window.removeEventListener("DOMContentLoaded", printCharts.printInitialize);
	
	var printButton = document.getElementById("printer-button");
	if (printButton) {
		printButton.addEventListener("click", (e) => printCharts.printCharts(e,currentChart));
		window.addEventListener("beforeprint", (e) => printCharts.printCharts(e,currentChart));
		printButton.addEventListener("mouseover", printCharts.hoverEnterPrint);
		printButton.addEventListener("mouseout", printCharts.hoverExitPrint);
	}
}

printCharts.printCharts = function(event,currentChart)
{
	var printButton = document.getElementById("printer-button");
	printButton.removeEventListener("click", printCharts.printCharts);
	printButton.style.color = "";
	
	var printableChart = document.getElementById("printable-charts");
	if (printableChart && printableChart?.getAttribute("data-generated") !== "true" && currentChart !== "studentSummary") {
		printableChart.setAttribute("data-generated", "true");
		$(document).css("cursor", "progress");
		$("body").css("cursor", "progress");
		
		graph.createPrintableCharts();
		
		$("body").css("cursor", "default");
		$(document).css("cursor", "default");
	}
	
	if (event.type !== "beforeprint")
		window.print();

	printButton.addEventListener("click", printCharts.printCharts);
}

printCharts.hoverEnterPrint = function(event)
{
	event.target.style.color = "#507BAE";
}

printCharts.hoverExitPrint = function(event)
{
	event.target.style.color = "";
}

export default printCharts
