import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import getURLParameters from '../../helpers/getURLParams';
import customRequest from '../../helpers/customRequest'
import message from '../../components/message/message';
import redirect from '../../components/redirect/redirect';
import ReactGA from 'react-ga4'

class Processing extends React.Component
{
    constructor()
    {
        super()
        this.state = {
            transaction: null
        }
    }

    componentDidMount()
    {
        let params = getURLParameters(this.props)
        if(params.status && params.checkout_id){
            customRequest.get(`/api/transaction/completed/?status=${params.status}&checkout_id=${params.checkout_id}`)
            .then((response)=>
            {
                let transaction = response.data
                redirect.send('/students', this.props, ()=>{
                    if (transaction.valid ) {
                        message.success(transaction.message)
                        if (transaction.status === 'success') {
                            ReactGA.event({
                                category: 'digitalCheckout',
                                action: 'purchase'
                              })
                        }
                    }
                    else {
                        message.error(transaction.message)
                    } 
                })
            })
        }
        else{
            redirect.send('/', this.props, ()=>{
                message.error('You do not have access to this page')
            })
        }
    }

    render()
    {
        return(
            <BeatLoader></BeatLoader>
        )
    }    
}

export default Processing
