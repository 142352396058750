import React, { useEffect } from 'react'
import PriceCard from "./PriceCard"
import mathIcon from '../../content/images/math.svg'
import getPriceData from '../../helpers/getPriceData'

type MathCardProps = {
    mathInput?: number
}

function MathCard({mathInput}: MathCardProps) {
    const [mathPrice, setMathPrice] = React.useState(null)

    useEffect(() => {
        getPriceData({math: mathInput}).then((response) => {
            setMathPrice(response.prices.math)
        })
    },[mathInput])

    const mathFeatures = [
        'Teaches Addition, Subtraction, Multiplication, and Division basic math facts to automaticity with full understanding',
        'Automatic charting allows for easy progress reporting and data analysis',
        'One minute timed exercises implemented with direct instruction 3 - 5 days per week',
    ]
    return (<>
        <PriceCard
            title={'Math Facts'}
            icon={mathIcon}
            features={mathFeatures}
            price={mathPrice}
            name="math"
            priceUnit={'/student'}
            color={'coral'}
            hasInput={mathInput !== undefined}
        />
    </>)
}
export default MathCard;
