
/**
* Generates an escaped URL query
* 
* Examples: 
*  ```JavaScript
*  //Output: "name='Raymond%20%26%20Bill'""
*  buildQuery({ name: 'Raymond & Bill' })
*  ```
*
* @param {Object.<string, string>} query Object of names and values
*
* @return {String} stringifyed and escaped query
*/
export default function buildQuery(query) {
    let stringQuery = ""
    let first = true
    for (let name in query) {
        
        if (first) first = false

        if (query[name] !== null && query[name] !== undefined && query[name] !== "") {
            stringQuery += `${!first ? '&' : ''}${encodeURIComponent(name)}=${encodeURIComponent(query[name])}`
        }
    }
    return stringQuery
}
