import eventManager from '../../helpers/eventManager'

export const redirect = {
    send: (url: string, props, callback = () => {}) => {
        if (!props?.history) {
            return eventManager.triggerEvent("REDIRECT", {url: url, callback: callback})
        }
        
        props.history.push(url)
        callback()
    }
}
export default redirect
