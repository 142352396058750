import React from 'react'
import Button from 'react-bootstrap/Button'
import ClipLoader from 'react-spinners/ClipLoader'

export default class SubmitButton extends React.Component<{ submitting: boolean, disabled?: boolean, children: React.ReactNode }> {
    render () {
        let { submitting, disabled } = this.props
        return (
            <Button type="submit" variant="primary" id = "organization-name-submit" className = "btn-lg" disabled = {submitting || disabled}>
                {
                    submitting
                        ?
                        <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={submitting}
                        />
                        : this.props.children
                }
            </Button>

        )
    }
}
