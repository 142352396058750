import axios from "axios"

function validator(error)
{
    var message = error?.response?.data?.message ?? "There has been an error connecting to the server."

    if (!window.navigator.onLine) {
        message = "You don't appear to be online."
    }

    return Promise.resolve({data: {
        valid: false,
        message: message
    }})
}
const api = axios.create({
    headers: { Pragma: 'no-cache' },
  });

export const customRequest = {
    get : (...params) =>
    {
        return api.get(...params)
        .catch((err) => validator(err, ...params))
    },

    post : (...params) =>
    {
        return api.post(...params)
        .catch((err) => validator(err, ...params))
    },

    put:  (...params) => {
        return api.put(...params)
        .catch((err) => validator(err, ...params))
    },

    delete: (...params) => {
        return api.delete(...params)
        .catch((err) => validator(err, ...params))
    },
    axios: api
}
export default customRequest
