import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import ReactGA from "react-ga4"
import { Link } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import Button from "react-bootstrap/Button"
import { getURLParams } from "@stem-sims/great-leaps-react-common"
import message from "../../../components/message/message"
import redirect from "../../../components/redirect/redirect"
import * as countries from "../../../models/countriesModel"
import * as signUp from "../../../models/signUp"
import * as zipcode from "../../../models/zipcode"
import * as statesModel from "../../../models/states"
import { initializeFormValidation, validForm } from "../../../helpers/forms/form-validation"
import passwordValidator from "../../../validators/passwordValidator"

export default function SignUpTemplate(props) {
    const params = getURLParams()
    const isSSO = params.sso === "true"
    const { updatePermissions } = props
    const [countriesOptions, setCountriesOptions] = React.useState([])
    const [states, setState] = React.useState<statesModel.StatesReturns[]>([])
    const [postalCodeTimeout, setPostalCodeTimeout] = React.useState(null)

    const signUpForm = React.useRef()
    const privacyPolicy = React.useRef<HTMLInputElement>()

    const formik = useFormik({
        initialValues: {
            name: ((params?.firstName ?? "") + " " + (params?.lastName ?? "")).trim(),
            username: "",
            emailAddress: params?.emailAddress ?? "",
            password: "",
            confirmPassword: "",
            referral: "",
            entity: "",
            role: "",
            postalCode: "",
            city: "",
            country: "",
            state: "",
            phoneNumber: "",
            notes: "",
            organizationInvite: params.invite,
            samlID: params?.id
        },
        validationSchema: Yup.object().shape({
            password: passwordValidator()
        }),
        onSubmit: async (values) => {
            var blurEvent = document.createEvent("Event")
            blurEvent.initEvent("blur", false, true)
            if (!validForm(signUpForm.current, blurEvent)) {
                return
            }

            if (!privacyPolicy?.current?.checked) {
                return message.error("You must agree to the privacy policy to use Great Leaps.")
            }

            try {
                const response = await signUp.post(values)
                if (!response.valid) {
                    return message.error(response.message)
                }
                
                ReactGA.event({
                    category: "digitalSignUp",
                    action: "click",
                    label: "button"
                })
                await updatePermissions()
                redirect.send("/account/sign-up/success", props)
            }
            catch(error) {
                message.error(error.message ?? "There has been an error signing up. Please refresh the page and try again.")
            }

        }
    })

    React.useEffect(() => {
        const returnClientError = (response) => {
            message.error(response?.message ?? "There has been an error loading the locations. Please refresh the page and try again.")
        }

        /**
         * Legacy sign up functions
         */
        initializeFormValidation()

        countries.get().then((response) => {
            if (response.valid === false || !response.countries) {
                return returnClientError(response)
            }
            setCountriesOptions(response.countries)
        })
        .catch((response) => {
            returnClientError(response)
        })
    }, [])

    const getStates = async (country: string) => {
        try {
            const states = await statesModel.get(country)
            if ((states as any)?.valid === false) {
                return message.error((states as any)?.message ?? "There has been an error loading your location. Please refresh the page and try again.")
            }
            setState(states)
            formik.setFieldValue("state", "")
        }
        catch(error) {
            message.error(error?.message ?? "There has been an error loading your location. Please refresh the page and try again.")
        }
    }

    return <>

    <div className="row subheader-text">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 mt-4">
            <h1 className="mt-2">Sign Up to Get Started with Great Leaps</h1>
            <p>Fill out this form with your information, click the "Create Account" Button, and activate your account by following the instructions we send to your email address.</p>

            <h2 className="h5"><strong>All new accounts come with a 14-day free trial. Register a student and get started!</strong></h2>
        </div>
    </div>

    <form
        id="sign-up-form"
        className="form-horizontal form-validate"
        noValidate
        method="post"
        onSubmit={formik.handleSubmit}
        ref={signUpForm}
    >
        <div className="mb-3 row">
            <label htmlFor="name" className="d-none d-sm-inline col-sm-3 control-label text-end">Name</label>

            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        disabled={formik.isSubmitting || (params.firstName && params.lastName && isSSO)}
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Name"
                        required
                        data-description="enter a name"
                        autoFocus={true}
                    />
                    
                    <span className="input-group-text">First and Last</span>
                </div>
            </div>
        </div>

        <div className="mb-3 row">
            <label htmlFor="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>

            <div className="col-sm-6">
                <input
                    type="text"
                    className="form-control unique"
                    disabled={formik.isSubmitting}
                    id="username"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    placeholder="Username (make short and simple)"
                    required
                    data-description="enter a username"
                />
            </div>
        </div>

        <div className="mb-3 row">
            <label htmlFor="email-address" className="d-none d-sm-inline col-sm-3 control-label text-end">Email&nbsp;Address</label>

            <div className="col-sm-6">
                <input
                    type="email"
                    className="form-control unique"
                    disabled={formik.isSubmitting || (params.emailAddress && isSSO)}
                    id="email-address"
                    name="emailAddress"
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    placeholder="Email Address"
                    required
                    data-description="enter an email address"
                />
            </div>
        </div>

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="password" className="d-none d-sm-inline col-sm-3 control-label text-end">Password</label>

            <div className="col-sm-6">
                <input
                    type="password"
                    className={`form-control string ${formik.touched.password && !!formik.errors.password && "is-invalid"}`}
                    disabled={formik.isSubmitting}
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Password"
                />
                <div className="invalid-feedback" style={{ fontSize: "16px" }}>
                    {formik.errors.password}
                </div>
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="password-confirmation" className="d-none d-sm-inline col-sm-3 control-label text-end">Confirm&nbsp;Password</label>

            <div className="col-sm-6">
                <input
                    type="password"
                    className="form-control confirmation"
                    disabled={formik.isSubmitting}
                    id="password-confirmation"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    placeholder="Confirm Password"
                    required
                    data-type="passwords"
                    data-confirms="password"
                    data-description="confirm your password"
                />
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="referral" className="d-none d-sm-inline col-sm-3 control-label text-end">How did you hear about us?</label>

            <div className="col-sm-6">
                <div className="input-group">
                    <select
                        className="form-control"
                        disabled={formik.isSubmitting}
                        name="referral"
                        id="referral"
                        value={formik.values.referral}
                        onChange={formik.handleChange}
                        data-description="select a referral"
                    >
                        <option disabled value="">Referral:</option>
                        <option value="Web">Web</option>
                        <option value="Conference">Conference</option>
                        <option value="Social Media" >Social Media</option>
                        <option value="School">School</option>
                        <option value="SEEN Magazine/Email">SEEN Magazine/Email</option>
                        <option value="Other">Other</option>
                    </select>
                    <span className="input-group-text">optional</span>
                </div>
            </div>
        </div>
        }

        <div className="mb-3 row">
            <label htmlFor="entity" className="d-none d-sm-inline col-sm-3 control-label text-end">School/Company/Entity</label>

            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control string"
                        disabled={formik.isSubmitting}
                        name="entity"
                        id="entity"
                        value={formik.values.entity}
                        onChange={formik.handleChange}
                        placeholder="School/Company/Entity"
                        maxLength={175}
                        data-type="entity"
                        data-description="enter a School/Company/Entity"
                    />

                    <span className="input-group-text">optional</span>
                </div>
            </div>
        </div>

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="role" className="d-none d-sm-inline col-sm-3 control-label text-end">Role</label>

            <div className="col-sm-6">
                <select
                    className="form-control"
                    disabled={formik.isSubmitting}
                    name="role"
                    id="role"
                    required
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    data-description="select a role"
                >
                    <option disabled value="">Role:</option>
                    {
                        ["Parent", "Tutor", "Teacher", "Administrator", "Other"].map((role) =>
                        {
                            return <option value={role} key={role}>{role}</option>
                        })
                    }
                </select>
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="postal-code" className="d-none d-sm-inline col-sm-3 control-label text-end">Postal Code</label>

            <div className="col-sm-6">
                <input
                    type="text"
                    className="form-control string"
                    disabled={formik.isSubmitting}
                    name="postalCode"
                    id="postal-code"
                    value={formik.values.postalCode}
                    onChange={(event) => {
                        formik.handleChange(event)
                        
                        if (postalCodeTimeout) {
                            clearTimeout(postalCodeTimeout)
                            setPostalCodeTimeout(null)
                        }

                        setPostalCodeTimeout(
                            setTimeout(async () => {
                                setPostalCodeTimeout(null)
                                const location = await zipcode.get(event.target.value)

                                if (location.valid === false) {
                                    return
                                }

                                formik.setFieldValue("city", location.city)
                                formik.setFieldValue("country", location.country)
                                await getStates(location.country)
                                formik.setFieldValue("state", location.state)
                            }, 1000)
                        )
                    }}
                    required
                    placeholder="Postal Code"
                    maxLength={175}
                    data-type="postal code"
                    data-description="enter a postal code"
                />
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="city" className="d-none d-sm-inline col-sm-3 control-label text-end">City</label>

            <div className="col-sm-6">
                <input
                    type="text"
                    className="form-control string"
                    disabled={formik.isSubmitting}
                    name="city"
                    id="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    placeholder="City"
                    maxLength={175}
                    data-type="city"
                    data-description="enter a city"
                    required
                />
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="country" className="d-none d-sm-inline col-sm-3 control-label text-end">Country</label>

            <div className="col-sm-6">
                <select
                    className="form-control"
                    disabled={formik.isSubmitting}
                    name="country"
                    id="country"
                    onChange={async (event) => {
                        formik.handleChange(event)
                        getStates(event.target.value)
                    }}
                    value={formik.values.country}
                    required
                    data-description="select a country"
                >
                    <option value="" disabled>Country:</option>
                    {countriesOptions?.map((item) => {
                        return (<option value={item} key={item}>{item}</option>)
                    })}
                </select>
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="state" className="d-none d-sm-inline col-sm-3 control-label text-end">State or Province</label>

            <div className="col-sm-6">
                <select
                    className="form-control"
                    disabled={formik.isSubmitting}
                    name="state"
                    id="state"
                    onChange={formik.handleChange}
                    value={formik.values.state}
                    required
                    data-description="select a state or province"
                >
                    <option value="" disabled>State or Province:</option>
                    {states.length === 0 ? <option value="" disabled >Please select a country first.</option> : ""}
                    {states?.map((item) => {
                        return (<option value={item.State} key={item.State}>{item.State}</option>)
                    })}
                </select>
            </div>
        </div>
        }

        { !isSSO && <div className="mb-3 row">
            <label htmlFor="phone" className="d-none d-sm-inline col-sm-3 control-label text-end">Phone Number</label>

            <div className="col-sm-6">
                <input
                    type="text"
                    className="form-control string"
                    disabled={formik.isSubmitting}
                    name="phoneNumber"
                    id="phone"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    placeholder="Phone Number"
                    maxLength={175}
                    data-type="phone number"
                    data-description="enter a phone number"
                    required
                />
            </div>
        </div>
        }

        <div className="mb-3 row">
            <label htmlFor="notes" className="d-none d-sm-inline col-sm-3 control-label text-end">Notes</label>

            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control string"
                        disabled={formik.isSubmitting}
                        name="notes"
                        id="notes"
                        value={formik.values.notes}
                        onChange={formik.handleChange}
                        placeholder="Notes..."
                        maxLength={175}
                    />
                    <span className="input-group-text">optional</span>
                </div>
            </div>
        </div>

        <div className="text-nowrap text-center">
            <input
                type="checkbox"
                disabled={formik.isSubmitting}
                id="agree-policy"
                name="agree-policy"
                value="yes"
                onChange={formik.handleChange}
                required
                style={{ margin: "5px" }}
                data-description="agree to the privacy policy and terms of use."
                ref={privacyPolicy}
            />
            <label htmlFor="agree-policy">I agree to GreatLeaps's <Link to="/legal/terms-of-use" target="_blank">Terms of Use</Link> and <Link to="/legal/privacy-policy" target="_blank">Privacy Policy</Link>.</label>
        </div>

        <Button type="submit" variant="secondary" id="sign-up-button" className="d-block mx-auto my-4" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? 
                <ClipLoader
                    size={20}
                    color={"#123abc"}
                    loading={formik.isSubmitting}
                />
            : "Create Account"}
        </Button>
    </form>
</>
}
