import React from 'react'
import Skeleton from 'react-loading-skeleton'

class LoadingNavbar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-light bg-light fixed-top hidden-print navbar-expand-md px-1 py-0">
                <div className="navbar-brand navbar-brand-default mx-auto">
                    <Skeleton circle={true} height={60} width={60} />
                </div>

                <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>&#x2630;
                </button>

                <div className="collapse navbar-collapse" id="navbar-collapse" aria-expanded="false">
                    <ul className="nav navbar-nav">
                        <li className="nav-item">
                            <Skeleton width={80} height={24} />
                        </li>
                        <li className="nav-item">
                            <Skeleton width={80} height={24} />
                        </li>
                        <li className="nav-item">
                            <Skeleton width={80} height={24} />
                        </li>
                    </ul>

                    <ul className="nav navbar-nav ms-auto">
                        <li className="nav-item">
                            <Skeleton width={80} height={24} />
                        </li>
                        <li className="nav-item">
                            <Skeleton width={80} height={24} />
                        </li>
                        <li className="nav-item">
                            <Skeleton width={80} height={24} />
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default LoadingNavbar
