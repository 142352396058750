import React from 'react'

function HelpButton({modelId}) {
    return (<button 
        type="button"
        id={'probe-help-btn'}
        className="btn btn-link move-forward modal-btn float-right"
        style={{outline: 'none', boxShadow: 'none'}}
        data-toggle="modal"
        data-target={`#${modelId}`}>
        <span className="fas fa-question-circle" aria-hidden="true" style={{color: 'black', fontSize: '25px'}} />
    </button>)
}

export default HelpButton