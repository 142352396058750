import React from "react"
import { Link } from "react-router-dom"
import buildQuery from "../../helpers/buildQuery"
import { MathSubjects } from "../../models/math"

interface MathGroupHeaderParameters {
    studentID: string,
    active: MathSubjects,
    sectionClick?: () => void
    basePath: string
}

export default function MathGroupHeader({studentID, active, sectionClick = () => {}, basePath}: MathGroupHeaderParameters) {
    const standardVariables = buildQuery({ id: studentID })
    return <div className = "row subheader-text justify-content-around">
            <Link
                to = {`${basePath}/addition?${standardVariables}`}
                className = {`col-12 col-md-5 col-lg-2 btn btn-secondary btn-lg ${active === "addition" ? "active" : ""}`}
                onClick = {sectionClick}>
                    Addition
            </Link>
            <Link
                to = {`${basePath}/subtraction?${standardVariables}`}
                className = {`col-12 col-md-5 col-lg-2 btn btn-secondary btn-lg ${active === "subtraction" ? "active" : ""}`}
                onClick={sectionClick}>
                    Subtraction
            </Link>
            <Link
                to = {`${basePath}/multiplication?${standardVariables}`}
                className = {`col-12 col-md-5 col-lg-2 btn btn-secondary btn-lg ${active === "multiplication" ? "active" : ""}`}
                onClick={sectionClick}>
                    Multiplication
            </Link>
            <Link 
                to = {`${basePath}/division?${standardVariables}`}
                className = {`col-12 col-md-5 col-lg-2 btn btn-secondary btn-lg ${active === "division" ? "active" : ""}`}
                onClick={sectionClick}>
                    Division
            </Link>
    </div>
}
