import React from 'react'
import HelpButton from './HelpButton'


function ChartHelp() {
    return (
        <React.Fragment>
            <HelpButton modelId = 'chart-help' />

            <div className="modal fade" id = "chart-help" role = "dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Chart Help</h3>
                            <button type = "button" className="close" data-dismiss = "modal">&times;</button>
                        </div>
                        <div className="modal-body row text-start" style={{marginLeft: '10px', marginRight: '10px', fontSize: '17px'}}>
                            <h3 style={{marginTop: '3px'}}>Tips for Understanding the Student’s Chart</h3>
                            <ul>
                                <li style={{paddingBottom: '10px'}}>On the chart is the <span>words per minute</span> in blue going up as the <span>errors made</span> in red go down showing student progress.</li>

                                <li style={{paddingBottom: '10px'}}>When you scroll down, you can see the <strong>"Raw Exercise Records"</strong> and to the right you can change the order between <strong>ascending or descending.</strong></li>

                                <li style={{paddingBottom: '10px'}}><strong>Data points are connected</strong> when the same exercise is done on consecutive days.</li>

                                <li><strong>Optimal growth with Great Leaps comes from working with a student 5 days a week. Less than 3 days per week is not using it with fidelity.</strong></li>
                            </ul>
                            <h3 style={{width: '100%'}}>Watch</h3>
                            <p style={{marginBottom: '25px', width: '100%'}}>
                                <a href = "https://www.youtube.com/watch?v=-NtuH0q4QpU&t=84s" target="_blank" rel="noreferrer">Student’s Chart Explained</a>
                            </p>
                            <p className="text-center">For additional in depth reading instructions, <a href={"/file/instructions"} target="_blank" rel="noreferrer">click here</a>.</p>
                            <p className="text-center">For additional in depth math instructions, <a href={"/file/math_instructions"} target="_blank" rel="noreferrer">click here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChartHelp
