import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import bubble1 from '../../content/images/bubble-1.png';
import bubble2 from '../../content/images/bubble-2.png';
import bubble3 from '../../content/images/bubble-3.png';
import grade_graph from '../../content/images/grade-graph.png';
import dgl_ipad from '../../content/images/dgl-ipad.png';
import dgl_laptop from '../../content/images/dgl-laptop.png';
import happy_certificate from '../../content/images/happy-certificate.jpg';

function TheProgram() {

    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css" href="/styles/about.css?version=8-28-2021" />
            
            <div className="about-title-image text-center">
                <h1 className="about-title-main h2 mt-5">The Program</h1>
                <Container>
                    <div className="row justify-content-center mt-4">
                        <Col xs={12} md={4} lg={3} className="my-3">
                            <a href="#whositfor">
                                <Button variant="success" className="w-100">Who's it For</Button>
                            </a>
                        </Col>
                        <Col xs={12} md={4} lg={3} className="my-3">
                            <a href="#resultsandbenefits">
                                <Button variant="success" className="w-100">Results & Benefits</Button>
                            </a>
                        </Col>
                        <Col xs={12} md={4} lg={3} className="my-3">
                            <a href="#howitworks">
                                <Button variant="success" className="w-100">How it Works</Button>
                            </a>
                        </Col>
                    </div>
                    <div className="row justify-content-center mb-5">
                        <Col xs={12} md={4} lg={3} className="my-3">
                            <a href="#keyfeatures">
                                <Button variant="success" className="w-100">Key Features</Button>
                            </a>
                        </Col>
                        <Col xs={12} md={4} lg={3} className="my-3">
                            <a href="#whyitmatters">
                                <Button variant="success" className="w-100">Why it Matters</Button>
                            </a>
                        </Col>
                    </div>
                </Container>
            </div>

            <span className="anchor" id="whositfor"></span>
            <div className="content-section-g mt-3" id="whositfor">
                <div className="row">
                    <div className="col-md-6 vcenter">
                        <h2 className="section-title">Who's it For</h2>
                        <ul className="text-start big-section">
                            <li>K-12 Students reading below the 5th grade level</li>
                            <li>Dyslexia, ADHD, and other common learning disabilities</li>
                            <li>Students lacking phonics, comprehension, or fluency</li>
                            <li>Districts, Schools, Learning Centers & Tutors, or Parents</li>
                        </ul>
                        <Link to='/about/use-cases' className="link-button-body">Use Cases <span className="glyphicon glyphicon-chevron-right arrow-icon"></span></Link>
                    </div>
                    <div className="col-md-6 vcenter">
                        <img src={happy_certificate} alt="Great Leaps CEO, Ken giving certificate to ecstatic student" width="100%" />
                    </div>
                </div>
            </div>

            <span className="anchor" id="resultsandbenefits"></span>
            <div className="content-section-w" id="resultsandbenefits">
                <div className="row">
                    <div className="col-md-4 vcenter">
                        <img src={grade_graph} alt="Line graph showing eighteen weeks of growth. Lines representing each reading grade level are going up over time." width="100%" />
                    </div>
                    <div className="col-md-8 vcenter" style={{ paddingLeft: "40px", paddingRight: "50px" }}>
                        <h2 className="section-title">Results & Benefits</h2>
                        <div className="text-start">
                            <p><strong>Fluent, independent reading</strong> that generalizes to benefit students in their classwork and outside lives.</p>
                            <p><strong>Improves student motivation</strong>, behavior and work in other classes as their academic self-confidence builds.</p>
                            <p><strong>Teaches comprehension</strong> and expressive language through Comprehension Development questions relating to the stories.</p>
                            <p><strong>Increased fidelity</strong> comes from the digital data allowing administrators to view student performance, identify outliers, and hold instructors accountable.</p>
                        </div>
                        <Link to='/about/testimonials' className="link-button-body">What Our Customers Say <span className="glyphicon glyphicon-chevron-right arrow-icon"></span></Link>
                    </div>
                </div>
            </div>

            <span className="anchor" id="howitworks"></span>
            <div id="howitworks-container">
                <h2 className="section-title-left">How it Works</h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="d-flex mb-2">
                            <img src={bubble1} className="num-bubble" height="60px" width="60px" alt="1" />
                            <h4 className="box-title">Made for Students</h4>
                        </div>
                        <div className="row">
                            <ul>
                                <li>Fun stories keep students engaged and motivated</li>
                                <li>Positive reinforcement builds confidence</li>
                                <li>15-minute sessions stay within the student’s attention span</li>
                                <li>Goal oriented nature of the program challenges and motivates students</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex mb-2">
                            <img src={bubble2} className="num-bubble" height="60px" width="60px" alt="2" />
                            <h4 className="box-title">Daily Practice</h4>
                        </div>
                        <div className="row">
                            <ul className="text-start">
                                <li>15 minutes/day 3-5 days per week yields impressive growth</li>
                                <li>Students work in phonics, sight phrases and stories with comprehension questions each session</li>
                                <li>Consistent daily practice brings the best results</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex mb-2">
                            <img src={bubble3} className="num-bubble" height="60px" width="60px" alt="3" />
                            <h4 className="box-title">1-on-1 Instruction</h4>
                        </div>
                        <div className="row">
                            <ul className="text-start">
                                <li>Immediate correction by an instructor quickly eliminates errors</li>
                                <li>Modeling also eliminates errors while building speed and proper intonation</li>
                                <li>Constant positive reinforcement builds trust, motivation and self confidence</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <span className="anchor" id="keyfeatures"></span>
            <div className="content-section-w" id="keyfeatures-container">
                <div className="row">
                    <h2 className="section-title-left">Key Features</h2>
                </div>
                <div className="text-start">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <p><strong>Automatic Charting</strong> ensures that student data is kept accurately, shows clear results and makes our program even easier to use than before.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <p><strong>Cross-Platform Capability</strong> means that you can access the program from any internet connected devices such as computers, tablets, or phones.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <p><strong>Student/Teacher Connectivity</strong> links together two separate devices, allowing the teacher full access to all features while the students see only the exercises they are working on. This also allows for doing Great Leaps at a distance.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <p><strong>Student Sharing</strong> allows teachers and administrators to delegate varying levels of student access between accounts. Schools can also allow parents to work with students over the summer which is not possible with physical books.</p>
                        </div>
                    </div>
                    <div id="device-img-row" className="row">
                        <div id="laptop-img" className="col-md-8">
                            <img src={dgl_laptop} alt="Laptop with Great Leaps Digital showing" />
                        </div>
                        <div id="ipad-img" className="col-md-4">
                            <img src={dgl_ipad} alt="Tablet with Great Leaps Digital showing"/>
                        </div>
                    </div>
                </div>
            </div>

            <div id="whyitmatters-container" className='text-center'>
                <div id="overlay-title">
                    <span className="anchor" id="whyitmatters"></span>
                    <h2 id="overlay-text">Why it Matters</h2>
                    <div id="overlay-box-container" className="row equal">
                        <div className="col-sm-6 col-md-3">
                            <div className="col-md-12 box">
                                <h1>90%+</h1>
                                <p>Students with reading troubles can be taught with early intervention</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="col-md-12 box">
                                <h1>85%</h1>
                                <p>Juveniles in the court system are illiterate</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="col-md-12 box">
                                <h1>66%</h1>
                                <p>Students who can't read by 4th grade end up in jail or on welfare</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="col-md-12 box">
                                <h1>4X</h1>
                                <p>3rd graders who can't read are 4x more likely to drop out later in school</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TheProgram;
