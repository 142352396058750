import React from "react"
import Form from "../../../components/forms/Form"
import Input from "../../../components/forms/Input"
import SubmitButton from "../../../components/forms/SubmitButton"
import redirect from "../../../components/redirect/redirect"
import * as adminModel from "../../../models/admin"
import message from '../../../components/message/message'

export default function ForceLogin() {
    return <React.Fragment>
        <h1 className="text-center section-header">Login As Any User</h1>
        <p className="subheader-text">Type in the username of the user you would like to login as below. Note that you will be logged out of your admin account.</p>

        <Form submit={async (values) => {
            const response = await adminModel.forceLogin(values["Username"].value)
            if (response.valid === false) {
                return message.error(response.message)
            }

            redirect.send("/dashboard", {})
        }}>
            {submitting => {
                        return (
                            <React.Fragment>
                                <Input
                                    name="Username"
                                    longName="Customer Username"
                                    submitting={submitting} />
                                <SubmitButton submitting={submitting}>Login</SubmitButton>
                            </React.Fragment>
                        )
                    }}
        </Form>

    </React.Fragment>
}
