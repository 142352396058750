import React, { useEffect } from 'react'
import PriceCard from "./PriceCard"
import readingIcon from '../../content/images/reading.svg'
import getPriceData from '../../helpers/getPriceData'

type ReadingCardProps = {
    readingInput?: number
}

export default function ReadingCard({readingInput}: ReadingCardProps) {
    const [readingPrice, setReadingPrice] = React.useState(null)

    useEffect(() => {
        getPriceData({reading:readingInput}).then((response) => {
            setReadingPrice(response.prices.reading)
        })
    },[readingInput])

    const readingFeatures = [
        'Complete access to exercises in phonics, high frequency word phrases, and age appropriate stories with Comprehension Development questions',
        'Student data is recorded, interpreted for you and kept and accessible at any time even after your subscription expires',
        'Built in sharing and transferring feature to enable administrative oversight and better ensure fidelity with instructors'
    ]
    return (<>
        <PriceCard
            title={'Reading'}
            icon={readingIcon}
            features={readingFeatures}
            price={readingPrice}
            name="reading"
            priceUnit={'/student'}
            color={'blue'}
            hasInput={readingInput !== undefined}
        />
    </>)
}
