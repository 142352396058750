import React from 'react'
import CustomerNavbar from './CustomerNavbar'
import DefaultNavbar from './DefaultNavbar'
import LoadingNavbar from './LoadingNavbar'

class NavbarController extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            Tawk_API: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.auth === null && prevProps.auth !== this.props.auth) {
            // remove loading navbar now that user type is known
            if (this.state.loading) {
                this.setState({
                    loading: false
                })
            }
        }
    }

    componentDidMount() {
        if(window.Tawk_API){
            window.Tawk_API.onLoad = ()=>{
                this.setState({
                    Tawk_API: window.Tawk_API
                })
            }
        }
        if (this.props.auth !== null) {
            if (this.state.loading) {
                this.setState({
                    loading: false
                })
            }
        }
    }
    
    render() {
        if (this.state.loading) {
            return <LoadingNavbar />
        }
        if(this.props.auth.includes("guest") || this.props.auth.includes("customer")) {
            if(this.state.Tawk_API){
                this.state.Tawk_API.showWidget()
                if(window.location.pathname !== "/"){
                    this.state.Tawk_API.minimize();
                }
                else{
                    this.state.Tawk_API.maximize();
                }
            }
        }
        else{
            if(this.state.Tawk_API){
                window.Tawk_API.hideWidget()
            }
        }
        if (this.props.auth.includes("customer")) {
            return <CustomerNavbar {...this.props} homeURL="/dashboard"/>
        } else if (this.props.auth.includes("student")) {
            return <DefaultNavbar {...this.props} homeURL="/student/dashboard" />
        } else {
            return <DefaultNavbar {...this.props} homeURL="/" />
        }
    }
}

export default NavbarController
