import React from 'react'
import { Link } from 'react-router-dom'

class SendEmailHome extends React.Component {

    render() {
        return (
            <React.Fragment>
                <h1>Send Email</h1>

                <div className="row">
                    <div className="col-md-6">
                        <Link to="/admin/activation-codes/send-email" className="btn btn-secondary btn-xl">Activation Codes</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/sales-code/send-email" className="btn btn-secondary btn-xl">Sales Code</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default SendEmailHome
