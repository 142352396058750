import React from 'react'

export default class Input extends React.Component<{
    name: string,
    longName: string,
    submitting: boolean,
    placeholder?: string,
    ref?: React.LegacyRef<HTMLInputElement>,
    defaultValue?: string
    type?: string}> {
    render() {
        let { name, submitting, longName, placeholder, ref, defaultValue, type } = this.props
        return (
            <div className = "mb-3 row">
                <label htmlFor = "username" className = "d-none d-sm-inline col-sm-3 control-label text-end">{name}</label>
                
                <div className = "col-sm-6">
                    <input
                        type = {type ?? "text"}
                        disabled = {submitting}
                        className = "form-control"
                        id = {name}
                        name = {name}
                        placeholder = {placeholder}
                        data-description = {`enter an ${longName}`}
                        defaultValue = {defaultValue}
                        ref = {ref}
                        required
                        autoFocus />
                </div>
            </div>
        )
    }
}
