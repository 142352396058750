


import React, { useContext, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import ClipLoader from "react-spinners/ClipLoader"
import { ProbeContext } from '../ProbeContext'

export default function Timer({timeHasError,submitForm,submitting}) {
    const { setCurrentResponseCount, setErrors, timer, setTimer } = useContext(ProbeContext)
    const [time,setTime] = useState<number>(0)
    const [resetWarning,setResetWarning] = useState<boolean>(false)

    let timerIdentifier = useRef<number>() //used to cancel the timer
    /** 
    * @abstract Called when the start button is clicked
    */
    const startTimer = () => {
        if (time >= 60) return

        window.scrollTo(0, 0)
        setCurrentResponseCount(0)
        setTimer(true)
        const startedAt = Date.now() - (time*1000) // by subtracting current timer, the difference between now & started will be correct
        timerIdentifier.current = window.setInterval(() => {
            setTime((prevTime) => {
                const elapsedTime = Math.floor((Date.now() - startedAt) / 1000)
                if (elapsedTime >= 60) {
                    stopTimer()
                    window.$("#stop-modal").modal("show")
                }
                return elapsedTime
            })
        }, 1000)
    }

    /* 
    *   Stops the timer
    */
    const stopTimer = () => {
        setTimer(false)
        clearInterval(timerIdentifier.current)
        timerIdentifier = null
    }
    
    const resetTimer = () => {
        setCurrentResponseCount(0)
        setErrors([])
        window.$(".response-text").removeClass("error")
        stopTimer()
        setTime(0)
        setResetWarning(false)
    }
    /* 
    *   Called when the time is changed by the input on the page
    */
    const changeTime = (e) => {
        if (parseInt(e.target.value)) {
            setTime(parseInt(e.target.value))
        }
    }

    return(<>
        <form onSubmit={(e) => submitForm(e,time)} className="form-validate my-auto" id="probe-form" noValidate>
            <Row className="timer justify-content-between mx-auto my-3 my-lg-4 g-0">
                <Col className='d-flex'>
                    {timer ? 
                        <Button id="stop-button" variant="danger" className="flex-1 px-3" onClick={stopTimer}>
                            <i className='fa fa-stop px-1'/> 
                            <span className='d-none d-sm-inline'> Stop</span>
                        </Button>
                    :
                        <Button id="start-button" variant="success" className="flex-1 px-3" onClick={startTimer}>
                            <i className='fa fa-play px-1'/> 
                            <span className='d-none d-sm-inline'> Start</span>
                        </Button>
                    }
                </Col>
                <Col className={`${timeHasError ? "has-error" : ""} col-8 col-lg-6 g-0`}>
                    <InputGroup className="h-100">
                        <FormControl
                            type="number"
                            className="h-100 alphabet-text bg-light border-end-0 text-center"
                            id="time"
                            name="time"
                            min="0"
                            max="60"
                            step="1"
                            placeholder="Time"
                            onChange = {changeTime}
                            value = {time}
                            required
                            data-description="enter the number of seconds it took your student to complete this exercise"
                        />
                        <InputGroup.Text className="flex-grow-2 bg-light py-3">
                            <span className='lead'>seconds</span>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col className="d-flex">
                    <OverlayTrigger overlay={
                        <Tooltip id="tooltip-disabled-add-student" className={`${time !== 0 ? "d-none" : "tooltip-disabled"}`}>
                            There is nothing to reset
                        </Tooltip>
                    }>
                        <span>
                            <Button variant="secondary-outline" disabled={time === 0} className={`${time===0 && 'pe-none'} h-100 px-3`} onClick={() => setResetWarning(true)}>
                                <i className='fa fa-undo px-1'/>
                                <span className='d-none d-sm-inline-block'> Reset</span>
                            </Button>
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Button type="submit" variant="primary" disabled={submitting} className="oval btn-lg">
                {submitting ?
                    <ClipLoader
                        size={20}
                        color={"#123abc"}
                        loading={submitting}
                        /> 
                    : "Submit" 
                }
            </Button>
        </form>
        <Modal show={resetWarning} onHide={()=>setResetWarning(false)}>
                <Modal.Header closeButton className=''>
                    Reset Timer
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center py-3'>Are you sure you want to reset the timer?</p>
                    <Row className='bg-light-red py-4'>
                        <Col xs={2}>
                            <i className='fa fa-warning fa-3x gl-coral'/>
                        </Col>
                        <Col xs={10}>
                            <p>
                                <strong className='gl-coral'>ATTENTION: </strong>
                                Resetting the timer will see the time back to zero and erase all grading marks you've made during this current timed session
                            </p>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4 mb-3'>
                        <Button onClick={() => setResetWarning(false)} variant="primary-outline" className='oval'>Cancel</Button>
                        <Button onClick={resetTimer} variant="primary" className='oval'>Reset Timer</Button>
                    </Row>
                </Modal.Body>
        </Modal>
        <div className="modal fade" id="stop-modal" role="dialog" aria-labelledby="stop-modal-label">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="stop-modal-label">Time's up!</h2>
                    </div>
                    <div className="modal-body">
                        <div className="alert alert-danger">Please instruct your student to stop reading and <b>double click/tap the last word read.</b></div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary-outline center-block" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
