import React from 'react'
import { Route } from "react-router-dom"
import redirect from '../components/redirect/redirect'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import getURLParameters from '../helpers/getURLParams'
import message from '../components/message/message'
import { Phonics, Phrases, Stories, Alphabet, MathProbe } from '@stem-sims/great-leaps-react-common';
import { mathSubjects } from '../models/math'
import StoriesDOK from '../pages/probes/StoriesDOK'
import { ProbeContext } from '../pages/probes/ProbeContext'
import Probe from '../pages/probes/Probe'
import blobRight from '../content/images/blob_right.svg'

class Probes extends React.Component {
    /*********************
    * State Variables
    *********************/
    state = {
        showWordModal: true,
        previewQuestions: false,
        currentResponseCount: 0,
        errors: [],
        timer: false,
        popup: "",
        fontSize: 1,
        studentId: getURLParameters(this.props).id,
        setCurrentResponseCount: (currentResponseCount) => this.setState({currentResponseCount: currentResponseCount}),
        setErrors: (errors) => this.setState({errors: errors}),
        setTimer: (timer) => this.setState({timer: timer}),
        setPopup: (popup) => this.setState({popup: popup}),
        setFontSize: (fontSize) => this.setState({fontSize: fontSize})
    }
    studentId = getURLParameters(this.props).id

    getNextPhonicsScreen() {
        if(this.props.match.params.probeNumber <= 3) {
            return "phonics/phonics"
        }
        return "phrases"
    }
    /****************
    * React Functions
    *****************/
    componentDidMount() {
        this.setState({showWordModal:mathSubjects.includes(this.props.type)})
        if (!this.studentId) {
            return redirect.send('/dashboard', this.props, () => {
                message.error("There has been an error finding your student. Please try again.")
            })
        }
    }
    render() {
        const suggestedStory = new URLSearchParams(this.props.location.search).get("suggestedStory") ?? "high"
        return (<ProbeContext.Provider value={this.state}>
            <Route exact path="/probes/phonics/alphabet/:probeNumber">
                <Probe type={this.props.type} probeNumber={this.props.match.params.probeNumber} studentId={this.studentId} 
                    previousScreen={`phonics/alphabet`} nextScreen={`phonics/alphabet`} inner={Alphabet}
                />
            </Route>
            <Route exact path="/probes/phonics/phonics/:probeNumber">
                <Probe type={this.props.type} probeNumber={this.props.match.params.probeNumber} studentId={this.studentId} 
                    previousScreen={`phonics/phonics`} nextScreen={`${this.getNextPhonicsScreen()}`} inner={Phonics}
                />
            </Route>
            <Route exact path="/probes/phrases/:probeNumber">
                <Probe type={this.props.type} probeNumber={this.props.match.params.probeNumber} studentId={this.studentId} inner={Phrases}
                    previousScreen={`phrases`} nextScreen={`stories/${suggestedStory}`}
                />
            </Route>
            <Route exact path="/probes/stories/:educationLevel/:probeNumber">
                <Probe type={this.props.type} 
                    probeNumber={this.props.match.params.probeNumber} 
                    studentId={this.studentId} 
                    previousScreen={`stories/${this.props.type}`} 
                    nextScreen={`stories/${this.props.type}/${this.props.match.params.probeNumber}/success`}
                    inner={Stories}
                    previewQuestions={()=>this.setState({previewQuestions: true})}
                >
                    <Modal show={this.state.previewQuestions} onHide={()=>this.setState({previewQuestions: false})} size='lg'>
                        <Modal.Header closeButton>
                            Questions Preview
                        </Modal.Header>
                        <Modal.Body>
                            <StoriesDOK {...this.props} showButtons={false}/>
                        </Modal.Body>
                    </Modal>
                </Probe>
            </Route>
            {mathSubjects.map((subject,i) => (
                <Route exact path={`/probes/${subject}/:probeNumber`} key={subject}>
                    <Probe type={this.props.type} probeNumber={this.props.match.params.probeNumber} inner={MathProbe} studentId={this.studentId} 
                        previousScreen={`${subject}`} nextScreen={`${i === (mathSubjects.length-1) ? mathSubjects[i] : mathSubjects[i+1]}`}
                    >
                        <Modal id='probe-modal' onHide={() => this.setState({ showWordModal: false })} show={this.state.showWordModal} centered={false} dialogClassName={'vw-100 bottom m-0'}>
                            <Modal.Body>
                                <Row className={'text-center px-2 px-sm-5'}>
                                    <Col md={6} className={'move-forward px-3'}>
                                        <h2 className={'h4 mt-4'}>Word Problem</h2>
                                        <p className={'py-3'}>
                                            Before beginning the math exercises, have your student think of a word problem related to the exercise focus! 
                                        </p>
                                        <hr/>
                                        <p className={'py-3'}>
                                            When your student has successfully demonstrated creating and solving their word problem, click the continue button below to move on to the exercise.  
                                        </p>
                                        <Button onClick={()=>this.setState({showWordModal:false})} variant={'primary'} className="round px-5 mb-3" >Continue</Button>
                                    </Col>
                                    <Col md={6} className={'d-flex'}>
                                        <Form className='blue-box d-flex align-self-center my-5 mx-3 px-2 px-lg-4 py-4 flex-column'>
                                            <p className='px-3'>Make sure your student’s word problem accomplishes the following:</p>
                                            <Col className='text-start px-sm-4'>
                                                <Form.Check 
                                                    id="math-language"
                                                    type='checkbox' 
                                                    label='Utilizes mathematical language (examples: more, add, multiply, subtract, equal, etc.).'
                                                    className='mt-2'
                                                />
                                                <Form.Check 
                                                    id="solve-math"
                                                    type='checkbox' 
                                                    label="Solve the math problem they’ve created."
                                                    className='my-3'
                                                />
                                                <Form.Check 
                                                    id="check-answer"
                                                    type='checkbox' 
                                                    label="Check their answer."
                                                    className='my-3'
                                                />
                                            </Col>
                                        </Form>
                                    </Col>
                                    <Image fluid src={blobRight} className={'blob d-none d-md-block h-100 bottom-right me-n3'} />
                                </Row>
                            </Modal.Body>
                        </Modal>
                    </Probe>
                </Route>
            ))}
        </ProbeContext.Provider>)
    }
}

export default Probes
