import React from "react"

export namespace Header {
    export function Extend({ readingLicenses, mathLicenses, exp }: { readingLicenses: number, mathLicenses: number, exp: string }) {
        let licenseText: string
        if (readingLicenses > 0 && mathLicenses > 0) {
            licenseText = `${readingLicenses} reading licenses and ${mathLicenses} math licenses`
        } else if (readingLicenses > 0) {
            licenseText = `${readingLicenses} reading licenses`
        } else if (mathLicenses > 0) {
            licenseText = `${mathLicenses} math licenses`
        } else {
            licenseText = "no licenses"
        }
        return (<>
            <h1 className="text-center">Extend Subscriptions</h1>
            <p>To extend this subscription with {licenseText} that expires on {exp} by one year, make the appropriate selection below. </p>
            <p>If you want to change the license count(s) of this extended subscription, please edit the value(s) in the License fields. Please note that this new student count will not change until after this selected subscription expires.</p>
        </>)
    }

    export function Add() {
        return (<>
            <h1 className='text-center'>Add Licenses</h1>
            <p>Select the number of students you would like to add below.</p>
        </>)
    }

    export function Default() {
        return(<>
            <h1 className={'h3 text-center bold mb-3'}>Subscribe to <br/>Great Leaps Digital</h1>
            <p className={'px-3 px-sm-1 my-4'}>
                <div>Great Leaps Digital is a one-on-one program.</div>
                <div>A caring instructor is essential for student success.</div>
            </p>
        </>)
    }
}
