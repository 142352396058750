import React from "react"
import * as studentsModel from '../../../shared/students'

export const Context = React.createContext<{
    students: studentsModel.Student[]
    selectedStudent: studentsModel.Student
    selectedStudents: studentsModel.Student[]
    refreshStudents: () => any
    hideModals: () => any
}>(undefined)

export function useRoster() {
    const roster = React.useContext(Context)
    return roster
}
