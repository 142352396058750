import React from 'react';
import { Link } from 'react-router-dom';

import message from '../../../components/message/message';
import getURLParameters from '../../../helpers/getURLParams';

class CreateDiscountCodeSuccess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            code: "",
            amount: "",
            type: "",
            "minimum-students": "",
            "maximum-students": "",
            expiration: "",

            valid: true
        }
    }

    componentDidMount() {
        let params = getURLParameters(this.props);

        if (!params.code || !params.amount || !params.type || !params['maximum-students'] || !params['minimum-students']) {
            this.setState({ valid: false });
            message.error("You seem to have navigated to this page accidentally. If you're trying to access this page, please proceed back to the administration page using the button below..")
            return;
        }

        this.setState({
            code: params.code,
            amount: params.amount,
            type: params.type,
            'minimum-students': params['minimum-students'],
            'maximum-students': params['maximum-students'],
            expiration: params.expiration
        })
    }

    render() {

        if (!this.state.valid) {
            return (
                <React.Fragment>
                    <div className="redirect-container">
                        <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                            <Link to="/admin" className="btn btn-secondary btn-lg">Return to Administration Tools</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="alert alert-info">
                    <h4>Details:</h4>

                    <p><label>Code:</label> {this.state.code}</p>
                    <p><label>Discount:</label> {(this.state.type === 'fixed') ? '$' : ''}{this.state.amount}{(this.state.type === 'percentage') ? '%' : ''}</p>
                    <p><label>Validity:</label> {this.state["minimum-students"] + " to " + this.state["maximum-students"] + " students"}</p>
                    <p><label>Expiration:</label> {this.state.expiration}</p>
                </div>

                <div className="row">
                    <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                        <Link to="/admin/discount-code" className="btn btn-secondary btn-lg">Return to Code Creation</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default CreateDiscountCodeSuccess;
