import { modelRequest } from "@stem-sims/great-leaps-react-common"

export interface AccountInfoProps {
    name: string
    username: string
    email: string
    streetAddress: string
    city: string
    country: string
    state: string
    postalCode: string
    phoneNumber: string
    faxNumber: string
    verifiedEmail: boolean
    isSamlUser: boolean
    privileges: string[]
    valid: boolean
}

/**
 * Gets account information for the user
 */
export function get(): Promise<AccountInfoProps> {
    return modelRequest.get("/api/account/info")
}
