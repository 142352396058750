import customRequest from './customRequest';

export interface PriceData {
    discountCodeMessage?: {
        message?: string
        messageType?: "clear" | "error" | "success" | "warning"
    },
    prices?: Record<"math" | "reading" | "training", number>
    subTotals: {
        math: number,
        reading: number,
    }
    price?: number
}

interface PriceResponse {
    prices: Record<string, number>
    subTotals: {
        math: number
        reading: number
    }
    discount?: {
        success: false
        message: string
    } | {
        success: true
        message: string
        type: "Fixed" | "Percentage"
        amount: number
    }
    total: number
}

async function getPriceData({ reading=1, math=1, discountCode="" }) {
    let response: PriceData = {
        discountCodeMessage: {},
        subTotals: {
            math: 0,
            reading: 0
        }
    };

    let products = (await customRequest.get('/api/products', { params: { reading, math, discountCode } })).data as PriceResponse

    response.prices = {
        'reading': products.prices?.["Reading Subscription"],
        'math': products.prices?.["Math Subscription"],
        'training': products.prices?.["Training"],
    }
    response.price = products.total
    response.subTotals = products.subTotals
    if (!discountCode) {
        response.discountCodeMessage.messageType = "clear"
    } else if (!products.discount.success) {
        response.discountCodeMessage.messageType = "error"
    } else {
        response.discountCodeMessage.messageType = "success"
    }
    response.discountCodeMessage.message = products.discount?.message ?? ""
    return response
}

export default getPriceData;
