import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'

function LoggedOutLinks() {
    return (<>
        <Row>
            <div className="col-sm-8 offset-sm-2">
                <Link to="/account/log-in/teacher" className="btn btn-primary btn-lg">Log In to Purchase</Link>
            </div>
        </Row>

        <Row>
            <p className="col-12 text-center font-light mb-0">OR</p>
        </Row>

        <Row>
            <div className="col-sm-4 offset-sm-4">
                <Link to="/account/sign-up" className="btn btn-primary btn-lg">Sign Up</Link>
            </div>
        </Row>
    </>)
}

export default LoggedOutLinks;
