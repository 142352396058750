import React from 'react'
import customRequest from '../../../helpers/customRequest'
import initializeLocations, { getStates } from '../../../helpers/forms/location-form'
import { initializeFormValidation } from '../../../helpers/forms/form-validation'
import message from '../../../components/message/message'
import ClipLoader from 'react-spinners/ClipLoader'

class Information extends React.Component
{
    state = {
        countriesOptions: [],
        loading: true,
        submitting: false
    }
    
    componentDidMount() {
        customRequest.get("/api/countries")
		.then(({data: countriesData}) => {
			this.setState({
				countriesOptions: countriesData.countries
            }, () => {
                let data = this.props.info
                if (!document.getElementById("information-form") || !data)
                {
                    return
                }
                document.getElementById("name").value = data.name
                document.getElementById("email-address").value = data.email
                document.getElementById("address").value = data.streetAddress
                document.getElementById("city").value = data.city
                document.getElementById("country").value = data.country
                document.getElementById("state-autofill").value = data.state
                getStates(document.getElementById("country"))
                
                document.getElementById("postal-code").value = data.postalCode
                document.getElementById("phone").value = data.phoneNumber
                document.getElementById("fax").value = data.faxNumber
                this.setState({loading: false})
            })
        })

        initializeLocations();
		initializeFormValidation(this.onSubmit);
    }

    onSubmit = () => {
        this.setState({loading: true, submitting: true}, () => {
            customRequest.get('/api/account/info')
            .then((infoResponse) =>
            {
                if (!document.getElementById("information-form"))
                {
                    return
                }

                message.clear()
                var {name, email, streetAddress, city, country, state, postalCode, phoneNumber, faxNumber} = infoResponse.data
                var newName = document.getElementById("name").value
                var newEmail = document.getElementById("email-address").value
                var newStreet = document.getElementById("address").value
                var newCity = document.getElementById("city").value
                var newCountry = document.getElementById("country").value
                var newState = document.getElementById("state").value
                var newPostalCode = document.getElementById("postal-code").value
                var newPhoneNumber = document.getElementById("phone").value
                var newFaxNumber = document.getElementById("fax").value

                if (name === newName && newEmail === email && newStreet === streetAddress && newCity === city && newCountry === country && newState === state && newPostalCode === postalCode && newPhoneNumber === phoneNumber && newFaxNumber === faxNumber)
                {
                    this.setState({loading: false, submitting: false})
                    message.error("You did not change any of your account information.")
                    return
                }

                let emailChanged = newEmail !== this.props.info.email

                customRequest.post('/api/account/edit', {
                    name: newName,
                    email: newEmail,
                    streetAddress: newStreet,
                    city: newCity,
                    country: newCountry,
                    state: newState,
                    postalCode: newPostalCode,
                    phoneNumber: newPhoneNumber,
                    faxNumber: newFaxNumber,
                    currentPassword: document.getElementById("current-password").value
                })
                .then(({data}) => {
                    this.setState({loading: false, submitting: false})
                    if (data.valid) {
                        message.success("Your account information has been successfully updated.")
                        document.getElementById("current-password").value = ""
                        this.props.updateInfo()

                        if (emailChanged) {
                            message.info("Please check your updated email address for a verification link. Until you verify your new address, all messages about your account will come to your old email address.")
                        }
                    }
                    else {
                        message.error(data.message)
                    }
                })
            })
        })
    }

    render() {
        return <form className="form-horizontal form-validate" id="information-form" noValidate method = "post">
        
        <div className="mb-3 row">
            <label htmlFor = "name" className="d-none d-sm-inline col-sm-3 control-label text-end">Name</label>
            
            <div className="col-sm-6">
                <input type = "text" disabled={this.state.loading} className="form-control" id = "name" name = "name" placeholder = "Name" required data-description = "enter a name" autoFocus />
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "email-address" className="d-none d-sm-inline col-sm-3 control-label text-end">Email&nbsp;Address</label>
            
            <div className="col-sm-6">
                <input type = "email" disabled={this.state.loading} className="form-control unique" id = "email-address" name = "email-address" placeholder = "Email Address" autoComplete = "off" required data-description = "enter an email address" />
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "address" className="d-none d-sm-inline col-sm-3 control-label text-end">Street Address</label>
            
            <div className="col-sm-6">
                <div className="input-group">
                    <input type = "text" disabled={this.state.loading} className="form-control string" name = "address" id = "address" placeholder = "Street Address" maxLength = "175" data-type = "address" data-description = "enter a street address" />
                    <span className="input-group-text">optional</span>
                </div>
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "city" className="d-none d-sm-inline col-sm-3 control-label text-end">City</label>
            
            <div className="col-sm-6">
                <input type = "text" disabled={this.state.loading} className="form-control string" name = "city" id = "city" placeholder = "City" maxLength = "175" data-type = "city" data-description = "enter a city" required />
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "country" className="d-none d-sm-inline col-sm-3 control-label text-end">Country</label>
            
            <div className="col-sm-6">
                <select className="form-control" disabled={this.state.loading} defaultValue={""} name="country" id="country" required data-description = "select a country">
                    <option value="" disabled>Country:</option>
                    {this.state.countriesOptions.map((item,i) => {
                        return (<option value={item} key={i}>{item}</option>)
                    })}
                </select>
            </div>
        </div>
        
        <div className="state-autofill-target">
            <input type = "text" name = "state-autofill" id = "state-autofill" />
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "state" className="d-none d-sm-inline col-sm-3 control-label text-end">State or Province</label>
            
            <div className="col-sm-6">
                <select className="form-control" disabled={this.state.loading} name = "state" id = "state" required data-description = "select a state or province">
                </select>
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "postal-code" className="d-none d-sm-inline col-sm-3 control-label text-end">Postal Code</label>
            
            <div className="col-sm-6">
                <input type = "text" disabled={this.state.loading} className="form-control string" name = "postal-code" id = "postal-code" placeholder = "Postal Code" maxLength = "175" data-type = "postal code" data-description = "enter a postal code" required/>
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "phone" className="d-none d-sm-inline col-sm-3 control-label text-end">Phone Number</label>
            
            <div className="col-sm-6">
                <input type = "text" disabled={this.state.loading} className="form-control string" name = "phone" id = "phone" placeholder = "Phone Number" maxLength = "175" data-type = "phone number" data-description = "enter a phone number" required />
            </div>
        </div>
        
        <div className="mb-3 row">
            <label htmlFor = "fax" className="d-none d-sm-inline col-sm-3 control-label text-end">Fax Number</label>
            
            <div className="col-sm-6">
                <div className="input-group">
                    <input type = "text" disabled={this.state.loading} className="form-control string" name = "fax" id = "fax" placeholder = "Fax Number" maxLength = "175" data-type = "fax number" data-description = "enter a fax number" />
            
                    <span className="input-group-text">optional</span>
                </div>
            </div>
        </div>
        
        
        <div className="mb-3 row">
            <label htmlFor = "current-password" className="d-none d-sm-inline col-sm-3 control-label text-end">Current Password</label>
            
            <div className="col-sm-6">
                <input type = "password" disabled={this.state.loading} className="form-control string" id = "current-password" name = "current-password" placeholder = "Current Password" required data-type = "current password" minLength = "5" data-description = "enter your current password" />
            </div>
        </div>
        
        <button type = "submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">{this.state.submitting ?
                    <ClipLoader
							size={20}
							color={"#123abc"}
							loading={this.state.submitting}
							/> : "Change Information"}</button>
    </form>
    }
}

export default Information
