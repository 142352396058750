import React from 'react';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeLocations from '../../../helpers/forms/location-form';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';
import customRequest from '../../../helpers/customRequest';
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import ClipLoader from "react-spinners/ClipLoader";

class CreateAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            username: "",
            "email-address": "",
            referral: "",
            entity: "",
            role: "",
            city: "",
            country: "",
            state: "",
            "postal-code": "",
            phone: "",

            loading: false,
            postalCodeRequestTimeout: null,
            countriesOptions: []
        }
    }

    componentDidMount() {

        customRequest.get("/api/countries").then(({ data }) => {
                if (!data.countries) {
                    const errorMessage = 'Cannot connect to API'
                    throw errorMessage
                }
                this.setState({
                    countriesOptions: data.countries
                }, () => {
                    // initialize scripts for form
                    initializeLocations();
                    initializeFormValidation();

                    initializeAutosubmit();
                    initializeTextAreaSubmit();
                    initializeSubmissionPrevention();
                });
            }).catch((error) => {
                redirect.send("/admin")
                message.error("Cannot connect to the server. Please refresh the page and try again.")
            })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value }, () => {
            if (target.name === "postal-code") {
                clearTimeout(this.state.postalCodeRequestTimeout);
                this.setState({
                    postalCodeRequestTimeout: setTimeout(this.setLocationInfo, 1000)
                });
            }
        });
    }

    setLocationInfo = async () => {
        let zipcodeResponse = await customRequest.get("/api/zipcode?zipcode=" + this.state["postal-code"])
        let locationDataResponse = zipcodeResponse.data

        if (locationDataResponse.valid === false) {
            return
        }

        document.getElementById("state-autofill").value = locationDataResponse.state

        this.setState({
            city: locationDataResponse.city,
        }, () => {
            let event = new Event('blur', { bubbles: true });
            document.getElementById("city").dispatchEvent(event);
        });

        this.setState({
            country: locationDataResponse.country
        }, () => {
            let event = new Event('blur', { bubbles: true });
            document.getElementById("country").dispatchEvent(event);
        });

        document.getElementById("country").addEventListener("blur", (event) => {
            event.target.removeEventListener(event.type, this);
            this.setState({
                state: locationDataResponse.state
            });
        });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var signUpForm = document.getElementById("create-account-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(signUpForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            customRequest.post("" /* todo */, {
                name: this.state.name,
                username: this.state.username,
                emailAddress: this.state['email-address'],
                referral: this.state.referral,
                entity: this.state.entity,
                city: this.state.city,
                country: this.state.country,
                state: this.state.state,
                postalCode: this.state['postal-code'],
                phoneNumber: this.state.phone,
                role: this.state.role
            })
                .then(({ data }) => {
                    if (!data.valid) {
                        this.setState({ loading: false })
                        return message.error(data.message)
                    }

                    redirect.send("/admin/create-account/success?username=" + this.state.username)
                    message.success("The account with username '" + this.state.username + "' has been successfully created.")
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    console.error(error)
                    return message.error("There has been an internal error.")
                })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1>Create an Account for a User</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>This form will allow you to create an account for a user, with a link to reset their password emailed to them.</p>

                        <p>Please note that the user will only receive the standard 3-day trial; any other subscriptions must be activated separately.</p>
                    </div>
                </div>

                <form id="create-account-form" className="form-horizontal form-validate" noValidate method="post" onSubmit={this.submitForm}>
                    <div className="mb-3 row">
                        <label for="name" className="hidden-xs col-sm-3 control-label text-end">Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" disabled={this.state.loading} id="name" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Full Name" required data-description="enter a name" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="username" className="hidden-xs col-sm-3 control-label text-end">Username</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control unique" disabled={this.state.loading} id="username" name="username" value={this.state.username} onChange={this.handleInputChange} placeholder="Username" required data-description="enter a username" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="email-address" className="hidden-xs col-sm-3 control-label text-end">Email&nbsp;Address</label>

                        <div className="col-sm-6">
                            <input type="email" className="form-control unique" disabled={this.state.loading} id="email-address" name="email-address" value={this.state["email-address"]} onChange={this.handleInputChange} placeholder="Email Address" required data-description="enter an email address" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="referral" className="hidden-xs col-sm-3 control-label text-end">How did you hear about us?</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <select className="form-control" disabled={this.state.loading} name="referral" id="referral" value={this.state.referral} onChange={this.handleInputChange} data-description="select a referral">
                                    <option value="" disabled selected>Referral:</option>
                                    {
                                        ["Web", "Conference", "Social Media", "School", "SEEN Magazine/Email", "Other"].map((source) => {
                                            return <option value={source}>{source}</option>
                                        })
                                    }
                                </select>
                                <span className="input-group-text">optional</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="entity" className="hidden-xs col-sm-3 control-label text-end">School/Company/Entity</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control string" disabled={this.state.loading} name="entity" id="entity" value={this.state.entity} onChange={this.handleInputChange} placeholder="School/Company/Entity" maxlength="175" data-type="entity" data-description="enter a School/Company/Entity" />

                                <span className="input-group-text">optional</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="role" className="hidden-xs col-sm-3 control-label text-end">Role</label>

                        <div className="col-sm-6">
                            <select className="form-control" disabled={this.state.loading} name="role" id="role" required value={this.state.role} onChange={this.handleInputChange} data-description="select a role">
                                <option value="" disabled selected>Role:</option>
                                {
                                    ["Parent", "Tutor", "Teacher", "Administrator", "Other"].map((role) => {
                                        return <option value={role}>{role}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="postal-code" className="hidden-xs col-sm-3 control-label text-end">Postal Code</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} name="postal-code" id="postal-code" value={this.state["postal-code"]} onChange={this.handleInputChange} required placeholder="Postal Code" maxlength="175" data-type="postal code" data-description="enter a postal code" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="city" className="hidden-xs col-sm-3 control-label text-end">City</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} name="city" id="city" value={this.state.city} onChange={this.handleInputChange} placeholder="City" maxlength="175" data-type="city" data-description="enter a city" required />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="country" className="hidden-xs col-sm-3 control-label text-end">Country</label>

                        <div className="col-sm-6">
                            <select className="form-control" disabled={this.state.loading} name="country" id="country" onChange={this.handleInputChange} value={this.state.country} required data-description="select a country">
                                <option value="" disabled selected>Country:</option>
                                {this.state.countriesOptions.map((item) => {
                                    return (<option value={item}>{item}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="state-autofill-target">
                        <input type="text" name="state-autofill" id="state-autofill" />
                    </div>

                    <div className="mb-3 row">
                        <label for="state" className="hidden-xs col-sm-3 control-label text-end">State or Province</label>

                        <div className="col-sm-6">
                            <select className="form-control" disabled={this.state.loading} name="state" id="state" onChange={this.handleInputChange} value={this.state.state} required data-description="select a state or province">
                            </select>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="phone" className="hidden-xs col-sm-3 control-label text-end">Phone Number</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} name="phone" id="phone" value={this.state.phone} onChange={this.handleInputChange} placeholder="Phone Number" maxlength="175" data-type="phone number" data-description="enter a phone number" required />
                        </div>
                    </div>

                    <button type="submit" id="sign-up-button" className="btn btn-secondary btn-lg" disabled={this.state.loading}>
                        {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Create Account"}
                    </button>
                </form>
            </React.Fragment>
        )
    }

}

export default CreateAccount;
