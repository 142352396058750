import React from 'react'
import { StudentDashboard  } from '@stem-sims/great-leaps-react-common'

class Dashboard extends React.Component
{
    render()
    {
        return (
            <div className="pt-4">
                <StudentDashboard />
            </div>
        )
    }
}

export default Dashboard
