import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { Error } from './types/error'

/**
 * @abstract Gets an orgs' groups
 */
export function get(orgId = "") : Promise<any[]> {
    return modelRequest.get(`/api/organizations/groups/${orgId}`)
}
/**
 * Creates a group
 * 
 * @param groupName name of group
 */
export function post(groupName: string) : Promise<Error> {
    return modelRequest.post('/api/organizations/groups', {
        name: groupName
    })
}

/**
 * Deletes a group
 * 
 * @param groupName 
 */
export function remove(groupName: string) : Promise<Error> {
    return modelRequest.delete('/api/organizations/groups', { data: {
        name: groupName
    }})
}

/**
 * Edit a group's name
 * 
 * @param previousName 
 * @param groupName 
 */
export function edit(previousName: string, groupName: string, studentLimit: number) : Promise<Error> {
    return modelRequest.put('/api/organizations/groups', {
        name: groupName,
        previousName: previousName,
        studentLimit: studentLimit
    })
}
