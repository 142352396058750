import React from 'react';
import { Link } from 'react-router-dom';

import message from '../../../components/message/message';
import getURLParameters from '../../../helpers/getURLParams';
import redirect from '../../../components/redirect/redirect';

class GenerateSalesCodeSuccess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            created: "",
            code: "",

            valid: true
        }
    }

    componentDidMount() {
        let params = getURLParameters(this.props);

        if (!params.name || !params.created || !params.code) {
            this.setState({ valid: false });
            message.error("You seem to have navigated to this page accidentally. If you're trying to access this page, please proceed back to the administration page using the button below..")
            return;
        }

        this.setState({
            name: params.name,
            created: params.created,
            code: params.code
        })
    }

    sendToEmailSalesLink = () => {
        redirect.send("/admin/sales-code/send-email?name=" + this.state.name + "&code=" + this.state.code)
    }

    render() {

        if (!this.state.valid) {
            return (
                <React.Fragment>
                    <div className="redirect-container">
                        <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                            <Link to="/admin" className="btn btn-secondary btn-lg">Return to Administration Tools</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="alert alert-info">
                    <h4>Details:</h4>

                    <p><label>Price:</label> {this.state.price}</p>
                </div>

                <div className="alert alert-info">
                    <h4>Code:</h4>

                    <ul className="list-unstyled">
                        <li>{this.state.code}</li>
                    </ul>
                </div>

                <div className="alert alert-info">
                    <p>If you want to email this code, you can click the button below to have the information from this page pre-filled.</p>

                    <button className="btn btn-secondary btn-lg" onClick={this.sendToEmailSalesLink}>Email Sales Links</button>
                </div>

                <div className="row">
                    <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                        <button className="btn btn-secondary btn-lg" onClick={this.props.history.goBack}>Return to Code Creation</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default GenerateSalesCodeSuccess;
