import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SocialMedia from './SocialMedia'
const moment = require('moment')

function Footer({auth}: {auth: string[]})
{
    const location = useLocation()
    const inProbe = location.pathname.match(new RegExp("^/probes/(phrases|phonics/alphabet|phonics/phonics|stories/elementary|stories/middle|stories/high|addition|subtraction|multiplication|division)/[0-9]+$"))
    return (
    <div className='text-center'>
        <hr />
        {!auth?.includes("student") && !inProbe ?
            <SocialMedia />
        : ""}

        <p>© {moment().format("YYYY")} Diarmuid, Inc. <Link to="/legal/terms-of-use">Terms of Use</Link> - <Link to="/legal/privacy-policy">Privacy Policy</Link></p>
    </div>)
}

export default Footer
