import React from 'react'
import * as organization from "../../../models/organizations"
import Form from '../../../components/forms/Form'
import Input from '../../../components/forms/Input'
import SubmitButton from '../../../components/forms/SubmitButton'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'

class Create extends React.Component<{ updatePermissions: () => Promise<void> }> {

    submit = (values) => {
        return organization.post(values.Name.value)
            .then((response) => {
                if (!response.valid) {
                    message.error(response.message)
                }
                else {
                    this.props.updatePermissions()
                        .then(() => {
                            redirect.send('/organization/invite', this.props)
                        })
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                <h1>Create An Organization</h1>
                <Form submit={this.submit}>
                    {submitting => {
                        return (
                            <React.Fragment>
                                <Input name="Name" longName="Organization Name" placeholder="Name of Organization" submitting={submitting} />
                                <SubmitButton submitting={submitting}>Create</SubmitButton>
                            </React.Fragment>
                        )
                    }}
                </Form>
            </React.Fragment>
        )
    }

}

export default Create
