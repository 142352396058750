import React from 'react'

export default class Input extends React.Component<{
    name: string,
    longName: string,
    submitting: boolean,
    options: string[],
    className?: string,
    onChange?: (value: string) => void
    placeholder?: string,
    ref?: any,
    defaultValue?: string
    type?: string}> {
    render() {
        let { name, submitting, longName, ref, defaultValue, options, className } = this.props
        return (
            <div className = {`mb-3 row ${className}`}>
                <label htmlFor = "username" className = "d-none d-sm-inline col-sm-3 control-label text-end">{name}</label>
                
                <div className = "col-sm-6">
                    <select
                        className="form-control"
                        disabled = {submitting}
                        id = {name}
                        name = {name}
                        data-description = {`enter an ${longName}`}
                        defaultValue={defaultValue}
                        ref={ref}
                        onChange={(event) =>{  
                            if (this.props.onChange) {
                                this.props.onChange(event.target.value)
                            }
                        } }
                        required
                        autoFocus>

                        {
                            options.map((value) => {
                                return <option value={value} selected={value === defaultValue}>
                                    {value}
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>
        )
    }
}
