import * as Yup from "yup"

export const passwordValidator = () => {
    return Yup.string()
            .required("Please enter a password.")
            .min(8, "Your password must be at 8 or more characters")
            .matches(/[a-z]/, "Your password must contain at least one lower letter")
            .matches(/[A-Z]/, "Your password must contain at least one uppercase letter")
            .matches(/[0-9]/, "Your password must contain at least one number")
}
export default passwordValidator
