import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Skeleton from 'react-loading-skeleton'
import ProbeListHelp from '../help/ProbeListHelp'
import buildQuery from '../../helpers/buildQuery'
import { ReadingSubjects, StoryLevels } from '../../models/reading'
import { MathSubjects } from '../../models/math'

interface HeaderParameters {
    studentCode: string
    studentID: string
    studentName: string
    active: ReadingSubjects | MathSubjects | "stories"
    story: StoryLevels
}

export default function Header({
    studentCode,
    studentID,
    studentName,
    active,
    story = null,
}: HeaderParameters)
{
    const standardVariables = buildQuery({ id: studentID })
    return (
        <>
            <h1 className = "break-words text-center pt-3">
                Start an Exercise with {studentName ?? <Skeleton width={150} />}
                <ProbeListHelp />
            </h1>
            <Row className="my-4">
                <Col>
                    <div className = "subheader-text h2 text-start">
                        Student Code: <span className = "readable">
                            {studentCode ?? <Skeleton width={100} />}
                        </span>
                    </div>
                </Col>
                <Col className='text-end'>
                    <Link
                        to = {`/students/records/${active === "phonics" || active === "alphabet" ? "phonics/" : ""}${active}${story ? "/" + story : ""}?${standardVariables}`}
                        target = "_blank"
                        className = {`btn btn-secondary btn-lg px-5 mb-4`}
                        aria-disabled={!studentID}
                        style={{
                            width: 'fit-content',
                            // Don't allow link to be clicked until studentID is loaded
                            pointerEvents: !studentID ? "none": "auto"
                        }}
                    >
                        <span className="hidden-xxs d-none d-sm-inline">View</span> Records
                    </Link>
                </Col>
            </Row>
        </>
    )
}
