


import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'

export default function GradingKey({gradingKey,setGradingKey}) {

    return(<>
        <Modal show={gradingKey} onHide={() => setGradingKey(false)}>
            <Modal.Header className='h5' closeButton>Grading Key</Modal.Header>
            <Card id="probe" className='shadow m-4 py-3 pe-1'>
                <div className='medium-small text-start ps-3'>
                    <p>
                        <span className='d-inline-block bg-danger key-box me-1'/>
                        Single Left Click - Mark/clear incorrect
                    </p>
                    <p>
                        <span className='d-inline-block bg-light-red key-box me-1'/>
                        Marked incorrect in previous session
                    </p>
                    <p>
                        <span className='d-inline-block bg-primary key-box me-1'/>
                        Double Left Click - Mark last word read by student
                    </p>
                </div>
            </Card>
        </Modal>
    </>)
}
