import Message from './messages/message';
import SuccessMessage from './messages/success-message';
import ErrorMessage from './messages/error-message';
import PreviousMessage from './messages/previous-message';
import RollAnimation from '../roll-animation';
import Request from '../request';
import WarningMessage from './messages/warning-message';

function Input(input)
{
	this.input = input;
	
	this.unique = (input.classList.contains("unique") === true);
	this.state = "";
	
	this.value = input.value;
	this.getAttribute = input.getAttribute.bind(input);
	this.hasAttribute = input.hasAttribute.bind(input);
	this.parentNode = input.parentNode;
	
	this.validators = [];
}

Input.success = "is-valid";
Input.warning = "has-warning";
Input.error = "is-invalid";

Input.inputElements = [];
Input.inputs = [];

Input.getInput = function(input)
{
	var index = Input.inputElements.indexOf(input);
	
	if (index === -1)
	{
		Input.inputElements.push(input);
		
		input = new Input(input);
		Input.inputs.push(input);
		
		return input;
	}
	
	else
	{
		return Input.inputs[index];
	}
}

Input.prototype.addValidator = function(validator)
{
	this.validators.push(validator);
	
	if (this.validators.length === 1)
	{
		this.input.addEventListener("blur", this.validate.bind(this));
	}
};

Input.prototype.validate = function(event)
{
	this.value = this.input.value;
	
	
	var autofocus = this.input.getAttribute("autofocus");
	if (autofocus === "")
	{
		this.input.removeAttribute("autofocus");
		
		
		if (event.submission !== true && String(this.value) === "")
		{
			return;
		}
	}
	
	
	var index = 0;
	
	var validators = this.validators;
	var length = validators.length;
	
	var response = new SuccessMessage();
	while (index < length && response instanceof SuccessMessage)
	{
		response = validators[index](this);
		
		index++;
	}
	
	
	if (event != null && event.showFeedback === false && response instanceof ErrorMessage)
	{
		event.preventDefault();
		return;
	}
	
	
	if (response instanceof Message)
	{
		if (!(response instanceof PreviousMessage))
		{
			this.changeState(response.state);
			
			if (response.text.length === 0)
			{
				this.removeFeedback();
			}
			
			else
			{
				var submission = false;
				if (event.submission === true)
				{
					submission = true;
				}

				this.addFeedback(response, submission);
			}
		}
		
		var inputGroup = this.getInputGroup();
		if (response instanceof ErrorMessage || (response instanceof PreviousMessage && inputGroup.classList.contains(Input.error)))
		{
			if (event != null)
			{
				event.preventDefault();
			}
		}
	}
	
	else if (response instanceof Request)
	{
		if (event === null || event.submission !== true)
		{
			response.addEventListener("readystatechange", this.validateAjax.bind(this));
			response.send();
		}
	}
};

Input.prototype.validateAjax = function(event)
{
	if (event.readyState === XMLHttpRequest.DONE && event.status === 200)
	{
		var message = event.validator(event.responseText, this);
		
		this.changeState(message.state);
		
		if (message.text.length === 0)
		{
			this.removeFeedback();
		}
		
		else
		{
			this.addFeedback(message);
		}
	}
};

/**
 * Updates the state of the input
 * 
 * @param {Input.success | Input.success} newState
*/
Input.prototype.changeState = function(newState)
{
	var state = this.state;
	if (state !== "")
	{
		this.input.classList.remove(state);
	}
	
	if (newState !== "")
	{
		this.input.classList.add(newState);
	}
	
	this.state = newState;
};
/**
 * 
 * @param {Message | ""} message 
 * @param {Boolean} submission 
 */
Input.prototype.addFeedback = function(message, submission)
{
	if (submission === undefined)
	{
		submission = false;
	}

	let messageText = ""
	if (message instanceof Message)
	{
		messageText = message.text
	}
	
	var inputGroup = this.getInputGroup();
	
	var formGroupStyle = window.getComputedStyle(inputGroup.parentNode);
	var formGroupMargin = parseFloat(formGroupStyle.marginBottom);
	
	
	var helpContainer = inputGroup.getElementsByClassName("help-container");
	helpContainer = helpContainer[0];
	
	var rollAnimation;
	if (!helpContainer)
	{
		rollAnimation = new RollAnimation();
		rollAnimation.heightBuffer = 5 - formGroupMargin;
		helpContainer = rollAnimation.node;
		
		inputGroup.appendChild(helpContainer);
		
		
		helpContainer.classList.add("help-container");
		helpContainer.style.transitionProperty = "height, margin-bottom";
	}
	
	else
	{
		rollAnimation = helpContainer.animationObject;
	}
	
	
	var helpBlock = document.createElement("p");
	helpBlock.innerHTML = messageText;
	helpBlock.className = "help-block";
	helpBlock.style.margin = "0px";
	helpBlock.style.paddingBottom = "10px";

	if (message instanceof ErrorMessage)
	{
		helpBlock.style.color = "#dc3545";
	}
	
	else if (message instanceof SuccessMessage)
	{
		helpBlock.style.color = "#28a745";
	}

	else if (message instanceof WarningMessage)
	{
		helpBlock.style.color = "#ffcc00";
	}
	
	var states = rollAnimation.states;
	var length = states.length;
	
	var nominalHeight = helpContainer.style.height;
	var computedHeight = window.getComputedStyle(helpContainer).height;
	if (!(submission === true && length === 1 && states[0].innerHTML === messageText && nominalHeight !== computedHeight))
	{
		rollAnimation.addState(helpBlock);
	}
	
	helpContainer.style.marginBottom = String(-formGroupMargin) + "px";
};

Input.prototype.removeFeedback = function()
{
	var inputGroup = this.getInputGroup();
	
	var helpContainer = inputGroup.getElementsByClassName("help-container");
	helpContainer = helpContainer[0];
	if (helpContainer != null)
	{
		this.addFeedback("");
		
		helpContainer.addEventListener("transitionend", window.removeAnimationTarget);
		helpContainer.style.height = "0px";
		helpContainer.style.marginBottom = "0px";
	}
};


Input.prototype.getInputGroup = function()
{
	var inputGroup = this.parentNode;
	if (inputGroup.classList.contains("input-group") === true || inputGroup.classList.contains("radio-inline") === true)
	{
		inputGroup = inputGroup.parentNode;
	}
	
	return inputGroup;
}

export default Input;
