import React, { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { ProbeContext } from '../ProbeContext'
import ModalTemplate from './ModalTemplate'

export default function AccessibilityModal() {
    const { fontSize, setFontSize } = useContext(ProbeContext)
    const [accessibilityModal, showAccessibilityModal] = useState(false)

    const adjustFont = (e) => {
        setFontSize(e.target.value)
    }
    return(<>
        <Button onClick={()=>showAccessibilityModal(true)} variant="secondary-outline" className='flex-nowrap my-2'>
            Font Size
        </Button>
        <ModalTemplate show={accessibilityModal} onHide={()=>showAccessibilityModal(false)} header={"Accessibility Settings"}>
            <Form.Label>Font Size</Form.Label>
            {/* Options 1-5 is given below for formatting purposes, but only 1-4 sizes can be selected */}
                <input onChange={adjustFont} value={fontSize} type="range" min="1" max="4" id="my-range" list="my-datalist"/>
                <datalist className="small" id="my-datalist" >
                    <option value={1}>Small</option>
                    <option value={2}></option>
                    <option value={3}>Medium</option>
                    <option value={4}></option>
                    <option value={5}>Large</option>
                </datalist>
            <Button onClick={()=>showAccessibilityModal(false)} variant="secondary" className="position-relative move-forward m-3">Exit</Button>
        </ModalTemplate>
    </>)
}
