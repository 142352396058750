import Input from './input';

function initializeLocations()
{	
	var countryList = document.getElementById("country");
	if (countryList !== null)
	{
		countryList.addEventListener("change", getStates);
		countryList.addEventListener("blur", getStates);
		
		var changeEvent = document.createEvent("Event");
		changeEvent.initEvent("change", true, true);
		countryList.dispatchEvent(changeEvent);
		
		var stateList = document.getElementById("state");
		stateList.addEventListener("change", setLocationByEvent);
	}
}

export function getStates(event, callback = () => {})
{
	var country
	if (event instanceof Event) {
		country = event.target.value;
	}
	else if (event instanceof HTMLElement) {
		country = event.value
	}
	else {
		const errorMessage = "event isn't formatted correctly"
		throw errorMessage
	}
	
	var stateList = document.getElementById("state");
	if (stateList !== null)
	{
		var currentState = stateList.value;
		if (currentState === "")
		{
			stateList.innerHTML = "";
			
			var defaultOption = new Option("State or Province:", "");
			defaultOption.disabled = true;
			defaultOption.selected = true;
			
			if (country === "" || country === "None")
			{
				stateList.options.add(defaultOption);
				
				var countryFirst = new Option("Please select a country first.", "");
				countryFirst.disabled = true;
				stateList.options.add(countryFirst);
				
				return;
			}
		}
		
		var request = new XMLHttpRequest();
		request.input = stateList;
		request.addEventListener("readystatechange", (event) => {
			callback()
			showStates(event)
		});
		request.open("GET", "/api/states?country=" + country, true);
		request.send();
	}
}

function showStates(event)
{
	var request = event.target;
	if (request.readyState === XMLHttpRequest.DONE && request.status === 200)
	{
		request.removeEventListener(event.type, showStates);
		
		
		var stateList = request.input;
		if (!stateList) {
			return 
		}
		var autofillState = document.getElementById("state-autofill");		
		var currentState = null;

		if (stateList && stateList.value !== "" && stateList.value !== null)
		{
			currentState = stateList?.value;
		}
		
		else if (autofillState && autofillState.value !== "" && autofillState.value !== null)
		{
			currentState = autofillState.value;
			autofillState.value = ""
		}
		stateList.innerHTML = "";
		
		
		var selectDefault = true;
		
		var states = JSON.parse(request.responseText);
		var statesLength = states.length;
		for (var index = 0; index < statesLength; index++)
		{
			var state = new Option(states[index]["State"], states[index]["State"]);
			if (states[index]["Short Code"] === currentState || states[index]["State"] === currentState)
			{
				state.selected = true;
				selectDefault = false;
			}
			
			stateList.options.add(state);
		}
		
		
		var defaultOption = new Option("State or Province:", "");
		defaultOption.disabled = true;
		defaultOption.selected = selectDefault;
		stateList.options.add(defaultOption, stateList.options[0]);
		
		
		var changeEvent = document.createEvent("Event");
		changeEvent.initEvent("change", true, true);
		changeEvent.submission = false;
		
		stateList = Input.getInput(stateList);
		stateList.validate(changeEvent);

		let stateInput = document.getElementById("state")

		if (stateInput) {
			event = new Event('change', { bubbles: true });
			stateInput.dispatchEvent(event);
		}
	}
}

function setCustomerLocation(customerLocation)
{
	var state = {"customerLocation": customerLocation};
	window.history.replaceState(state, "", window.location.href);
}


function setLocationByEvent(event)
{
	var stateList = event.target;
	var state = stateList.value;
	
	var countryList = document.getElementById("country");
	var country = countryList.value;
	
	var customerLocation =
	{
		"country":	country,
		"state":	state
	};
	
	setCustomerLocation(customerLocation);
}

export default initializeLocations;
