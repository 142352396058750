import React from 'react'
import { initializeFormValidation } from '../../../helpers/forms/form-validation'
import customRequest from '../../../helpers/customRequest'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'
import ClipLoader from "react-spinners/ClipLoader"

class Recover extends React.Component {
    state = {
        submitting: false
    }
    componentDidMount() {
        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({submitting: true}, () => {
            customRequest.post('/api/account/recover', {
                username: document.getElementById('username').value,
                emailAddress: document.getElementById('email-address').value
            })
            .then(({data: response}) => {
                this.setState({submitting: false})
                if (response.valid) {
                    return redirect.send("/", this.props, () => {
                        message.success("An email has been sent to the address associated with your account. Please follow the contained instructions to regain access to your account. If you do not receive this email within 15 minutes, please check your spam folder.")
                    })
                }
                else {
                    message.error(response.message)
                }

            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1 className='text-center pb-3'>Recover Your Account Information</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Enter either one of the following pieces of information to locate your account.</p>

                        <p>Once located, an email will be sent to the address associated with your account with your username and instructions to reset your password.</p>
                    </div>
                </div>

                <form className="form-horizontal form-validate text-center" novalidate method = "post">
                    <div className="mb-3 row">
                        <label for = "username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>
                        
                        <div className="col-sm-6">
                            <input type = "text" className="form-control" disabled={this.state.submitting} id = "username" name = "username" placeholder = "Username" autofocus data-required-alternate = "email-address" data-description = "enter a username" />
                        </div>
                    </div>
                    
                    <div className="mb-3 row">
                        <label for = "email-address" className="d-none d-sm-inline col-sm-3 control-label text-end">Email Address</label>
                        
                        <div className="col-sm-6">
                            <input type = "text" className="form-control" disabled={this.state.submitting} id = "email-address" name = "email-address" placeholder = "Email Address" data-required-alternate = "username" data-description = "enter an email address" />
                        </div>
                    </div>

                    <button type = "submit" className="btn btn-secondary btn-lg" disabled={this.state.submitting}>{this.state.submitting ?
                        <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.submitting}
                            /> : "Search"}</button>
                </form>
            </React.Fragment>
        )
    }
}

export default Recover
