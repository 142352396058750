import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'

function UseCases() {

    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css" href="/styles/about.css?version=8-28-2021" />

            <div className="about-title-image text-center">
                <h1 className="about-title-main h2 mt-5">Use Cases</h1>
                <div className="row container mx-auto mt-5">
                    <Col xs={12} md={3} className="my-3">
                        <a href="#districts">
                            <Button variant="success" className="w-100">Districts</Button>
                        </a>
                    </Col>
                    <Col xs={12} md={3} className="my-3">
                        <a href="#schools">
                            <Button variant="success" className="w-100">Schools</Button>
                        </a>
                    </Col>
                    <Col xs={12} md={3} className="my-3">
                        <a href="#community">
                            <Button variant="success" className="w-100">Community</Button>
                        </a>
                    </Col>
                    <Col xs={12} md={3} className="my-3">
                        <a href="#longdistance">
                            <Button variant="success" className="w-100">Remote Learning</Button>
                        </a>
                    </Col>
                    
                </div>

                <div id="usecases-header" className="text-center mb-5">
                    <h2 className="h2 font-weight-bold text-center">Simply Better</h2>
                    <h5 id="">Great Leaps is incredibly versatile due to it's ease of implementation for instuctors, short 15-minute sessions, and effectiveness with a wide variety of students.</h5>
                </div>
            </div>

            <span className="anchor" id="districts"></span>
            <div className="picture-header" id="districts">
                Districts
            </div>

            <div className="usecase-content">
                <h5>NYC School District</h5>
                <p>NYC Department of Education has used Great Leaps programs district wide for 20 years. Under the direction of Chancellor Carmen Farina,
                Great Leaps was used in regular ed reading remediation in all burrows. They organized and trained hundreds of paraprofessionals
                in the implementation of Great Leaps. Now they are running pilot programs in nearly 200 schools district wide with Great Leaps Digital
                        and with that have the new ability to accurately address fidelity district wide.</p>
            </div>

            <span className="anchor" id="schools"></span>
            <div className="picture-header" id="schools">
                Schools
                </div>

            <div className="usecase-content">
                <h5>Public Schools</h5>
                <p>P.S. 39 in Staten Island, NY works with 50 students a day using 6 paraprofessionals. The digital platform enables them to know up to
                        the minute that each student is getting the intervention with fidelity and daily, automatic data recording guides their RTI process.</p>

                <h5>Charter Schools</h5>
                <p>San Francisco Day School trained parents to use the program to have them use it over summer vacation. When they came back to school in
                the Fall, all but one of those students tested out of remedial reading. Instead of summer slide, these students gained, on average, 1.25
                        years in instructional level.</p>

                <h4><a href="https://greatleaps.com/pages/contact-us" target="_blank" rel="noreferrer">Click here</a> to request a pilot trial for your school.</h4>
            </div>

            <span className="anchor" id="community"></span>
            <div className="picture-header" id="community">
                Community
                </div>

            <div className="usecase-content">
                <h5>Parents</h5>
                <p>Jessica Howell started the program after the school told her that her daughter was in danger of not being promoted to 3rd  grade.
                She began at the start of summer vacation and not only did Elsie get promoted to 3rd grade but Elsie continued on to make more than
                    2 years progress in 10 months using the Great Leaps Digital program. Checkout more on her story and <Link to="/about/testimonials">other
                    parent testimonials here.</Link></p>

                <h5>Tutors</h5>
                <p>Instead of having students travel to your office, the Great Leaps Digital program enables tutors to reach students wherever they are
                    at. This has enabled us to create a <a href="https://tutoring.greatleaps.com/">Tutoring Platform</a> to connect trained Great Leaps
                    tutors with students through our built in video chat. This has also enabled existing tutors to expand their business nationwide like <a href="https://www.leapingwithlisa.com/">Leaping with Lisa</a>
                    , for example who has built her business from student success across the country using Great Leaps.</p>


                <h5>Community Organizations</h5>
                <p>Groups such as the <a href="https://www.acac.net/">Allegheny Center Alliance Church</a> in Pittsburg, PA and <a href="https://sanctuaryon8th.org/great-leaps-tutoring/">Sanctuary on 8th Street</a> in Jacksonville, FL are changing lives
                using the Great Leaps Digital program in their afterschool programs. Caring volunteers work with students individually while they are
                        already at their facilities, equipping them for academic success.</p>
            </div>

            <span className="anchor" id="longdistance"></span>
            <div className="picture-header" id="longdistance">
                <span className="hidden-xxs d-none d-sm-inline hidden-sm hidden-md">Long</span> Distance <span className="hidden-xxs d-none d-sm-inline hidden-sm">Learning</span>
                </div>

            <div className="usecase-content">
                <h5>Schools Go Remote</h5>
                <p>Due to school closures in March 2020, the Great Leaps’ team helped transition thousands of students and their many instructors from 
                    using Great Leaps in person to going remote with Great Leaps Digital. The transition was smooth and simple for instructors and students 
                    alike! Using free video conferencing programs such as Zoom, Facetime, Skype or Google Hangouts, students continued to receive one on 
                    one direct reading instruction from home. More specifically, in a pilot program in New York City with nearly 200 schools, instructors 
                    were forced to transition to long distance learning mid semester and the results have been nothing short of amazing. Students’ progress 
                    continued to excel and we received a 90%+ report of strong approval from instructors who were able to continue working with their students.</p>
            </div>
        </React.Fragment>
    )

}

export default UseCases;
