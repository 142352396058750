import React from 'react'
import eventManager from '../../helpers/eventManager'

class MessageContainer extends React.Component
{
    state = {
        messages: [],
        type: "info",
        info: []
    }

    clearID = null
    messageID = null

    createMessage = (data) =>
    {
        window.scrollTo(0, 0)
        this.setState({ messages: data.message, type: data.type })
    }

    clearMessages = () =>
    {
        this.setState({ messages: [] })
    }

    componentDidMount()
    {

        this.messageID = eventManager.listenEvent("MESSAGE", this.createMessage.bind(this))
        this.clearID = eventManager.listenEvent("MESSAGE-CLEAR", this.clearMessages.bind(this))

        let messageContainer = this
        
        this.props.history.listen((location) =>
        {
            messageContainer.clearMessages()
        })
    }

    componentWillUnmount() {
        eventManager.unlistenEvent("MESSAGE", this.messageID)
        eventManager.unlistenEvent("MESSAGE-CLEAR", this.clearID)
    }

    render()
    {
        return (
            <React.Fragment>
                {
                    this.state.messages.map((message) =>
                        <div className="alert-container" key={message}>
                            <div className={`alert alert-${this.state.type}`} key={message}>{message}</div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

export default MessageContainer
