import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { Error } from './types/error'

/**
 * Get instructors limit
 * 
 * @param username instructor username
 * @param groupName group instructor is in
 */
export function get(username: string, groupName: string) : Promise<{
    studentLimit: number,
    maxLimit: number,
    valid: boolean
}> {
    return modelRequest.get('/api/instruction/limit', {
        params: {
            username: username,
            groupName: groupName
        }
    })
}

/**
 * Get instructors limit
 * 
 * @param username instructor username
 * @param groupName group instructor is in
 */
export function post(username: string, groupName: string, studentLimit: string | null) : Promise<Error> {
    return modelRequest.post('/api/instruction/limit', {
            username: username,
            groupName: groupName,
            studentLimit: studentLimit
    })
}
