import React from 'react'
import { Link } from 'react-router-dom'

class SalesCodeHome extends React.Component {

    render() {
        return (
            <React.Fragment>
                <h1>Sales Code</h1>

                <div className="row">
                    <div className="col-md-6">
                        <Link to="/admin/create-transaction" className="btn btn-secondary btn-xl">Create Transaction</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/sales-code/generate" className="btn btn-secondary btn-xl">Generate Code</Link>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <Link to="/admin/sales-code/send-email" className="btn btn-secondary btn-xl">Send Code Email</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default SalesCodeHome;
