import React, { useContext, useEffect } from 'react'
import SubjectTabs from '../../../components/subject-navigation/SubjectTabs'
import { RecordContext } from '../RecordsPage'
import Donut from '../../../components/subject-navigation/Donut'
import printCharts from '../../../helpers/printCharts'

function Graph({header=undefined}) {
    const context = useContext(RecordContext)
    if(!header) header = context.type[0].toUpperCase() + context.type.slice(1)

    function camelCaseToCapital(text) {
        const result = text.replace(/([A-Z])/g, " $1");
        const capitalCase = result.charAt(0).toUpperCase() + result.slice(1);
        return capitalCase
    }

    useEffect(()=> {
        printCharts.printInitialize(context.currentChart)
    },[context.currentChart])

    return(<>
        <div className="dont-print">
            <h3 className='h4 fw-bold'>{context.studentName}'s {camelCaseToCapital(context.currentChart)}</h3>
            <div className='d-flex'>
                <div className="records-chart d-flex flex-grow-1 border border-primary rounded px-2">
                    <div id="progress-chart" className="overflow-hidden flex-grow-1" data-show-goals="true" />
                    <div className='d-none d-md-block'>
                        <Donut studentID={context.studentID} type={context.type}/>
                    </div>
                </div>
                <SubjectTabs  
                    studentID={context.studentID}
                />
            </div>
        </div>
        <div id="printable-charts">
            <h2>{header}</h2>
        </div>
    </>)
}

export default Graph
