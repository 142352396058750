import React from 'react';
import Container from 'react-bootstrap/Container'
import ken_classroom from '../../content/images/ken-classroom.jpg';
import ken_office from '../../content/images/ken-office.jpg';

function Story() {

    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css" href="/styles/about.css?version=8-28-2021" />
            <div className="about-title-image text-center">
                <h1 className="about-title-main mt-5">Our Story</h1>
                <label className="lead mt-4">Our mission is to <strong>equip all students</strong> for success through
                    <strong> simple</strong> and <strong>effective</strong> educational programs.
                </label>
            </div>

            <Container id="research" fluid="lg" className="">
                <h2 className="section-header h3 font-weight-bold">History</h2>

                <div className="row">
                    <p className="pl-3">Success breeds success and necessity is the mother of invention. The story of Great Leaps’ international success starts like so many, with humble beginnings. In 1991 a teacher by the name of Ken Campbell was leading a special needs classroom south of the swamp in central Florida. Born with a passion to help all children become “Simply better,” Ken grew frustrated as he observed how both classroom time and the tools provided were lacking. Like other great teachers before him, Ken sacrificed his own time and money to create and test solutions that would effectively help these children improve.</p>
                    <div className="col-md-8 text-justify vcenter">
                        <p className="indented">His efforts were successful right from the start. The real catalyst that made Great Leaps an internationally implemented program was a routine visit from a leading University of Florida Professor, Dr. Cecil Mercer. During the professors visit he overheard a student ask the principal to move an assembly time “so that it would not interfere with his reading lesson.” WOW!  This would get any teachers attention and the professor promptly asked for some copies of Ken's program for conducting trials at a High School in Gainesville, Florida. As history would have it, the trial was a huge success. Dr. Mercer then proudly presented the data and the initial program at the Learning Disabilities Association Conference in Chicago and positive reviews prompted to publish his science and Great Leaps was launched.</p>
                        <p className="indented">Today, Great Leaps is in all fifty states and in over forty countries. Proudly Ken’s gifts have helped over half a million children and growing simply because, it works. Today Great Leaps is unrolling “Great Leaps Digital,” which integrates decades of experience and wisdom with 21st century technology making the tools faster more accessible, and more effective than ever before!</p>
                    </div>

                    <div className="col-md-4 vcenter">
                        <img
                            className="img-fluid center-block p-2"
                            src={ken_office}
                            alt="Ken in his office"
                            title="He makes reading fun!"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                    </div>
                </div>

                <h2 className="h3 font-weight-bold mt-4">About the Author</h2>
                <p className="text-justify">Ken Campbell's 36 year teaching career and his extensive study of accomplished academic researchers certainly adds credibility to the highly effective Great Leaps Programs, but his passion to help children is really the driving force behind the company and its growth. Although he will always be a teacher at heart, there came a time where Great Leaps allowed his impact on students to grow beyond teaching one classroom at a time. So the accidental entrepreneur was born.</p>

                <div className="row justify-content-center align-items-center pt-2">
                    <div className="col-md-5">
                        <img
                            className="img-fluid center-block"
                            width="auto"
                            height="auto"
                            src={ken_classroom}
                            alt="Ken in a classroom"
                            title="He makes reading fun!"
                        />
                    </div>
                    <div className="col-md-6 text-justify vcenter">
                        <p className="indented">When he's not in the office Ken enjoys a deep connection with family history through his study of Scotland and his ancestors. Once fall comes around, you can catch him cheering on his alma mater, the University of Florida, clad in orange and blue. Ken continues to write short stories and novels in his free time but most importantly, he enjoys spending time with his wife Diana, three kids, and 6 grandkids either on the beaches of St. Augustine or simply relaxing at home. He is a strong believer in the power of community and developed Great Leaps to be easy enough for virtually anyone to teach a child to read and leave a lasting impact on their life.</p>
                    </div>
                </div>
            </Container>

        </React.Fragment>
    )
}

export default Story;
