import React from 'react'
import getURLParameters from '../../../helpers/getURLParams'
import customRequest from '../../../helpers/customRequest'
import redirect from '../../../components/redirect/redirect'
import message from '../../../components/message/message'
import BeatLoader from 'react-spinners/BeatLoader'

class VerifyEmailAddress extends React.Component {
    
    componentDidMount() {
        var params = getURLParameters(this.props)

        if (!params.code) {
            return redirect.send('/', this.props, () => {
                message.error("We weren't able to verify your email address, please try again.")
            })
        }

        customRequest.post('/api/account/verify-email', {
            code: params.code
        })
        .then(({data: response}) => {

            if (response.valid) {
                this.props.updatePermissions()
                .then((user) => {
                    if (user === "customer") {
                        redirect.send('/dashboard', this.props, () => {
                            message.success("Your email was sucessfully verified.")
                        })
                    }
                    else if (user === "student") {
                        redirect.send('/student/dashboard', this.props, () => {
                            message.success("Your email was sucessfully verified.")
                        })
                    }
                    else {
                        redirect.send('/account/log-in/teacher', this.props, () => {
                            message.info("Please log in to continue with your account")
                        })
                    }
                })
            }
            else {
                redirect.send('/dashboard', this.props, () => {
                    message.error(response.message)
                })
            }
        })
    }

    render() {
        return (
            <div style={{ margin: "0 auto", display: "table", height: "80vh"}}>
                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                    <BeatLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                        />
                </div>
            </div>
        )
    }
}

export default VerifyEmailAddress
