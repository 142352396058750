import React from 'react'
import MoonLoader from 'react-spinners/ClimbingBoxLoader'

class Loader extends React.Component<{ visible: boolean, opacity?: number, text?: string }>
{
    render()
    {
        var finalDisplay = "none"
        var finalOpacity = 0.4
        var color = "bg-secondary"

        var {visible, opacity, text} = this.props

        if (visible)
            finalDisplay = 'inline'
        
        if (opacity)
            finalOpacity = opacity
        
        if (finalOpacity > 0.8)
        {
            color = "bg-white"
        }

        

        return(
            <div id="img-loader" className="w-100 h-100" style={{"display": finalDisplay}}>
                <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} >
                    {text}
                    <MoonLoader
                        color={'#05b'}
                    />
                </div>
                <div className={`bg-grey fixed-top w-100 h-100 ${color}`} style={{"opacity" : finalOpacity}}/>
            </div>
        )
    }
}

export default Loader
