function initializeSubmissionPrevention()
{	
	var forms = document.getElementsByTagName("form");
	
	var length = forms.length;
	for (var index = 0; index < length; index++)
	{
		var form = forms[index];
		
		form.addEventListener("submit", preventSubmission);
	}
}

function preventSubmission(event)
{
	var form = event.target;
	
	if (form.classList.contains("prevent-submit") === true)
	{
		event.preventDefault();
	}
	
	else if (form.classList.contains("prevent-next-submit") === true)
	{
		form.classList.remove("prevent-next-submit");
		form.classList.add("prevent-submit");
	}
}

export default initializeSubmissionPrevention;
