import { models } from '@stem-sims/great-leaps-react-common'
var recentVersion = null


function getAuth() {
    
    return models.auth.get()
    .then((auth) =>
    {
        if (!auth.user) {
            return Promise.resolve({
                user: ["guest"],
                currentOrganization: null
            })
        }

        //reload react if there is a new version available 
        if (recentVersion !== null && auth.version !== recentVersion) {
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name)
            })
            return window.location.reload(true)
        }

        recentVersion = auth.version

        // TODO: if org or org.name is null, throw error

        return Promise.resolve({
            user: auth.user,
            currentOrganization: auth.currentOrganization
        })
    })
}


export const auth = {
    "get": () => {
        return getAuth().catch(() => 
            Promise.resolve({
                user: ["guest"],
                currentOrganization: null
            })
        )
    }
}
export default auth
