function initializeAutosubmit()
{
	var form = document.getElementsByClassName("form-autosubmit");
	form = form[0];
	if (form != null)
	{
		if (form.action === "")
		{
			form.action = document.location.href;
		}
		
		
		var title = document.title.split(" - ");
		title[0] = "Home";
		title = title.join(" - ");
		
		document.title = title;
		
		
		form.submit();
		
		window.history.replaceState(window.history.state, "", "/");
	}
}

export default initializeAutosubmit;
