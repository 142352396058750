import React, { useEffect } from 'react'
import eventManager from '../../helpers/eventManager'
import { Redirect } from 'react-router-dom'

function RedirectContainer() {

    const [url, setUrl] = React.useState(null) //  url is redirect url

    function createMessage({url, callback}) {
        setUrl(url)
        callback()
    }

    useEffect(() => {
        eventManager.listenEvent("REDIRECT", createMessage.bind(this))
    },[])
    useEffect(() => {
        if (url !== null) {
            setUrl(null)
        }
    },[url])

    if (url === null) {
        return <React.Fragment></React.Fragment>
    }
    return(
        <React.Fragment>
            <Redirect to={url} />
        </React.Fragment>
    )
}

export default RedirectContainer
