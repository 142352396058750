import { modelRequest } from "@stem-sims/great-leaps-react-common"

export interface SignUpProps {
    name: string
    username: string
    emailAddress: string
    password: string
    referral?: string
    entity?: string
    role: string
    postalCode: string
    city: string
    country: string
    state: string
    phoneNumber: string
    notes: string
}

export function post(props: SignUpProps) {
    return modelRequest.post("/api/sign-up", props)
}

