


import React, { useContext, useEffect } from 'react'
import Col from "react-bootstrap/Col"
import { ReactTable } from '@stem-sims/nexus'
import { FilterByInstructor } from '../../../components/StudentRoster'
import { RecordContext } from '../RecordsPage'

export default function RecordsRoster({columns,data: students, clickRow=undefined, currentStudent}) {
    const context = useContext(RecordContext)
    const [filterValue, setFilterValue] = React.useState("")
    const [instructors, setInstructors] = React.useState([])

    const filteredStudentList = React.useMemo(() => {
        if (!students) return []
        return students.filter((student) => {
            if(!student.instructors || student.instructors.length === 0) return true

            //filter out archived students todo: make a way to view archived students and have it work with next/prev buttons (GL-660)
            if (student.licenses.every((license) => license.status === "Archived")) {
                return false
            }

            if (student.instructors.find((instructor) => instructor.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1)) {
                return true
            }
            return false
        })
    }, [students, filterValue])

    useEffect(() => {
        const instructorsByStudent: string[] = students.map((s) => s.instructors) 
        const instructorNames = instructorsByStudent.flat(1)
        const uniqueInstructorsNames = instructorNames.filter((instruct, index, array) => (array.indexOf(instruct) === index))
        setInstructors(uniqueInstructorsNames)
    },[students])
    if(clickRow) var classString = "cursor-pointer"
    var customClass = (row) => `${row.original.fidelityWarning && "fidelity-warning"} ${row.original.id.toString() === context.studentID ? "selectedStudent" : ""} ${classString}`

    return(<>
        <Col className='filterByInstructor mx-2'>
            <FilterByInstructor instructors={instructors} setFilterValue={setFilterValue} />
        </Col>
        <ReactTable columns={columns} bulkActions={[]} data={filteredStudentList} sortBy={[{ id: "name" }]} customClass={customClass} clickRow={clickRow} hidePagination/>
    </>)
}
