import { modelRequest } from '@stem-sims/great-leaps-react-common'

/**
 * Create an organization
 * 
 * @param name name of org
 */
export function post(name: string) : Promise<{ valid: boolean, message: string }> {
    return modelRequest.post('/api/organizations', {
        name: name
    })
}

/**
 * Gets all organizations that a user is in
 */
export function get() : Promise<{ name: string, groups: string[], id: string }[]> {
    return modelRequest.get('/api/organizations')
}
