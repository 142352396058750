import eventManager from '../../helpers/eventManager'

export const message = {
    success(...message)
    {
        eventManager.triggerRecentEvent("MESSAGE", {
            type: "success",
            message: message
        })
    },

    error(...message)
    {
        eventManager.triggerRecentEvent("MESSAGE", {
            type: "danger",
            message: message
        })
    },

    info(...message)
    {
        eventManager.triggerRecentEvent("MESSAGE", {
            type: "info",
            message: message
        })
    },
    
    clear()
    {
        eventManager.triggerRecentEvent("MESSAGE-CLEAR")
    }
}
export default message
