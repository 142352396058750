import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { Link, useHistory } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'

import add_student from '../../content/images/add-student.png'
import extend from '../../content/images/extend.png'
import subscriptionResponse from '../../models/subscriptions'

export default function Choose() {
    const history = useHistory()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        subscriptionResponse().then(async response => {
            const hasExpiredSubs = response.expiredSubscriptions.length > 0
            if (!hasExpiredSubs && response.validSubscriptions.every(sub => sub.trial)) {
                history.push("/subscribe")
            } else {
                setLoading(false)
            }
        }).catch((err) => {
            history.push("/subscribe")
        })
    }, [history])

    if (loading) {
        return <div className="text-center my-auto">
            <BeatLoader />
        </div>
    }

    return <Container className="text-center">
        <h1 className='h2 text-center mt-5'>Manage Subscriptions<hr/></h1>
        <p className='text-center lead py-3'>You have existing subscription(s), would you like to extend a subscription's duration or add more students to a subscription?</p>
        <Row className="justify-content-center text-center">
            <Col xs={8} sm={6} md={4} xl={3}>
                <Link to="/subscribe/extend/summary">
                    <div className="btn btn-secondary">
                        <img className="img-fluid px-3 py-2" src={extend} alt="An hourglass with a clockwise circular arrow surrounding it" />
                        <label className='h5 d-block'>Extend Subscriptions</label>
                    </div>
                </Link>
            </Col>
            <Col xs={8} sm={6} md={4} xl={3}>
                <Link to="/subscribe/add">
                    <div className="btn btn-secondary">
                        <img className="img-fluid px-3 py-2" src={add_student} alt="A cartoon drawing of a person with a plus superimposed" />
                        <label className='h5 d-block'>Add Licenses</label>
                    </div>
                </Link>
            </Col>
        </Row>
    </Container>
}
