import Input from './input';
import NoMessage from './messages/no-message';
import SuccessMessage from './messages/success-message';
import ErrorMessage from './messages/error-message';
import Request from '../request';

function initializeFormValidation(submitCallback = (event) => {})
{
	var forms = document.getElementsByClassName("form-validate");

	var formsLength = forms.length;
	for (let formIndex = 0; formIndex < formsLength; formIndex++)
	{
		var form = forms[formIndex];

		form.addEventListener("submit", (event) => validateForm(event, submitCallback));

		var blurEvent = document.createEvent("Event");
		blurEvent.initEvent("blur", false, true);
		blurEvent.submission = false;
		blurEvent.showFeedback = true;


		var required = form.querySelectorAll("input[required], select[required], input[data-required-alternate]");
		if (required.length > 0)
		{
			let length = required.length;
			for (let index = 0; index < length; index++)
			{
				let input = Input.getInput(required[index]);
				input.addValidator(validateRequiredInput);

				if (input.value !== "")
				{
					input.input.dispatchEvent(blurEvent);
				}
			}
		}

		var optional = form.querySelectorAll("input:not([required]):not([type='hidden']):not(.safari-username-autofill), textarea:not([required])");
		if (optional.length > 0)
		{
			let length = optional.length;
			for (let index = 0; index < length; index++)
			{
				let input = Input.getInput(optional[index]);
				input.addValidator(validateOptionalInput);

				if (input.value !== "")
				{
					input.input.dispatchEvent(blurEvent);
				}
			}
		}


		var name = document.getElementById("name");
		if (name != null)
		{
			name = Input.getInput(name);
			name.addValidator(validateName);

			if (name.value !== "")
			{
				name.input.dispatchEvent(blurEvent);
			}
		}

		var studentName = document.getElementById("student-name");
		if (studentName != null)
		{
			studentName = Input.getInput(studentName);
			studentName.addValidator(validateStudentName);

			if (studentName.value !== "")
			{
				studentName.input.dispatchEvent(blurEvent);
			}
		}


		var username = document.querySelector("#username:not(.safari-username-autofill)");
		if (username != null)
		{
			username = Input.getInput(username);
			username.addValidator(validateUsername);

			if (username.value !== "")
			{
				username.input.dispatchEvent(blurEvent);
			}
		}

		var emailAddress = document.getElementById("email-address");
		if (emailAddress != null)
		{
			emailAddress = Input.getInput(emailAddress);
			emailAddress.addValidator(validateEmailAddress);

			if (emailAddress.value !== "")
			{
				emailAddress.input.dispatchEvent(blurEvent);
			}
		}


		var number = form.querySelectorAll("input[type=number]");
		if (number.length !== 0)
		{
			let length = number.length;
			for (let index = 0; index < length; index++)
			{
				let input = Input.getInput(number[index]);
				input.addValidator(validateNumber);

				if (input.value !== "")
				{
					input.input.dispatchEvent(blurEvent);
				}
			}
		}


		var string = form.querySelectorAll("input.string");
		if (string.length !== 0)
		{
			let length = string.length;
			for (let index = 0; index < length; index++)
			{
				let input = Input.getInput(string[index]);
				input.addValidator(validateString);

				if (input.value !== "")
				{
					input.input.dispatchEvent(blurEvent);
				}
			}
		}

		var confirmation = form.querySelectorAll("input.confirmation");
		if (confirmation.length !== 0)
		{
			var length = confirmation.length;
			for (var index = 0; index < length; index++)
			{
				let input = Input.getInput(confirmation[index]);
				input.addValidator(validateConfirmation);

				if (input.value !== "")
				{
					input.input.dispatchEvent(blurEvent);
				}
			}
		}
	}
}

function validateForm(event, submitCallback)
{
	var form = event.target;

	var blurEvent = document.createEvent("Event");
	blurEvent.initEvent("blur", false, true);
	blurEvent.submission = true;
	blurEvent.showFeedback = true;
	event.preventDefault();

	if (validForm(form, blurEvent) === true)
	{
		submitCallback(event)
	}
}

function validForm(form, event)
{
	var inputs = form.querySelectorAll("input[required], select[required], input[data-required-alternate], input:not([required]):not([type='hidden'])");
	var success = true;
	var length = inputs.length;

	if (length !== 0)
	{
		for (var index = 0; index < length; index++) {
			success = inputs[index].dispatchEvent(event) && success;
		}
	}

	return success;
}


function validateRequiredInput(input)
{
	if (input.value.length < 1)
	{
		if (input.hasAttribute("data-required-alternate"))
		{
			var element = input;

			do
			{
				element = document.getElementById(element.getAttribute("data-required-alternate"));

				if (element.value.length >= 1)
				{
					return new NoMessage();
				}
			}

			while (Input.getInput(element) !== input);
		}

		return new ErrorMessage("Please " + input.getAttribute("data-description") + ".");
	}

	return new SuccessMessage();
}

function validateOptionalInput(input)
{
	return new SuccessMessage();
}


function validateName(input)
{
	var name = input.value.trim();

	if (name.length < 3 || name.indexOf(" ") === -1)
	{
		return new ErrorMessage("Please enter both a first and last name.");
	}

	else if (name.length > 175)
	{
		return new ErrorMessage("Your name must be no more than 175 characters in length.");
	}

	return new SuccessMessage();
}

function validateStudentName(input)
{
	var name = input.value.trim();

	var message = validateName(input);

	if (!(message instanceof SuccessMessage))
	{
		var messageText = message.text;
		message.text = messageText.replace("Your name", "Your student's name");

		return message;
	}

	else
	{
		if (name === input.getAttribute("data-initial"))
		{
			return new SuccessMessage();
		}

		if (input.unique === true)
		{
			var request = new Request();
			request.validator = validateStudentNameUniqueness;
			request.method = "GET";
			request.path = "/api/students/name-available?name="+ encodeURIComponent(name);

			return request;
		}
	}

	return new SuccessMessage();
}

function validateStudentNameUniqueness(response)
{
	if (JSON.parse(response).exists !== "false")
	{
		return new ErrorMessage("You have already added a student with this name. Please add information, such as a middle initial, to differentiate your students.");
	}

	return new SuccessMessage();
}


function validateUsername(input)
{
	var username = input.value;
	var length = username.length;

	if (username.trim().length !== length)
	{
		return new ErrorMessage("Please enter a username that does not start or end with a whitespace character.");
	}

	else if (length > 175)
	{
		return new ErrorMessage("Your username must be no more than 175 characters in length.");
	}

	else if (input.unique === true)
	{
		var request = new Request();
		request.validator = validateUsernameUniqueness;
		request.method = "GET";
		request.path = "/api/username-available?username=" + encodeURIComponent(username);

		return request;
	}

	return new SuccessMessage();
}

function validateUsernameUniqueness(response)
{
	if (response !== "true")
	{
		return new ErrorMessage("The username you entered has already been taken.");
	}
	return new SuccessMessage();
}


function validateEmailAddress(input)
{
	var errorMessage = new ErrorMessage("Please enter a valid email address.");

	var emailAddress = input.value;
	var length = emailAddress.length;

	if (length < 4)
	{
		return errorMessage;
	}

	else if (emailAddress.trim().length !== length)
	{
		return new ErrorMessage("Please enter an email address that does not start or end with a whitespace character.");
	}

	else if (length > 175)
	{
		return new ErrorMessage("Your email address must be no more than 175 characters in length.");
	}

	else
	{
		if (emailAddress === input.getAttribute("data-initial"))
		{
			return new SuccessMessage();
		}

		var atPosition = emailAddress.indexOf("@");
		if (atPosition <= 0 || atPosition >= (length - 2))
		{
			return errorMessage;
		}

		else if (input.unique === true)
		{
			var request = new Request();
			request.validator = validateEmailAddressUniqueness;
			request.method = "GET";
			request.path = "/api/emailaddress-available?email=" + encodeURIComponent(emailAddress);

			return request;
		}
	}

	return new SuccessMessage();
}

function validateEmailAddressUniqueness(response)
{
	if (response !== "true")
	{
		return new ErrorMessage("The email address you entered has already been used to create an account.");
	}

	return new SuccessMessage();
}


function validateConfirmation(input)
{
	var confirms = input.getAttribute("data-confirms");
	confirms = document.getElementById(confirms);
	if (input.value !== confirms.value)
	{
		return new ErrorMessage("Your " + input.getAttribute("data-type") + " do not match.");
	}

	return new SuccessMessage();
}


function validateNumber(input)
{
	var number = parseInt(input.value);

	var minimum = Number.NEGATIVE_INIFINITY;
	if (input.hasAttribute("min"))
	{
		minimum = parseInt(input.getAttribute("min"));
	}

	var maximum = Number.POSITIVE_INIFINITY;
	if (input.hasAttribute("max"))
	{
		maximum = parseInt(input.getAttribute("max"));
	}


	if (number < minimum)
	{
		return new ErrorMessage("Please enter a number greater than or equal to " + minimum + ".");
	}

	else if (number > maximum)
	{
		return new ErrorMessage("Please enter a number less than or equal to " + maximum + ".");
	}

	return new SuccessMessage();
}


function validateString(input)
{
	var minimumLength = input.getAttribute("minlength");
	if (minimumLength == null)
	{
		if (input.getAttribute("required") == null)
		{
			minimumLength = 0;
		}

		else
		{
			minimumLength = 1;
		}
	}

	else
	{
		minimumLength = parseInt(minimumLength);
	}

	var maximumLength = input.getAttribute("maxlength");
	if (maximumLength == null)
	{
		maximumLength = Number.POSITIVE_INFINITY;
	}

	else
	{
		maximumLength = parseInt(maximumLength);
	}


	var length = input.value.length;
	if (length <= maximumLength && length >= minimumLength)
	{
		return new SuccessMessage();
	}

	else
	{
		var type = input.getAttribute("data-type");

		var message;

		if (maximumLength === minimumLength)
		{
			message = "Your " + type + " must be " + String(maximumLength) + " characters in length.";
		}

		else if (maximumLength === Number.POSITIVE_INFINITY)
		{
			message = "Your " + type + " must be at least " + String(minimumLength) + " characters in length.";
		}

		else if (minimumLength <= 1)
		{
			message = "Your " + type + " must be no more than " + String(maximumLength) + " characters in length.";
		}

		else
		{
			message = "Your " + type + " must be between " + String(minimumLength) + " and " + String(maximumLength) + " characters in length.";
		}


		return new ErrorMessage(message);
	}
}

export { initializeFormValidation, validForm };
