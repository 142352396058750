import axios from "axios"

export const Programs = ["Math", "Reading"] as const
export type ProgramsType = typeof Programs[number]


const Gender = ['Male', 'Female', 'Other', 'Decline to Answer'] as const
type GenderType = typeof Gender[number]

const Ethnicity = ['White', 'Hispanic Origin', 'Mideastern/Arab Descent', 'Black', 'Asian/Pacific Islander', 'South Asian', 'American Indian/Alaskan Native', 'Multi-Racial', 'Decline to Answer'] as const
type EthnicityType = typeof Ethnicity[number]

const SchoolEnrollment = ['Public', 'Private', 'Cyber', 'Homeschooling', 'Not Applicable', 'Decline to Answer'] as const
type SchoolEnrollmentType = typeof SchoolEnrollment[number]

const Iep = ['Yes', 'No', 'Decline to Answer'] as const
type IepType = typeof Iep[number]

const Retention = ['Never Retained', 'Retained 1 Year', 'Retained 2 Year', 'Retained 3 Year', 'Decline to Answer']
type RetentionType = typeof Retention[number]

const EnglishLanguageLearner = ['Yes', 'No', 'Decline to Answer']
type EnglishLanguageLearnerType = typeof EnglishLanguageLearner[number]

export interface Student {
    id: string
    name: string
    orgId: string
    hasDoneProbe: boolean
    // If owner is present then the student is shared
    owner?: {
        username: string
    }
    licenses: {
        program: ProgramsType
        status: "Active" | "Archived"
        hasDoneProbe: boolean
    }[]
}

export interface StudentInfo {
    id: string,
    name: string
    birthDay: string
    gender: GenderType | null
    ethnicity: EthnicityType | null
    currentSchoolEnrollment: SchoolEnrollmentType | null
    iep: IepType | null
    retention: RetentionType | null
    englishLanguageLearner: EnglishLanguageLearnerType | null
    group: string | null,
    instructors: string[]
}

export type StudentCode = {
    code: string,
    expires: Date
}

export interface StudentMoreInfo extends StudentInfo, Student {}

export function getAll(customerUsername?: string) : Promise<{data: StudentMoreInfo[]}> {
    return axios.get('/api/students', {
        params: {
            customerUsername: customerUsername
        }
    })
    .then((response) => response.data)
}

export function getOne(studentId): Promise<{data: StudentMoreInfo}> {
    return axios.get("/api/student", {
        params: { studentId }
    })
    .then((response) => response.data)
}
export function updateOne(student: StudentInfo, programs: ProgramsType[]): Promise<{data: StudentMoreInfo}> {
    return axios.post("/api/students/edit", {
        programs: programs,
        student: student
    }).then((response) => response.data)
}
export function getRecords({studentId, type}): Promise<any[]> {
    return axios.get("/api/records", {
        params: {
            studentID: studentId,
            type: type
        }
    }).then((response) => response.data.rawRecords)
}