import React from 'react';
type ResearchSummaryProps = {
    url: string,
    start: string,
    linkText: string,
    end: string,
    bullet?: string
}
function ResearchSummary({url, start, linkText, end, bullet}: ResearchSummaryProps) {
    return(<>
        <p className="research-citation">
            {start} 
            <a href={url}>
                {linkText}
            </a> 
            <em>{end}</em>
        </p>
        {bullet && 
            <ul className="research-bullet">
                <li>{bullet}</li>
            </ul>     
        }
    </>)
}

export default ResearchSummary;
