export default function stringToFloat(value: string | number) {
    //only 0-9 and decimal points are accepted
    if (typeof value === "number") {
        return value
    }
    let val = value.replace(/[^0-9.]/gi, "")
    if (!val) {
        val = "0"
    }

    return parseFloat(val)
}
