import React from 'react';
import Container from 'react-bootstrap/Container'

function PrivacyPolicy() {

    return (
        <Container>
            <h1 className='section-header mt-4'>Privacy Policy</h1>

            <div className="legal-block">
                <div>
                    <p>The Great Leaps Digital website is operated by Diarmuid, Inc. and referred to as "Diarmuid", "us" or "we". This document explains what information we collect about visitors and subscribers to the Great Leaps Digital website and how we use this information. For the purpose of clarity, "subscriber" shall refer only to someone who registers their contact information and/or starts a subscription to our services, and "student" shall refer to anyone entered as a student in the program. "User" refers to both of these groups as well as anyone else who visits our website. "You" refers to a member of whichever category was last addressed. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at glinfo@greatleaps.com.</p>

                    <p>The privacy of those who visit this website is important to us. We design and operate our technology and services with our users’ protection and privacy in mind. We support the goals and guidelines set forth by oversight laws and bodies such as the Family Educational Rights and Privacy Act (FERPA), the Children’s Online Privacy Protection Act of 1998 (COPPA), the Center for Media Education (CME), the Children’s Advertising Review Unit (CARU) of the Council of Better Business Bureaus and other organizations devoted to providing online safety and privacy for children, households, parents and schools. This privacy policy outlines the types of information we gather when users access this website, as well as some of the steps we take to safeguard it against unauthorized use.</p>

                    <p>For purposes of this Policy, "Personally Identifiable Information" (PII) includes any information or combination of information that could be used to trace or distinguish someone’s identity, including but not limited to email, name and address. "Non-Personal Information" does not enable identification of an individual.</p>

                    <p>This privacy policy applies to all information collected through our website (such as https://digital.greatleaps.com), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").</p>
                </div>

                <div>
                    <h2 className="section-header">1. WHAT INFORMATION DO WE COLLECT?</h2>
                    <div>
                        <h3>Personal information you disclose to us</h3>

                        <p><strong>In Short:</strong> <em>We collect personal information that you provide to us such as name, address, contact information, passwords and security data.</em></p>

                        <p>We collect personal information that you voluntarily provide to us when registering at the Sites expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites (such as entering competitions, contests or giveaways) or otherwise contacting us.</p>

                        <p>Data governed under this policy (later referred to any time "data" is mentioned) includes all Personally Identifiable Information and any other information acquired by means of users accessing the website, using any of our services or communicating with us. Data may not be used for any non-educational purpose unless otherwise explicitly discussed in this policy.</p>

                        <p>We only collect data that is necessary for the educational purpose of providing and improving our services. We seek particularly to comply with all FERPA and COPPA privacy protections.</p>

                        <p>We do not knowingly collect or maintain Personally Identifiable Information from any person under the age of 18 ("minors") without parental consent. Minors may not use this website or its services without parental consent or at least consent of the school when that is sufficient under both the Family Educational Rights and Privacy Act (FERPA), the Children’s Online Privacy Protection Act of 1998 (COPPA) and local and state guidelines to constitute parental consent. If we learn that any data about any minor has been collected without parental consent, we will take reasonable steps to delete any such information.</p>

                        <p>We will not sell or market directly to minors and always seek parental or legal guardian’s authorization whenever we identify that a minor attempts to purchase a Great Leaps product or service or provide Personally Identifiable Information to us.</p>

                        <p>A note to kids under 18: Please get permission from your parent/legal guardian before using the Great Leaps website. If you wish to subscribe to any of the Great Leaps website, your parent/legal guardian needs to complete the subscription for you using their email address and information.</p>

                        <p>The personal information that we collect depends on the context of your interactions with us and the Sites, the choices you make and the products and features you use. The personal information we collect can include the following:</p>

                        <p><strong>Name and Contact Data.</strong> We collect your first and last name, email address, postal address, phone number, and other similar contact data.</p>

                        <p><strong>Credentials.</strong> We collect passwords, and similar security information used for authentication and account access.</p>

                        <p><strong>Payment Data.</strong> We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor and you should review its privacy policies and contact the payment processor directly to respond to your questions.</p>

                        <p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>
                    </div>

                    <div>
                        <h3>Information automatically collected </h3>

                        <p><strong>In Short:</strong> <em>Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Sites.</em></p>

                        <p>For the purpose of analytics and the operation and improvement of our website, we collect the IP addresses of users.</p>

                        <p>We may make use of a standard feature found in browser software, called "cookies", which allows us to track the pages on this site visited by users and to collect other statistical information. Using cookies helps us to improve the site for our users and to improve our services. We may further use cookies to monitor potential fraud and abuse of the accounts or use which is not in accordance with the Terms of Use.</p>

                        <p>It is your choice whether to accept or reject cookies and you can set your browser to reject or delete greatleaps.com cookies. However, if you choose to reject cookies or delete them, it may significantly limit some of the functionalities available or affect your experience with the services.</p>

                        <p>We may collect device-specific information about your browser, operating system or other technical details of your usage to improve your experience with our services. We may further use this collected Non-Personal Information about usage patterns, time of usage and form of usage, to improve the offering of our services to users.</p>
                    </div>

                    <div>
                        <h3>Student Data</h3>

                        <p><strong>In Short:</strong> <em>Very limited student data is collected and will not be shared without parent consent.</em></p>

                        <p>With the Great Leaps Reading Program, only a very limited amount of data is entered about students and students only need to read from the computer or tablet screen as a part of our services. Teacher username holders will input the student name and/or ID number, and the student’s month and year of birth before the program is first begun. Later as the program is being used, words and errors per minute as well as the date and story are recorded by the person with the teacher username in order to chart progress. Students are also grouped by their respective teacher account whether that is a teacher, tutor, parent, or paraprofessional. This data simply allows us to provide our services and for teachers, tutors, and parents to be able to evaluate progress.</p>
                    </div>

                    <div>
                        <h3>Information collected from other sources</h3>

                        <p><strong>In Short:</strong> <em>We may collect limited data from public databases, marketing partners, and other outside sources.</em></p>

                        <p>We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links).</p>
                    </div>
                </div>

                <div>
                    <h2 className="section-header">2. HOW DO WE USE YOUR INFORMATION?</h2>

                    <p><strong>In Short:</strong> <em>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></p>

                    <p>We use personal information collected via our Sites for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>

                    <p>We use the information we collect or receive:</p>

                    <ul>
                        <li><strong>To facilitate account creation and logon process.</strong> If you choose to link your account with us to a third party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process.</li>

                        <li><strong>To send you marketing and promotional communications.</strong> We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time</li>

                        <li><strong>To send administrative information to you.</strong> We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</li>

                        <li><strong>Fulfill and manage your orders.</strong> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Sites.</li>

                        <li><strong>Deliver targeted advertising to you.</strong> We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>

                        <li><strong>To protect our Sites.</strong> We may use your information as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring and prevention).</li>

                        <li><strong>For other Business Purposes.</strong> We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve ourSites, products, services, marketing and your experience.</li>
                    </ul>
                </div>

                <div>
                    <h2 className="section-header">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>

                    <p><strong>In Short:</strong> <em>We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.</em></p>

                    <p>We may process or share data based on the following legal basis:</p>

                    <ul>
                        <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>

                        <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>

                        <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>

                        <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>

                        <li><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                    </ul>

                    <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>

                    <ul>
                        <li><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Sites, which will enable them to collect data about how you interact with the Sites over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</li>

                        <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    </ul>
                </div>

                <div>
                    <h2 className="section-header">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h2>

                    <p><strong>In Short:</strong> <em>We only share information with the following third parties.</em></p>

                    <p>We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.</p>

                    <ul>
                        <li><strong>Advertising, Direct Marketing, and Lead Generation:</strong> Monday</li>
                        <li><strong>Communicate and Chat with Users:</strong> MailChimp</li>
                        <li><strong>Web and Mobile Analytics:</strong> Facebook Analytics and Google Analytics</li>
                    </ul>
                    <div>
                        <h3>Access to Data</h3>

                        <p>Subscribers have all of their student performance data available to them. This allows educators to make their own assessments of progress, and provide any student education records to parents without difficulty, satisfying FERPA requirements. Furthermore the recording of data is flexible so that input errors can be corrected and data can be deleted.</p>

                        <p>We will satisfy requests to permanently delete student names/ID numbers from our records, though they are already safely encrypted automatically.</p>
                    </div>

                    <h3>Use of Subcontractors</h3>

                    <p>We may use one or more subcontractors to assist in performing services where there is some level of access to user data. All such subcontractors are subject to this Policy and must also abide by the same security practices outlined herein. We agree to share the names of these subcontractors with the user upon request.</p>

                    <h3>Transfer of Personally Identifiable Information in Business Transaction</h3>

                    <p>If we, in the future, will go through business transition such as a merger, acquisition by another company, or sale of all or a portion of its assets, your Personally Identifiable Information may be among the assets transferred. You expressly acknowledge that such transfers may occur, and that any acquirer of Diarmuid or its assets may continue to use your Personally Identifiable Information only as set forth in this Privacy Policy and with all security measures in place upon transfer. You hereby consent to such use if done according to this Privacy Policy.</p>

                </div>

                <div>
                    <h2 className="section-header">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

                    <p><strong>In Short:</strong> <em>We may use cookies and other tracking technologies to collect and store your information.</em></p>

                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>
                </div>

                <div>
                    <h2 className="section-header">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>

                    <p><strong>In Short:</strong> <em>We may transfer, store, and process your information in countries other than your own.</em></p>

                    <p>Our servers are located in United States. If you are accessing our Sites from outside United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information), in United States, and other countries.</p>

                    <p>If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.</p>

                    <p>EU-U.S. Privacy Shield Framework:</p>

                    <p>In particular Company complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union to the United States and has certified its compliance with it. As such, Company is committed to subjecting all personal information received from European Union (EU) member countries, in reliance on the Privacy Shield Framework, to the Framework’s applicable Principles. To learn more about the Privacy Shield Framework, visit the U.S. Department of Commerce’s Privacy Shield List.</p>

                    <p>Company is responsible for the processing of personal information it receives, under the Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf.</p>

                    <p>With respect to personal information received or transferred pursuant to the Privacy Shield Framework, Company is subject to the regulatory enforcement powers of the U.S. FTC. In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
                </div>

                <div>
                    <h2 className="section-header">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

                    <p><strong>In Short:</strong> <em>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</em></p>

                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>

                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                </div>

                <div>
                    <h2 className="section-header">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

                    <p><strong>In Short:</strong> <em>We aim to protect your personal information through a system of organizational and technical security measures.</em></p>

                    <p>When a user enters sensitive information (such as credit card numbers, user name, and password) on our registration or order forms, we encrypt that information using encryption techniques common in e-commerce, such as secure socket layer technology (SSL). We follow best practice industry standards to protect the personal information submitted to us, both during transmission and while maintaining this information. Access to the data is protected by both physical and technical safeguards, and we agree to promptly notify the subscriber if any breach of their information occurred. We have a written response plan for any breaches in security and will share this plan with the user upon request.</p>
                </div>

                <div>
                    <h2 className="section-header">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

                    <p><strong>In Short:</strong> <em>In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></p>

                    <p>In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details  provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>

                    <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</p>

                    <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their <a href="http://ec.europa.eu/justice/data-protection/bodies/api/authorities/index_en.htm">contact details here.</a></p>

                    <h3>Account Information</h3>

                    <p><strong>If you would at any time like to review or change the information in your account or terminate your account contact us using our <a href="https://greatleaps.com/index.php?main_page=contact_us">contact form.</a></strong></p>
                    <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

                    <p><strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Sites.</p>

                    <p><strong>Opting out of email marketing:</strong> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:</p>
                    <ul>
                        <li>Use the "Unsubscribe" link in any email sent by us.</li>
                        <li>Contact us using our <a href="https://greatleaps.com/index.php?main_page=contact_us">contact form</a></li>
                    </ul>

                    <h3>Disclaimer Regarding Linked Sites</h3>

                    <p>Our website may contain links from or to third party sites. We do not partner with any ad server companies and are not responsible for information, content, terms of use or privacy policy at these sites.  When you use a link to go from our website to another website, this Privacy Policy is no longer in effect. By your browsing and interacting on any other website, including websites that have a link on the website, you are subject to that website’s own rules and policies. Please read those rules and policies carefully before proceeding.</p>

                </div>

                <div>
                    <h2 className="section-header">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

                    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
                </div>

                <div>
                    <h2 className="section-header">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                    <p><strong>In Short:</strong> <em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></p>

                    <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>

                    <p>If you are under 18 years of age, reside in California, and have a registered account with the Sites, you have the right to request removal of unwanted data that you publicly post on Great Leaps Digital. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on Great Leaps Digital, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>

                </div>

                <div>
                    <h2 className="section-header">12. DO WE MAKE UPDATES TO THIS POLICY?</h2>

                    <p><strong>In Short:</strong> <em>Yes, we will update this policy as necessary to stay compliant with relevant laws.</em></p>

                    <p>The information you provide to us is controlled by the privacy policy in effect at the time you provide the information. With the introduction of new products, features, or laws and regulations, changes to this policy may be required from time to time. We reserve the right to amend this privacy policy at any time as deemed necessary; however, we will not reduce your rights under this privacy policy without notifying and obtaining your prior consent. We urge you to examine this policy for changes when using this site. If you do not agree to the current policy, please refrain from using the content or leave the Great Leaps website.</p>
                </div>

                <div>
                    <h2 className="section-header">13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>

                    <p>If you have questions or comments about this policy, you may contact us from our <a href="https://greatleaps.com/index.php?main_page=contact_us">contact form</a> or by post to:</p>


                    <address className="text-center">
                        <p>Diarmuid, Inc<br />P.O. Box 357580<br /> Gainesville, Florida 32635<br />United States of America</p>
                    </address>
                </div>
            </div>

        </Container>
    )
}

export default PrivacyPolicy;
