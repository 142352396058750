import React from 'react';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import UseResearch from './UseResearch'
import SupportResearch from './SupportResearch'

function Research() {

    return (<>
        <link rel="stylesheet" type="text/css" href="/styles/about.css?version=8-28-2021" />

        <div className="about-title-image text-center">
            <h1 className="about-title-main h2 mt-5">Research & Evidence</h1>
            <div className="row container justify-content-center mx-auto mt-5">
                <Col lg={4} className="my-2">
                    <a href="#usingGL">
                        <Button variant="success" className="w-100">Research Using GL</Button>
                    </a>
                </Col>
                <Col lg={4} className="my-2">
                    <a href="#supportingGL">
                        <Button variant="success" className="w-100">Research Supporting GL</Button>
                    </a>
                </Col>
            </div>
        </div>
        <Container id="research" fluid="lg">
            <span className="anchor" id="usingGL"></span>
            <h2 className="h3 font-weight-bold mt-3">Research Using Great Leaps</h2>
            <UseResearch/>
            <span className="anchor" id="supportingGL"></span>
            <h2 className="h3 font-weight-bold">Research & Materials Supporting Great Leaps</h2>
            <SupportResearch/>
        </Container>
    </>)

}

export default Research;
