import React from 'react'
import { initializeFormValidation } from '../../../../helpers/forms/form-validation'
import customRequest from '../../../../helpers/customRequest'
import redirect from '../../../../components/redirect/redirect'
import message from '../../../../components/message/message'
import ClipLoader from 'react-spinners/ClipLoader'
import getURLParameters from '../../../../helpers/getURLParams'

class Remove extends React.Component
{
    state = 
    {
        ownerUsername: null,
        studentID : null,
        studentName : null,
        submitting: false
    }
    componentDidMount() {
        var params = getURLParameters(this.props)
        this.setState({studentID: params.id, studentName: params.name, ownerUsername: params.username})

        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({ submitting: true }, () => {
            customRequest.delete('/api/students/share', {data: {
                studentID: this.state.studentID
            }})
            .then(({data: response}) => {
                redirect.send('/students', this.props, () => {
                    if (response.valid) {
                        message.success('You have successfully removed your permissions on the student shared to you.')
                    } else {
                        message.success(response.message)
                    }
                })
            })
        })
    }

    render()
    {
        var { studentName, ownerUsername } = this.state
        return (
        <React.Fragment>
            <h1 className='text-center'>Remove Shared Student</h1>
            <form className="form-horizontal prevent-next-submit form-validate" noValidate method = "post">
                <div className="mb-3 row">
                    <label className="d-none d-sm-inline col-sm-6 control-label text-end">Sharing User</label>
                    
                    <div className="col-sm-6">
                        <p className="form-control-static text-start text-center-xs break-words">{ownerUsername}</p>
                    </div>
                </div>
                
                <div className="mb-3 row">
                    <label className="d-none d-sm-inline col-sm-6 control-label text-end">Student Name</label>
                    
                    <div className="col-sm-6">
                        <p className="form-control-static text-start text-center-xs break-words">{studentName}</p>
                    </div>
                </div>
                
                <button type = "submit" disabled={this.state.submitting} className="btn btn-secondary btn-lg">
                {this.state.submitting ?
                    <ClipLoader
                        size={20}
                        color={"#123abc"}
                        loading={this.state.submitting}
                        /> : "Remove"
                }</button>
            </form>
        </React.Fragment>)
    }
}

export default Remove
