import React, { useContext, useEffect } from 'react';
import { Route } from "react-router-dom";
import redirect from '../components/redirect/redirect';
import message from '../components/message/message';
import Common from '../context/Common'
import Spinner from 'react-bootstrap/Spinner'

function Authenticate({component: Component, checkAuth = true, type, redirect: redirectLocation, message: messageAfter, props, updatePermissions, ...rest})
{
    const common = useContext(Common)
    let auth = common?.auth
    
    useEffect(()=> {
        if (checkAuth && auth != null && !auth.includes(type)) {
            redirect.send(redirectLocation, rest, () =>{
                message.error(messageAfter)
            })
        }
    },[auth,checkAuth,messageAfter,type,redirectLocation,rest])

    return (
        <Route
            {...rest}
            render={(additionalProps) => {
                return (
                    <Component updatePermissions={updatePermissions} auth={auth} {...additionalProps} {...props} />
                )
            }
            }
        />
    )
}


class CustomerRouteSkeleton extends React.Component
{
    static contextType = Common
    render()
    {
        var { component: Component, auth, props, updatePermissions, ...rest} = this.props

        return (
            <Authenticate component={Component}
                          type='customer'
                          redirect='/account/log-in/teacher'
                          message='You must login into your teacher account to access that page.'
                          props={props}
                          updatePermissions={updatePermissions}
                          {...rest} />
        )
    }
}
class ManagerRouteSkeleton extends React.Component
{
    static contextType = Common
    render() {
        const { currentOrg } = this.props
        if(!currentOrg) return <Spinner animation="border" variant="primary" className="m-auto" style={{ width: "5rem", height: "5rem" }}/>

        // user will be redirected if 1. not in org 2. org is personal 3. is an instructor
        if((currentOrg.personal || ( currentOrg.permissions === 'Instructor'))) {
            redirect.send("/dashboard", this.props.rest, () => message.error("You must be manager to access this page."))
        }
        return (
            <CustomerRouteSkeleton {...this.props} />
        )
    }
}

class AdminRouteSkeleton extends React.Component
{
    static contextType = Common
    render()
    {
        var { component: Component, auth, props, updatePermissions, ...rest} = this.props

        return (
            <Authenticate component={Component}
                          type='admin'
                          redirect='/dashboard'
                          message='You must be an Administrator to access that page.'
                          props={props}
                          updatePermissions={updatePermissions}
                          {...rest} />
        )
    }
}

class GuestRouteSkeleton extends React.Component
{
    render()
    {
        var { component: Component, auth, props, updatePermissions, ...rest} = this.props

        return (
            <Authenticate component={Component}
                          type='guest'
                          checkAuth={false}
                          redirect='/dashboard'
                          message='You are already logged in'
                          props={props}
                          updatePermissions={updatePermissions}
                          {...rest} />
        )
    }
}

class StudentRouteSkeleton extends React.Component
{
    render()
    {
        var { component: Component, auth, ...rest} = this.props
        return (
            <Authenticate component={Component}
                          type='student'
                          redirect='/account/log-in/student'
                          message='You must login into your student account to access that page.'
                          {...rest} />
        )
    }
}


export {
    CustomerRouteSkeleton,
    ManagerRouteSkeleton,
    GuestRouteSkeleton,
    StudentRouteSkeleton,
    AdminRouteSkeleton
}
