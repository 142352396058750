import React, { useRef } from 'react'
import { Container } from 'react-bootstrap';
import NavbarController from './nav/NavbarController'
import Footer from './Footer'

function PrivacyBanner({ auth }: {auth: string[]}) {

    const banner = useRef(null);

    if (auth && !auth.includes("guest")) {
        return <React.Fragment></React.Fragment>
    }

    if (localStorage.getItem('seen-cookies-banner')) {
        return <React.Fragment></React.Fragment>
    }

    return <div ref={banner} className="privacy-banner">
        <div className="banner-wrapper">
            <p>We use cookies to provide and improve our services. By using our site, you consent to cookies. <a href="/legal/privacy-policy">Learn more</a></p>
            <button onClick={() => {
                banner.current.style.display = "none"
                localStorage.setItem('seen-cookies-banner', "true")
            }} aria-label="Close" type="button"><i className="fas fa-times" style={{ fontSize: '15px' }}></i></button>
        </div>
    </div>
}

type Template = {
    auth: string[] | null
    currentOrg: any
    updatePermissions: () => any
    switchOrg: () => any 
    children: React.ReactNode
}
function Template({ auth, currentOrg, updatePermissions, switchOrg, children }: Template) {
    return (<>
        <NavbarController auth={auth} currentOrg={currentOrg} updatePermissions={updatePermissions} switchOrg={switchOrg} />
        <Container id="page" fluid={true} className={'d-flex flex-column g-0'}>
            {children}
        </Container>
        <PrivacyBanner auth={auth} />
        <footer>
            <Footer auth={auth} />
        </footer>
    </>)
}

export default Template
