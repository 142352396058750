import React from 'react';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';
import customRequest from '../../../helpers/customRequest';

import ClipLoader from "react-spinners/ClipLoader";
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import getURLParams from '../../../helpers/getURLParams';

class GenerateSalesCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            created: "",
            price: "",

            loading: false
        }
    }

    componentDidMount() {
        initializeFormValidation();

        initializeAutosubmit();
        initializeTextAreaSubmit();
        initializeSubmissionPrevention();

        let params = getURLParams(this.props);

        if (params.name) {
            this.setState({ name: params.name })
        }

        if (params.created) {
            this.setState({ created: params.created })
        }

        if (params.price) {
            this.setState({ price: params.price })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var generateCodeForm = document.getElementById("generate-sales-code-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(generateCodeForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            customRequest.post("" /* todo */, {
                name: this.state.name,
                created: this.state.created,
                price: this.state.price
            })
                .then(({ data }) => {
                    if (!data.valid) {
                        this.setState({ loading: false })
                        return message.error(data.message)
                    }

                    let redirectUrl = "/admin/sales-code/generate/success?name=" + this.state.name + "&created=" +
                        this.state.created + "&price=" + this.state.price + "&code=" + data.code

                    redirect.send(redirectUrl)
                    message.success("Your sales code has been successfully generated, with details listed below.")
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    return message.error("There has been an internal error.")
                })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1>Generate Sales Code</h1>

                <form id="generate-sales-code-form" className="form-horizontal form-validate" noValidate onSubmit={this.submitForm} method="post">
                    <div className="mb-3 row">
                        <label for="name" className="col-sm-3 control-label text-end">Transaction Contact Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="name" name="name" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.name} placeholder="Name" required data-description="enter the transaction's contact name" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="created" className="col-sm-3 control-label text-end">Transaction Created</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="created" name="created" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.created} placeholder="YYYY-MM-DD HH:MM:SS" maxlength="19" required data-description="enter the transaction's creation date and time" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="price" className="col-sm-3 control-label text-end">Price</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>

                                <input type="number" className="form-control" id="price" name="price" min="1" step="1" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.price} placeholder="Price" required data-description="enter the transaction price" />

                                <span className="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">
                        {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Generate"}
                    </button>
                </form>
            </React.Fragment>
        )
    }

}

export default GenerateSalesCode;
