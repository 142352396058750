import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import customRequest from '../../helpers/customRequest'
import getURLParameters from '../../helpers/getURLParams'
import ChartHelp from '../../components/help/ChartHelp'
import redirect from '../../components/redirect/redirect'
import message from '../../components/message/message'
import BeatLoader from 'react-spinners/BeatLoader'
import buildQuery from '../../helpers/buildQuery'
import { ReadingSubjects, StoryLevels } from '../../models/reading'
import { MathSubjects } from '../../models/math'
import Skeleton from 'react-loading-skeleton'
import * as studentModel from "../../shared/students"
import * as recordsModel from '../../models/records'
import StudentRoster from '../../components/StudentRoster'
import ExerciseRecords from '../../pages/records/components/ExerciseRecords'
import Chart, { ChartType } from './components/Chart'
import { isStoryType } from '../../models/probeList'
import RecordsRoster from './components/RecordsRoster'

export const RecordContext = React.createContext<{
    type: ReadingSubjects | StoryLevels | MathSubjects,
    program: studentModel.ProgramsType,
    studentID: string
    studentName: string
    customerUsername: string
    adjacent: {
        next: any
        prev: any
    }, //adjacent students
    donutData: any
    suggestedPhonicsType: "phonics" | "alphabet"
    loading: boolean,
    closeRoster: boolean,
    buttonsDisabled: boolean,
    currentChart: ChartType,
    updateChartType: (type: ChartType) => any,
    chartData: recordsModel.ChartData,
}>(undefined)


class Records extends React.Component<{
    type: ReadingSubjects | StoryLevels | MathSubjects,
    program: studentModel.ProgramsType,
    location: any
}, {
    studentID: string
    studentName: string
    customerUsername: string
    adjacent: {
        next: any
        prev: any
    }, //adjacent students
    rawRecords: any[],
    donutData: any
    suggestedPhonicsType: "phonics" | "alphabet"
    loading: boolean
    buttonsDisabled: boolean
    closeRoster: boolean,
    currentChart: ChartType,
    chartData: recordsModel.ChartData,
    program: studentModel.ProgramsType,
    type: ReadingSubjects | StoryLevels | MathSubjects,
}>
{
    /*********************
    * Global Variables
    *********************/
    state = {
        studentID: null,
        studentName: "",
        customerUsername: "",
        adjacent: {
            next: null,
            prev: null
        }, //adjacent students
        rawRecords: [],
        donutData: {},
        suggestedPhonicsType: "phonics" as "phonics" | "alphabet",
        loading: true,
        buttonsDisabled: true,
        donutsLoading: true,
        donutsRefresh: true,
        closeRoster: false,
        currentChart: this.props.location.state?.currentChart || "performanceChart",
        program: this.props.program,
        type: this.props.type,
        chartData: null
    }

    updateRecords = async () => {
        await new Promise((resolve, reject) => {
            this.setState({loading: true}, async () => {
                const params = getURLParameters(this.props)
                const getRecords = recordsModel.get(params.id, this.props.type)
                const getDonuts = customRequest.get("/api/donuts", {
                    params: {
                        studentID: params.id,
                    }
                })
                const [chartData, {data: student}, { data: donutData }] = await Promise.all([getRecords, studentModel.getOne(params.id), getDonuts])
                if (chartData?.valid === false) {
                    return redirect.send('/students', this.props, () => {
                        message.error(chartData.message ?? "There has been an error getting your student's records.")
                    })
                }
                this.setState({
                    chartData: chartData,
                    studentID: params.id,
                    studentName: student?.name,
                    customerUsername: student?.owner?.username ?? null,
                    loading: false,
                    donutData: donutData,
                }, () => {
                    resolve("")
                })
            })
        })
    }

    toggleRosterClicked = () => {
        this.setState({closeRoster: !this.state.closeRoster})
    }

    setActiveStudent = (studentId, currentChart: ChartType) => {
        const type = this.props.type
        const isStory = isStoryType(type)
        const redirectLink = `/students/records/${isStory ? "stories/" : ["phonics", "alphabet"].includes(type) ? "phonics/" : ""}${type}`
        redirect.send(redirectLink + `?${buildQuery({id: studentId})}`, {type: type, currentChart: currentChart, program: this.props.program})
        setTimeout(() => {
            this.updateRecords() // refreshes page after url changes
        }) // before getting angry at another timeout, blame redirect for not reloading the page
    }

    clickRow = (row, currentChart: ChartType) => {
        this.setActiveStudent(row.original.id, currentChart)
    }

    /****************
    * React Functions
    *****************/
    componentDidMount() {
        this.updateRecords()
    }
    
    render() {
        const { studentID, studentName, customerUsername, loading } = this.state
        const { type, program } = this.props

        return (
        <Container fluid="xl">
            <Row className="text-center mt-3 pb-2">
                <h1 className="break-words h3 fw-bold">{
                studentName ? `
                    ${studentName}${customerUsername ? ` (from ${customerUsername})` : ""}'s
                ` : <Skeleton width={120} />} Records
                <ChartHelp />
                </h1>
            </Row>
            <RecordContext.Provider value={{...this.state, updateChartType: (type) => { this.setState({currentChart: type}) } }}>
                <Row className='flex-row-reverse border rounded ' id="records">
                    <Col lg={this.state.closeRoster ? 11 : 8} className="animate-mid p-3">
                        {loading ? 
                            <div style={{ margin: "0 auto", display: "table", height: "50vh"}}>
                                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                                    <BeatLoader
                                        size={15}
                                        color={"#123abc"}
                                        loading={true}
                                        />
                                </div>
                            </div>
                            : <>
                                <Chart setActiveStudent={this.setActiveStudent} />
                                {this.state.currentChart !== "studentSummary" && <>
                                    <hr />
                                    <div className="text-center mx-n3 mt-4" id="raw-records">
                                        <ExerciseRecords studentId={studentID} probeType={type} program={program} />
                                    </div>
                                </>}
                            </>
                        }            
                    </Col>
                    <Col lg={this.state.closeRoster ? 1 : 4} className={`dont-print d-flex g-0 animate-mid ${this.state.closeRoster && "closed"} `} id="records-student-roster">
                        <div className='w-100 react-table-col position-relative pt-3'>
                            <StudentRoster clickRow={this.clickRow} Roster={RecordsRoster}/>
                        </div>
                        <button onClick={this.toggleRosterClicked} className='btn btn-secondary rounded-0 fw-bold px-1'>
                            {this.state.closeRoster ? ">" : <i className='fas fa-chevron-left'/>}
                        </button>
                    </Col>
                </Row>
            </RecordContext.Provider>
        </Container>
        )
    }
}

export default Records
