import Message from './message';
import Input from '../input';

function SuccessMessage(text)
{
	if (text == null)
	{
		text = "";
	}
	
	this.parent.constructor.call(this, Input.success, text);
}

SuccessMessage.prototype = new Message();
SuccessMessage.prototype.parent = Message.prototype;
SuccessMessage.prototype.constructor = SuccessMessage;

export default SuccessMessage;
