import React from 'react';
import { Link } from 'react-router-dom';

import message from '../../../components/message/message';
import getURLParameters from '../../../helpers/getURLParams';
import redirect from '../../../components/redirect/redirect';

class CreateTransactionSuccess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            created: "",
            price: "",

            valid: true
        }
    }

    componentDidMount() {
        let params = getURLParameters(this.props);

        if (!params.name || !params.created || !params.price) {
            this.setState({ valid: false });
            message.error("You seem to have navigated to this page accidentally. If you're trying to access this page, please proceed back to the administration page using the button below..")
            return;
        }

        this.setState({
            name: params.name,
            created: params.created,
            price: params.price
        })
    }

    sendToActivationCodes = () => {
        redirect.send("/admin/activation-codes/generate?name=" + this.state.name + "&created=" + this.state.created)
    }

    sendToSalesCode = () => {
        redirect.send("/admin/sales-code/generate?name=" + this.state.name + "&created=" + this.state.created + "&price=" + this.state.price)
    }

    render() {

        if (!this.state.valid) {
            return (
                <React.Fragment>
                    <div className="redirect-container">
                        <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                            <Link to="/admin" className="btn btn-secondary btn-lg">Return to Administration Tools</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="alert alert-info">
                    <h4>Details:</h4>

                    <p><label>Contact Name:</label> {this.state.name}</p>
                    <p><label>Created Timestamp:</label> {this.state.created}</p>
                </div>

                <div className="alert alert-info">
                    <p>If you want to generate subscription activation codes, you can click the button below to have the information from this page pre-filled.</p>

                    <button type="submit" className="btn btn-secondary btn-lg" onClick={this.sendToActivationCodes}>Generate Activation Codes</button>
                </div>

                <div className="alert alert-info">
                    <p>If you want to generate a sales code, you can click the button below to have the information from this page pre-filled.</p>

                    <button type="submit" className="btn btn-secondary btn-lg" onClick={this.sendToSalesCode}>Generate Sales Code</button>
                </div>

                <div className="row">
                    <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                        <Link to="/admin/create-transaction" className="btn btn-secondary btn-lg">Return to Transaction Creation</Link>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

export default CreateTransactionSuccess;
