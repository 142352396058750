import React from 'react';
import Container from 'react-bootstrap/Container'

function TermsOfUse() {
    
    return (
        <Container>
            <h1 className="section-header mt-4">Terms of Use</h1>

            <div className="text-justify legal-block">
                <div>
                    <p><strong>Important – Read Carefully:</strong> This End-User License Agreement ("EULA") is a legal contract between you (either (a) an individual user (e.g., teacher, student) or (b) a business organization (e.g., educational institution) (and in either case "You")) and Diarmuid Inc. ("Licensor") (collectively the "Parties") for all versions of Licensor’s Great Leaps software, whether a pre-release version ("Beta Version"), trial version ("Trial Version"), or a production release version ("Commercial Version"), including any associated media, printed materials, accompanying instructions, and electronic documentation, provided to you by Licensor through any means of delivery, including physical, electronic, and wireless (collectively the "Software").</p>

                    <p>By accessing or otherwise using the Software, you agree to be bound by the terms of this EULA and you represent that you are authorized to enter into this EULA on behalf of your corporate entity (if applicable). If you do not wish to be bound by the terms of this EULA, do not access or otherwise use the Software.</p>

                    <p>Licensor reserves the right to suspend, discontinue, delete, modify, or remove any content or functionality offered in our Great Leaps website from time to time, without prior notice, without reason or liability, and/or to offer certain content or functionality only in various versions or in selected times, based on Licensor’s sole discretion. In addition, Licensor may charge users for certain content or functionality that is currently available for free.</p>

                    <p><strong>By using the software, you acknowledge that you have read this EULA, understand it, and agree to be bound by its terms and conditions.</strong></p>

                    <p>If you are under the age of 18, you may use the Great Leaps website only with authorization and/or supervision of a parent or legal guardian. If you are an educator, and you are using the Great Leaps website on behalf of minors, you are required to obtain the authorization of their parents or legal guardian.</p>

                    <p>The Software is protected by copyright laws and international treaty provisions, as well as other intellectual property laws and treaties. The Software is only licensed, not sold.</p>
                </div>

                <div>
                    <h3 className="section-header">Grant of License</h3>

                    <p>Subject to your compliance with the terms of this EULA, Licensor grants you a non-exclusive, non-sublicensable, non-transferable, revocable, limited license (the "License") enabling You to use the Software, including all the images, photos, animations, audio-video components, music, text and "applets" incorporated into the Software, as well as the accompanying printed materials and all of the Software copies solely as set forth below. All conditions stated below apply both to the Software as a whole and to all of its separate components. The Great Leaps website or any portion thereof may not be reproduced, distributed, duplicated, republished, copied, sold, resold or otherwise exploited without our express written consent. You are granted a right to reproduce, duplicate and/or copy any documents provided in portable document format (.pdf) provided all reproductions, duplicates and/or copies contain copyright notice(s) contained on the original materials.</p>
                </div>

                <div>
                    <h3 className="section-header">Restrictions on Use of Software</h3>

                    <p>Title to Software and all associated intellectual property rights is retained by Licensor. You may not (a) rent, lease, or make the Software available for use by others in any service bureau, hosted services, time sharing facility, other computer services, or similar arrangement; (b) resell, sublicense, lend, grant a security interest in, or otherwise distribute the Software to any third party; (c) transfer the Software to any third party unless you have received Licensor’s prior written consent, which Licensor may withhold in its discretion; (d) disassemble or reverse engineer the Software (except to the extent that this prohibition is expressly prohibited by law); (e) modify the Software, including modifications for use on hardware or operating systems not supported or compatible with the Software; (f) create derivative works of the Software; or (g) copy the Software. Unauthorized copying and modification of the Software is not permitted. You acknowledge that the Software is developed for general use in a variety of information management applications; it is not developed or intended for use in any inherently dangerous applications, including applications that may create a risk of personal injury. If you use the Software in dangerous applications, then you shall be responsible to take all appropriate fail-safe, backup, redundancy, and other measures to ensure its safe use. Licensor disclaims any express or implied warranty of fitness for such uses.</p>
                </div>

                <div>
                    <h3 className="section-header">Ownership</h3>

                    <p>This EULA does not convey to you any rights of ownership in the Software. All worldwide rights, title, and interest in the Software and in any ideas, know-how, and programs which are developed by Licensor in the course of providing any technical services, and in any enhancements or modifications made to the Software, including without limitation, patents, copyrights, trademarks, trade secrets, images, photographs, animations, video, audio, music, text, and "applets," and all other proprietary rights of any kind, as may exist now or hereafter come into existence, in and to the Software shall at all times remain the property of Licensor and/or its suppliers, and you will not acquire any interest therein, except as expressly provided in this EULA.</p>
                </div>

                <div>
                    <h3 className="section-header">Intellectual Property</h3>

                    <p>Subject to the provisions of these Terms of Use, our Great Leaps website and the Content are owned by or licensed to Licensor, subject to copyright, trademark and other intellectual property rights under United States and foreign laws and international conventions. Licensor reserves all rights to the Content. You agree not to engage in the use, copying, or distribution of any Content unless you are expressly permitted to do so by a written agreement with us. You agree not to circumvent, disable or otherwise interfere with security related features of the Great Leaps website or features that prevent or restrict use or copying of any Content or enforce limitations on the use of the Great Leaps website or the Content. All product, brand and company names and logos used on our Great Leaps website are the trademarks or registered trademarks (including, but not limited to the trademark "Great Leaps") of their respective owners. Any use of any of the marks appearing on the Great Leaps website, whether or not owned and/or operated by Licensor (unless you first receive our express written consent or the consent of the owner of the mark, as appropriate), is strictly prohibited. You may not use metatags or any other "hidden text" utilizing "Great Leaps" or any other name, trademark, service mark or product or service name of Licensor without our prior written permission. In addition, the look and feel of the Great Leaps website, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Licensor and may not be copied, imitated or used, in whole or in part, without our prior written permission. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.</p>
                </div>

                <div>
                    <h3 className="section-header">Copyright and Trademark Notices</h3>

                    <p>Software is copyrighted. You may not remove, modify, or alter any Licensor copyright or trademark notice from any part of the Software, including but not limited to any such notices contained in the physical and/or electronic media or documentation. No right, title or interest in or to any trademark, service mark, logo or trade name of Licensor is granted under this Agreement.</p>
                </div>

                <div>
                    <h3 className="section-header">Termination</h3>

                    <p>Your license will automatically terminate if (a) you fail to complete payment, (b) you fail to comply with any material provision of this EULA, and/or (c) your subscription period expires without renewal. Upon the effective date of any termination, you relinquish all rights granted under this EULA.</p>
                </div>

                <div>
                    <h3 className="section-header">User Account</h3>

                    <p>Certain content and functionalities in our Great Leaps website require registration in order to be able to enjoy such content and functionalities. Licensor reserves the right to decide which content and functionalities require registration or will require registration in the future. If you wish to register to use such content and functionalities in our Great Leaps website, you must continue to abide by these Terms of Use. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. Please note: Licensor is in the business of producing and selling educational content and products designed for children. However, Licensor sells them to adults, who, among other things, can legally enter into credit card transactions or other permitted payment methods and agreements. If you are under 18, you may use the Great Leaps website only with authorization and supervision of a parent or legal guardian. Licensor reserves the right to refuse service, terminate accounts, remove or edit the Content, or cancel orders in its sole discretion.</p>
                </div>

                <div>
                    <h3 className="section-header">U.S. Government Restricted Rights</h3>

                    <p>The Software is provided as "commercial computer software" or "restricted computer software". Use, duplication, or disclosure by the U.S. Government or a U.S. Government subcontractor is subject to the restrictions set forth in 48 CFR 12.212 or 48 CFR 227.7202, as applicable, or successor provisions. The manufacturer is STEM Sims, LLC., 408 West University Avenue, Suite 306, Gainesville, FL 32601, USA.</p>
                </div>

                <div>
                    <h3 className="section-header">Disclaimer of Warranty</h3>

                    <p><strong>The Software is provided "as is" without warranty of any kind. To the full extent permissible by applicable law, Licensor disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. Licensor does not warrant that the Great Leaps website, the content, information, materials, products or services included on or otherwise made available through the Great Leaps website, their servers, or E-mail sent from Licensor are free of viruses or other harmful components. In no event shall Licensor or any of its affiliates or customers, suppliers or their respective members, directors, officers, employees, shareholders, partners or agents be liable for any incidental, indirect, punitive, exemplary, or consequential damages whatsoever (including, without limitation, damages for loss of profits, business interruption, loss of business information, or any other pecuniary loss) arising from the use of the Great Leaps website or from the content, information, materials, products or services included on or otherwise made available through the Great Leaps website, whether the damages arise from use or misuse or reliance or, inability to use the Great Leaps website or any of the content, information, materials, products or services included on or otherwise made available through the Great Leaps website, or whether they arise from the interruption, suspension, modification, alteration, or termination of any of Great Leaps website or any content, information, materials, products or services included on or otherwise made available through the Great Leaps website.</strong></p>
                </div>

                <div>
                    <h3 className="section-header">Indemnification</h3>

                    <p>You agree to indemnify, defend, and hold harmless Licensor, and their divisions, subsidiaries, affiliates, successors, officers, directors, and employees, and any third-party providers or sources of the Software, from and against any losses, damages, fines, liabilities, and expenses (including attorneys’ fees and costs) (a) to the extent you have encoded, compressed, copied, or transmitted any software comprising the Software in violation of another party’s rights or in violation of any law, and (b) arising from or related to the import, export, or re-export of the Software by you.</p>
                </div>

                <div>
                    <h3 className="section-header">Limitation of Liability</h3>

                    <p><strong>In no event shall Licensor be liable for any indirect, incidental, special, punitive or consequential damages, or damages for loss of profits, revenue, data or data use, incurred by you or any third party, whether in an action in contract or tort, even if licensor has been advised of the possibility of such damages. Licensor’s entire liability for damages hereunder shall in no event exceed the amount of license fees that you have paid.</strong></p>
                </div>

                <div>
                    <h3 className="section-header">General</h3>

                    <p>This EULA shall bind and inure to the benefit of Licensor and its successors and assigns.</p>

                    <p>Neither party is authorized to act for, or on the behalf of, the other party under this EULA. Each party is an independent contractor, and no employee, principal, agent, legal representative, or partnership relationship is created between them by this EULA.</p>

                    <p>No failure or delay by either party to enforce or take advantage of any provision or right under this EULA shall constitute a subsequent waiver of that provision or right, nor shall it be deemed to be a waiver of any of the other terms and conditions of this EULA.</p>

                    <p>The validity, performance, and construction of this EULA or any claim relating to the Software shall be governed by the laws of the State of Florida, USA, but excluding its conflicts of laws principles. This EULA is not subject to the United Nations Convention on Contracts for the Sale of Goods. Venue for any legal action arising out of, or relating to this EULA or the Software, shall be in Alachua County, Florida.</p>

                    <p>Unless otherwise stated herein, amounts stated as payable under this EULA do not include customs duties or sales, use, excise, GST, VAT, or other similar taxes payable hereunder, and the same shall be added to such amounts. If Licensor and/or its distributors is/are required to pay any such taxes in connection with your order, other than taxes based on Licensor’s and/or its distributor’s income, such taxes will be billed to and paid by you.</p>

                    <p>All monies payable hereunder shall be payable in United States Dollars and are non-refundable. Fees are due no later than 30 days after the date of the invoice.</p>

                    <p>All correspondence hereunder shall be in English.</p>

                    <p>Licensor and/or its distributors may include your company name in a list of Licensor and/or distributor customers.</p>

                    <p>You will fully comply with all relevant export laws and regulations, including but not limited to the U.S. Export Administration Regulations and Executive Orders ("Export Controls") and other applicable laws and regulations. You represent and warrant that you are not a person, company, or destination restricted or prohibited by Export Controls ("Restricted Person") and that you are not under control of any Restricted Person. You will not, directly or indirectly, export, re-export, divert, or transfer the Software, any portion thereof or any materials, items, or technology relating to Licensor’s business or related technical data or any direct product thereof to any Restricted Person.</p>

                    <p>All notices or communications to be given under this EULA shall be in writing and shall be deemed delivered upon hand delivery, confirmed facsimile communication, or three (3) days after deposit in the mail of the home country of the party, postage prepaid, by certified or registered first class mail, addressed to the Parties at their addresses set forth above.</p>

                    <p>In the event that any provision of this EULA is prohibited by any law governing its construction, performance, or enforcement, such provision shall be ineffective to the extent of such prohibition without invalidating thereby any of the remaining provisions of this EULA.</p>

                    <p>Captions contained in this EULA are inserted for reference and in no way define, limit, extend, or describe the scope of the EULA or intent of any provision in the EULA.</p>

                    <p>The Parties agree that if you utilize any of the Software in an unauthorized manner, or materially breach the terms and conditions of this EULA, that such use or breach would have a devastating and serious impact on Licensor’s continuing ability to compete profitably and would, therefore, result in immediate and irreparable injury, loss, or damage to Licensor. The Parties agree that in such event, in addition to Licensor’s right to recover damages for a breach of this EULA, Licensor would be entitled to obtain a temporary restraining order or a preliminary injunction from a court of competent jurisdiction to prevent you, your employees, agents, consultants, or independent contractors from engaging in any further use of the Software or the continued material breach of the terms and conditions of this EULA.</p>

                    <p>Except for the obligation to make payments hereunder, non-performance of either party shall be excused to the extent that performance is rendered impossible by strike, fire, flood, governmental action, failure of suppliers, earthquake, hurricane, or any other reason where failure to perform is beyond the reasonable control of the non-performing party. In the event of any such delay, the date of delivery or performance hereunder shall be extended by a period equal to the time lost by reason of such delay.</p>

                    <p>The terms and conditions of this EULA may not be superseded, modified, or amended except in writing which states that it is such a modification, and is signed by an authorized representative of each party hereto.</p>

                    <p>This EULA constitutes the complete and entire understanding and agreement of all terms, conditions, and representation between you and the Licensor as to the subject matter hereof, and supersedes and replaces all prior or contemporaneous agreements, written or oral, regarding such subject matter, and shall take precedence over any additional or conflicting terms which may be contained in either party’s quotations, purchase orders, or order acknowledgment forms.</p>

                    <p>You agree to receive communications from us electronically regarding your account, these Terms of Use and the Great Leaps Website, and such communications sent from us shall be considered sufficient means of notice, whether applicable law requires written notice or not. You further agree that your electronic communications, except for communications regarding subscriptions, are not confidential.</p>
                </div>
            </div>

            <div className="row prefooter-text">
                <div className="col-10 offset-1 col-md-6 offset-md-3">
                    <p>© 2019 Diarmuid, Inc. All rights reserved.</p>

                    <address>
                        <p>P.O. Box 357580<br />Gainesville, Florida 32635<br />United States of America</p>
                    </address>

                    <p>Great Leaps is a trademark and trade name of Diarmuid, Inc. and is protected in the United States of America and other nations.</p>
                </div>
            </div>

        </Container>
    )
}

export default TermsOfUse;