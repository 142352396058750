import React from 'react';
import moment from 'moment';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';
import customRequest from '../../../helpers/customRequest';

import ClipLoader from "react-spinners/ClipLoader";
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import getURLParams from '../../../helpers/getURLParams';

class ForceActivateCodes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            codes: [],
            currentCode: "",

            codeIndex: 0,
            loading: false
        }
    }

    componentDidMount() {
        initializeFormValidation();

        initializeAutosubmit();
        initializeTextAreaSubmit();
        initializeSubmissionPrevention();

        let params = getURLParams(this.props);

        if (params.codes) {
            this.setState({
                codes: params.codes,
                currentCode: params.codes[0]
            })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var generateCodeForm = document.getElementById("force-activate-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(generateCodeForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            customRequest.post("/api/admin/force-activate", {
                username: this.state.username,
                code: this.state.currentCode
            })
                .then(({ data }) => {
                    if (!data.valid) {
                        this.setState({ loading: false })
                        return message.error(data.message)
                    }

                    if (this.state.codes && this.state.codeIndex < this.state.codes.length - 1) {
                        message.success("The customer's subscription has been successfully activated, and they will have access to Great Leaps until " + moment(data.expiration).format('YYYY-MM-DD'))
                        this.setState({
                            codeIndex: this.state.codeIndex + 1
                        }, () => this.setState({
                            currentCode: this.state.codes[this.state.codeIndex],
                            username: "",
                            loading: false
                        }))
                        message.info("The next code to be force-activated has been pre-filled for you below.")
                    } else {
                        redirect.send("/admin/activation-codes/force-activate/success?expiration=" + moment(data.expiration).format('YYYY-MM-DD'))
                        message.success("The customer's subscription has been successfully activated, and they will have access to Great Leaps until " + moment(data.expiration).format('YYYY-MM-DD'))
                    }

                })
                .catch((error) => {
                    this.setState({ loading: false })
                    return message.error("There has been an internal error.")
                })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1 className="text-center section-header">Force-Activate Subscription</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Use this form to force-activate an issued subscription code on a customer's account. Make sure that the username entered is the customer's master username, <strong>not</strong> the username of any of their sub-accounts.</p>

                        <p>If the information on this page has been pre-filled from the activation code generation page, all of your codes from that page have been passed to this page. Each time you submit this form, the next code will be pre-filled on the resulting page. Once all codes have been used, a confirmation page will appear in place of this form.</p>
                    </div>
                </div>

                <form id="force-activate-form" className="form-horizontal form-validate" noValidate method="post" onSubmit={this.submitForm}>
                    <div className="mb-3 row">
                        <label for="name" className="hidden-xs col-sm-3 control-label text-end">Username</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="username" name="username" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.username} placeholder="Username" required data-description="enter the customer's username" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="code" className="hidden-xs col-sm-3 control-label text-end">Code</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="code" name="currentCode" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.currentCode} placeholder="Code" required data-description="enter the activation code" />
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">
                    {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Activate"}
                    </button>
                </form>

            </React.Fragment >
        )
    }

}

export default ForceActivateCodes;
