

import { modelRequest } from '@stem-sims/great-leaps-react-common'

/**
 * Validate a usernames availability
 * 
 * returns a boolean that is true if the username does not exist
 * 
 * @param username 
 */
export function get(username: string) : Promise<boolean> {
    return modelRequest.get('/api/username-available', {
        params:
        {
            username: username
        }
    })
    .then((response) => {
        if (response?.valid === false) return response

        return Boolean(response)
    })
}


/**
 * Check if a student name is available in the organization
 *
 * @returns a boolean that is true if the student name is available
 *
 * @param name the student name to check
 */
export async function studentNameAvailabilityCheck(name: string): Promise<boolean> {
     const response = await modelRequest.get("/api/students/name-available", {
        params: {
            name: name
        }
    })
    if (response?.valid === false) {
        return response
    }
    return response.exists === "false"
}
