import React from 'react'
import ClipLoader from "react-spinners/ClipLoader"
import { Link } from 'react-router-dom'

class Teacher extends React.Component
{
    submit = (event) =>
    {
        event.preventDefault()
        this.props.submit()
    }

    render()
    {
        var { submitting } = this.props
        return (
            <form className="form-horizontal form-validate" noValidate method = "post" action = "/account/log-in/teacher" onSubmit={this.submit}>
                <div className="mb-3 row">
                    <label htmlFor="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>
                    
                    <div className="col-sm-6">
                        <input type = "text" disabled = {submitting} className="form-control" id = "username" name = "username" placeholder = "Username" required data-description = "enter your username" autoFocus />
                    </div>
                </div>
                
                <div className="mb-3 row">
                    <label htmlFor="password" className="d-none d-sm-inline col-sm-3 control-label text-end">Password</label>
                    
                    <div className="col-sm-6">
                        <input type = "password" disabled = {submitting} className="form-control string" id = "password" name = "password" placeholder = "Password" required data-type = "password" minLength="5" data-description = "enter your password" />
                    </div>
                </div>
                
                <button type = "submit" id = "login-submit" disabled = {submitting} className="btn btn-secondary btn-lg">
                    {submitting ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={submitting}
                            /> : "Log In"}</button>
                
                
                <div className="mb-3 row">
                    <p className = "medium-small col-12"><Link to = "/account/recover">Forgot my password</Link></p>
                    
                    <p className = "col-12"><Link to = "/account/sign-up">Need an Account? <strong>Sign up here!</strong></Link></p>
                </div>
            </form>
        )
    }
}

export default Teacher
