import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import graph from '../../../helpers/graph'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { subjectPath } from '../../../components/subject-navigation/SubjectTabs'
import { RecordContext } from '../RecordsPage'
import redirect from '../../../components/redirect/redirect'
import { DateRangePicker } from 'react-dates'
import { useEventListener } from 'usehooks-ts'
import Stats from './Stats'
import Graph from './Graph'

export type ChartType = "performanceChart" | "readingProgress" | "studentSummary"

function Chart({setActiveStudent}) {
    const context = useContext(RecordContext)   
    const history = useHistory()
    const currentChart = context.currentChart
    const [startDate, setStartDate] = useState<string>(moment(context.chartData.startDate)?.format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState<string>(moment(context.chartData.endDate)?.format("YYYY-MM-DD") || moment(new Date()).format("YYYY-MM-DD"))
    const [focused, setFocused] = useState(moment(context.chartData.startDate))

    const StudentProgressLegend = useMemo(() => <div className='mb-3'>
        <div>
            <span className='small'>Number of errors</span>
            <span className="dot red ms-2"></span>
        </div>
        <div>
            <span className='small'>Responses per minute</span>
            <span className="dot blue ms-2"></span>
        </div>
        <div>
            <span className='small'>{context.program === "Reading" ? "Goal" : "Skill Set"} completed</span>
            <span className="goal-legend ms-2"></span>
        </div>
    </div>,[context.program])


    
    const GradeLegend = useMemo(() => <>
        <div className='my-2'>
            <span className='small'>Instructional Grade Level</span>
            <span className="dot blue ms-2"></span>
        </div>
    </>,[])

    const charts = useMemo(() => {
        const initStats = (chartData, program, studentID, type) => {
            graph.initGradeGraph({chartData,studentID,startDate,endDate})
        }
        return {
        "performanceChart": {
            keyLegend: StudentProgressLegend,
            init: graph.initExerciseGraph,
            update: graph.updateExerciseGraph,
            component: <Graph />
        },
        "readingProgress": {
            keyLegend: GradeLegend,
            init: (chartData, program, studentID, type) => graph.initGradeGraph({chartData,studentID,type:type,startDate,endDate}),
            update: graph.updateGradeGraph,
            component: <Graph />
        },
        "studentSummary": {
            keyLegend: GradeLegend,
            init: initStats,
            update: graph.updateGradeGraph,
            component: <Stats />
        },
    }},[GradeLegend, StudentProgressLegend, endDate, startDate])

    useEventListener('resize', () => charts[currentChart].update(startDate,endDate))

    const changeChart = (e) => {
        if(e.target.value === "readingProgress") {
            history.push(subjectPath({studentID: context.studentID, typePath: ("stories/" + context.chartData?.suggestedStory)}), {currentChart: "readingProgress"})
        }
        else if(e.target.value === "studentSummary" && context.program === "Math") {
            changeProgram({target: { value: "Reading"}})
        }
        context.updateChartType(e.target.value as ChartType)
    }
    const changeProgram = (e) => {
        const typePath = e.target.value === "Reading" ? "phonics/alphabet" : "addition"
        redirect.send(subjectPath({studentID: context.studentID, typePath: typePath}),{},()=>null)
    }

    useEffect(() => {
        const el = document.getElementById("svg-chart")
        el?.remove()
        charts[currentChart].init(context.chartData,context.program,context.studentID,context.type)
    },[charts, currentChart, context.chartData, context.program, context.studentID, context.type])

    /**
     * Updating chart anytime data updates
     */
    useEffect(() => {
        setTimeout(() => {
            charts[currentChart].update(startDate,endDate)
        },500) // wait for open/close animation to finish
    },[charts, currentChart, context.closeRoster, startDate, endDate])

    return(<>
        <Row>
            <Col sm={6} md={3} className="gx-2 dont-print">
                <Form.Group className='mb-3'>
                    <Form.Control as="select" onChange={changeChart} name="retention" defaultValue={currentChart}>
                        <option value="performanceChart">Student Performance</option>
                        <option value="studentSummary">Student Summary</option>
                        <option value="readingProgress">Reading Progress</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col sm={6} md={2} className="gx-2 dont-print">
                <Form.Group className='mb-3'>
                    <Form.Control as="select" onChange={changeProgram} name="currentProgram" defaultValue={context.program}>
                        <option value="Reading">Reading</option>
                        <option value="Math">Math</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col className='flex-grow-0 text-center gx-2 dont-print'>
                <button id="printer-button" className="dont-print bg-white">
                    <span className="fas fa-print" />
                </button>
            </Col>
            <Col xs={10} sm={5} md={3} lg={2} xl={3} className='text-center gx-1 mb-2 mb-md-1'>
                <Button onClick={(e) => setActiveStudent(context.chartData.adjacent.prev?.id, context.currentChart)} variant="secondary" className='mx-1'>
                    <i className='fa fa-caret-left me-2 me-lg-1 me-xl-2'></i>
                    <span className='font-md d-none d-xl-inline d-xxl-none'>Prev</span>
                    <span className='font-md d-inline d-lg-none d-xxl-inline-block'>Previous</span>
                </Button>
                <Button onClick={(e) => setActiveStudent(context.chartData.adjacent.next?.id, context.currentChart)} variant="secondary" className='mx-1'>
                    <span className='font-md d-inline d-lg-none d-xl-inline-block'>Next</span>
                    <i className='fa fa-caret-right ms-2 ms-lg-1 ms-xl-2'></i>
                </Button>
            </Col>
            <Col sm={6} md={3} lg={4} xl={3} className='medium-small gx-2'>
                {context.chartData.startDate && currentChart === "readingProgress" && 
                    <DateRangePicker 
                        isOutsideRange={(dateToBePicked) => false /* allow all dates (defaults to true for some reason) */}
                        startDate={moment(startDate)} 
                        endDate={moment(endDate)} 
                        onDatesChange={({ startDate: newStartDate, endDate: newEndDate }) => {
                            if(newStartDate > moment(endDate) && !newEndDate) return setEndDate(newStartDate.format("YYYY-MM-DD")) // if start is selected after end
                            if(newStartDate) setStartDate(newStartDate.format("YYYY-MM-DD"))
                            if(newEndDate) setEndDate(newEndDate.format("YYYY-MM-DD"))
                        }}
                        focusedInput={focused}                    
                        onFocusChange={focusedInput => setFocused(focusedInput)}
                        minDate={moment(context.chartData.startDate)}
                        maxDate={moment(new Date())}
                        small={true}
                        withPortal={true}
                        numberOfMonths={3}
                        keepOpenOnDateSelect={true}
                        calendarInfoPosition="top"
                        startDatePlaceholderText={"Start Date"}
                        endDatePlaceholderText={"End Date"}
                        renderCalendarInfo={() => <div className='d-flex justify-content-between align-items-center mt-2 mx-3'>
                            <label className='lead'>Adjust date range for reading progress</label>
                            <Button>Close</Button> {/* closes modal because its technically out of the date range modal (yes this is the only way)*/}
                        </div> 
                        }
                    />
                }
                {charts[currentChart].keyLegend}
            </Col>
        </Row>
        <>
            {charts[currentChart].component}
        </>
    </>)
}
export default Chart
