import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { MathSubjects } from './math'
import { ReadingSubjects, StoryLevels } from './reading'

interface GetParameters {
    studentID: string,
    type: ReadingSubjects | StoryLevels | MathSubjects
}

export interface ProbeListProbe {
    title: string,
    number: number,
    skillSet: null | number
    skillSetTitle: null | string
}

export interface ProbeListSection {
    title: string,
    number: number
    probes?: ProbeListProbe[]
}

export interface ProbeListSuggestedProbe {
    section: number,
    number: number,
    skillSet: null | number
}

export interface ProbeList {
    studentCode: string
    hasDoneProbe: boolean
    suggestedProbe: ProbeListSuggestedProbe
    sections: ProbeListSection[]
    valid: boolean
    message: string
}
export interface MatchParams {
    probeNumber: string
}

export const HIGH_PROBE = "high"
export const MIDDLE_PROBE = "middle"
export const ELEMENTARY_PROBE = "elementary"

export function isStoryType(type: string) {
    return type === HIGH_PROBE || type === MIDDLE_PROBE || type === ELEMENTARY_PROBE
}

export function get({ studentID, type }: GetParameters): Promise<ProbeList> {
    return modelRequest.get("/api/probe-list", {
        params: {
            studentID: studentID,
            type: type
        }
    })
}
