import React from 'react'
import { Link } from 'react-router-dom'

class AdminHome extends React.Component {

    render() {
        return (
            <React.Fragment>
                <h1 className="text-center section-header">Administration Tools</h1>

                <div className="row">
                    <div className="col-md-6">
                        <Link to="/admin/create-transaction" className="btn btn-secondary btn-xl">Create Transaction</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/activation-codes" className="btn btn-secondary btn-xl">Activation Codes</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/sales-code" className="btn btn-secondary btn-xl disabled">Sales Code</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/send-email" className="btn btn-secondary btn-xl disabled">Send Email</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/discount-code" className="btn btn-secondary btn-xl">Create Discount Code</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/create-account" className="btn btn-secondary btn-xl disabled">Create Account for User</Link>
                    </div>
                    <div className="offset-md-3 col-md-6">
                        <Link to="/admin/force-login" className="btn btn-secondary btn-xl">Force Login</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default AdminHome;
