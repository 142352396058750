import React from 'react';
import { Link } from 'react-router-dom';

class ActivationCodesHome extends React.Component {

    render() {
        return (
            <React.Fragment>
                <h1 className='text-center'>Activation Codes</h1>

                <div className="row">
                    <div className="col-md-6">
                        <Link to="/admin/create-transaction" className="btn btn-secondary btn-xl">Create Transaction</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/activation-codes/generate" className="btn btn-secondary btn-xl">Generate Codes</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/activation-codes/force-activate" className="btn btn-secondary btn-xl">Force-Activate Codes</Link>
                    </div>

                    <div className="col-md-6">
                        <Link to="/admin/activation-codes/send-email" className="btn btn-secondary btn-xl disabled">Send Codes Email</Link>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

export default ActivationCodesHome;
