import EventDispatcher from './events/event-dispatcher';
import TimerEvent from './events/timer-event';

function Timer(delay, repeatCount)
{
	this.delay = delay;
	
	this.repeatCount = repeatCount;
	
	this.interval = undefined;
	
	this.active = false;
	
	this.parent.constructor.call(this);
}


Timer.prototype = new EventDispatcher();
Timer.prototype.parent = EventDispatcher.prototype;
Timer.prototype.constructor = Timer;

Timer.prototype.start = function()
{
	this.active = true;
	this.interval = setInterval(this.fire.bind(this), this.delay);
};


Timer.prototype.stop = function()
{
	this.active = false;
	clearInterval(this.interval);
};

Timer.prototype.fire = function()
{
	var fireEvent = new TimerEvent(TimerEvent.TIMER_FIRE);
	this.dispatchEvent(fireEvent);
	
	this.repeatCount--;
	if (this.repeatCount === 0)
	{
		var completeEvent = new TimerEvent(TimerEvent.TIMER_COMPLETE);
		this.dispatchEvent(completeEvent);
		
		this.stop();
	}
};

export default Timer;
