import EventDispatcher from './events/event-dispatcher';
import Timer from './timer';
import TimerEvent from './events/timer-event';

function RollAnimation()
{
	var node = document.createElement("div");
	node.style.overflow = "hidden";
	node.style.height = "0px";
	node.style.transitionProperty = "height";
	
	node.animationObject = this;
	
	
	var container = this.createContainer();
	node.appendChild(container);
	
	this.node = node;
	this.container = container;
	
	
	this.states = [];
	this.pendingState = null;
	
	
	this.heightBuffer = 0;
}

RollAnimation.prototype = new EventDispatcher();
RollAnimation.prototype.parent = EventDispatcher.prototype;
RollAnimation.prototype.constructor = RollAnimation;

RollAnimation.prototype.addState = function(newState)
{
	var states = this.states;
	var length = states.length;
	if (length === 2)
	{
		if (newState.innerHTML !== states[1].innerHTML)
		{
			this.pendingState = newState;
		}
	}
	
	else
	{
		var container = this.container;
		
		container.appendChild(newState);
		this.states.push(newState);
		
		
		var currentState;
		if (length === 1)
		{
			currentState = states[0];
			
			var switchContainersReference = this.switchContainers.bind(this);
			container.addEventListener("transitionend", switchContainersReference);
			this.switchContainersReference = switchContainersReference;
			
			container.style.top = String(-currentState.clientHeight) + "px";
		}
		
		else
		{
			currentState = newState;
		}
		
		var node = this.node;
		var statePadding = parseFloat(window.getComputedStyle(currentState).paddingBottom);
		
		node.style.height = String(newState.clientHeight - statePadding - this.heightBuffer) + "px";
	}
};

RollAnimation.prototype.createContainer = function()
{
	var container = document.createElement("div");
	container.className = "animation-container";
	
	container.style.position = "relative";
	container.style.top = "0px";
	container.style.transitionProperty = "top";
	
	
	return container;
};

RollAnimation.prototype.switchContainers = function(event)
{
	event.target.removeEventListener(event.type, this.switchContainersReference);
	
	
	var oldContainer = event.target;
	var newContainer = this.createContainer();
	
	
	var states = this.states;
	
	var newState = states[1];
	
	
	oldContainer.removeChild(newState);
	newContainer.appendChild(newState);
	
	
	var node = this.node;
	
	node.removeChild(oldContainer);
	node.appendChild(newContainer);
	
	this.container = newContainer;
	
	
	var thisReference = this;
	
	
	var containerStyle = window.getComputedStyle(newContainer);
	var transitionDuration = parseFloat(containerStyle.transitionDuration);
	
	var timer = new Timer(transitionDuration * 1000, 1);
	timer.addEventListener
	(
		TimerEvent.TIMER_FIRE,
		function(event)
		{
			thisReference.states.shift();
			
			if (thisReference.pendingState != null)
			{
				thisReference.addState(thisReference.pendingState);
				thisReference.pendingState = null;
			}
		}
	);
	timer.start();
};

export default RollAnimation;
