import React from 'react';
import moment from 'moment';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';
import customRequest from '../../../helpers/customRequest';
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import ClipLoader from "react-spinners/ClipLoader";

class CreateDiscountCode extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            code: getCode(5),
            amount: "",
            type: "Fixed",
            "minimum-students": 1,
            "maximum-students": 65535,
            expiration: moment().add(30, "days").format("YYYY-MM-DD HH:mm:ss"),
            comments: "",

            loading: false
        }
    }

    componentDidMount() {
        initializeFormValidation();

        initializeAutosubmit();
        initializeTextAreaSubmit();
        initializeSubmissionPrevention();

        // todo: check if code already exists (low probability but possible) and regenerate?
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var generateCodeForm = document.getElementById("create-discount-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(generateCodeForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            customRequest.post("/api/admin/discount-code", {
                code: this.state.code,
                amount: this.state.amount,
                type: this.state.type,
                minStudents: this.state["minimum-students"],
                maxStudents: this.state["maximum-students"],
                expiration: this.state["expiration"],
                comment: this.state["comments"]
            })
                .then(({ data }) => {
                    if (!data.valid) {
                        this.setState({ loading: false })
                        return message.error(data.message)
                    }

                    let redirectURL = "/admin/discount-code/success?code=" + this.state.code + "&amount=" + this.state.amount + "&type=" + this.state.type
                        + "&minimum-students=" + this.state["minimum-students"] + "&maximum-students=" + this.state["maximum-students"] + "&expiration=" + this.state.expiration

                    redirect.send(redirectURL)
                    message.success("Your sales code has been successfully generated, with details listed below.")
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    return message.error("There has been an internal error.")
                })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1 className="text-center section-header">Create Discount Code</h1>

                <form id="create-discount-form" className="form-horizontal form-validate" noValidate onSubmit={this.submitForm} method="post">
                    <div className="mb-3 row">
                        <label for="code" className="col-sm-3 control-label text-end">Code</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" id="code" name="code" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.code} placeholder="Code" required minLength="1" maxlength="175" data-description="enter a discount code" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="amount" className="col-sm-3 control-label text-end">Amount</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="number" className="form-control" id="amount" name="amount" min="1" step="1" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.amount} placeholder="Amount" required data-description="enter a discount amount" />

                                <span className="input-group-text">{ this.state.type === "Fixed" ? ".00" : "%" }</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-3 control-label text-end">Type</label>

                        <div className="col-sm-6 text-start text-center-xs">
                            <label className="radio-inline">
                                <input type="radio" name="type" id="type-Fixed" value="Fixed" disabled={this.state.loading} onChange={this.handleInputChange} checked={this.state.type === 'Fixed'} required />

                                <span>Fixed</span>
                            </label>

                            <label className="radio-inline ps-3">
                                <input type="radio" name="type" id="type-Percentage" value="Percentage" disabled={this.state.loading} onChange={this.handleInputChange} checked={this.state.type === 'Percentage'} required />

                                <span>Percentage</span>
                            </label>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="minimum-students" className="col-sm-3 control-label text-end">Minimum Students</label>

                        <div className="col-sm-6">
                            <input type="number" className="form-control" id="minimum-students" name="minimum-students" min="1" step="1" max="65535" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["minimum-students"]} required data-description="enter a minimum number of students for the discount code" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="maximum-students" className="col-sm-3 control-label text-end">Maximum Students</label>

                        <div className="col-sm-6">
                            <input type="number" className="form-control" id="maximum-students" name="maximum-students" min="1" step="1" max="65535" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["maximum-students"]} required data-description="enter a maximum number of students for the discount code" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="expiration" className="col-sm-3 control-label text-end">Expiration</label>

                        <div className="col-sm-6">
                            <input type="date" className="form-control" id="expiration" name="expiration" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.expiration} placeholder="YYYY-MM-DD HH:MM:SS" maxlength="19" required data-description="enter an expiration date and time" />
                        </div>
                    </div>


                    <div className="mb-3 row">
                        <label for="comments" className="hidden-xs col-sm-3 control-label text-end">Comments (optional)</label>

                        <div className="col-sm-6">
                            <textarea className="form-control" id="comments" name="comments" placeholder="Comments" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.comments} />
                        </div>
                    </div>


                    <button type="submit" className="btn btn-secondary btn-lg" disabled={this.state.loading}>
                        {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Create"}
                    </button>
                </form>

            </React.Fragment >
        )
    }

}

function getCode(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default CreateDiscountCode;
