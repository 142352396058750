import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Nav from "react-bootstrap/Nav"
import Image from "react-bootstrap/Image"
import { buildQuery, ReadingDonuts, MathDonuts } from "@stem-sims/great-leaps-react-common"
import BeatLoader from "react-spinners/BeatLoader"
import { Button, Modal } from "react-bootstrap"
import { useModel } from '@stem-sims/nexus';
import * as studentsModel from '../../shared/students'
import * as statusModel from '../../models/status'
import flipperLicense from "../../content/images/flipper_license.svg"
import PurchaseModal from "./PurchaseModal"

interface ProgramHeaderProps {
    initialProgram: studentsModel.ProgramsType
    studentID: string
    studentName: string
    refreshDonuts: boolean
    basePath: string
    readingActive?: boolean
    mathActive?: boolean
}

export default function ProgramHeader({ initialProgram, studentID, studentName, refreshDonuts, basePath, readingActive = true, mathActive = true }: ProgramHeaderProps) {
    const programType: studentsModel.ProgramsType = initialProgram.charAt(0).toUpperCase() + initialProgram.slice(1) as studentsModel.ProgramsType
    const [selectedProgram, setSelectedProgram] = React.useState<studentsModel.ProgramsType>(programType)
    const [donutsAreLoading, setDonutsAreLoading] = React.useState(true)
    const [purchaseModal, setPurchaseModal] = React.useState<studentsModel.ProgramsType | "">("")
    const [licenseModal, setLicenseModal] = React.useState<studentsModel.ProgramsType | "">("")
    const [licenseAmount, setLicenseAmount] = React.useState<number>(0)
    const [student, setStudent] = React.useState<studentsModel.StudentMoreInfo>()
    const standardVariables = buildQuery({ id: studentID })

    useEffect(()=>{
        if(!studentID) return
        studentsModel.getOne(studentID).then((studentRes)=>setStudent(studentRes.data))
    },[studentID])

    const { response: status, loading: statusLoading } = useModel({
        model: statusModel.get
    })

    function activateModal(program) {
        const licensesAvailable = status[program.toLowerCase()].available
        setLicenseAmount(licensesAvailable)
        if(licensesAvailable === 0) setPurchaseModal(program)
        else {
            setLicenseModal(program)
        }
    }   
    function assignLicense(program: studentsModel.ProgramsType) {
        // if they have license but this modal is triggered, its been archived
        const hasArchivedLicense = student.licenses.find((license) => license.program === program)
        if(hasArchivedLicense) {
            toast.error("This license has been archived.")
            setLicenseModal("")
            return
        }
        const newLicenses = [program, ...student.licenses.map((license)=>license.program)]
        studentsModel.updateOne(student,newLicenses).then((res)=>{
            toast.success("License Assigned!")
            setStudent({licenses: newLicenses, ...student})
            setLicenseModal("") 
            window.location.reload() // tmp fix on making UI update after license is assigned
        }).catch((err) => {
            toast.error(err.response ? err.response.data.message : "There has been an error")
        })
    }   

    return (<>
        <Nav
            variant="tabs"
            defaultActiveKey={programType}
            className="mb-4"
            onSelect={(eventKey: studentsModel.ProgramsType) => {
                setSelectedProgram(eventKey)
            }}
            fill
        >
            <Nav.Item onClick={()=> {if(!readingActive && !statusLoading) activateModal("Reading")}}>
                <Nav.Link
                    eventKey="Reading"
                    as={Link}
                    to={`${basePath}/phonics/phonics?${standardVariables}`}
                    disabled={!readingActive}
                >
                    Reading
                </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={()=> {if(!mathActive && !statusLoading) activateModal("Math")}}>
                <Nav.Link
                    eventKey="Math"
                    as={Link}
                    to={`${basePath}/addition?${standardVariables}`}
                    disabled={!mathActive}
                >
                    Math
                </Nav.Link>
            </Nav.Item>
        </Nav>
        {(statusLoading || donutsAreLoading) &&
            <div style={{ margin: "0 auto", display: "table"}}>
                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center", height: "215px"}}>
                    <BeatLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                        />
                </div>
            </div>
        }
        {selectedProgram === "Math" && <>
            <MathDonuts
                studentID={studentID}
                refresh={refreshDonuts}
                onLoaded={() => setDonutsAreLoading(false)}
            />
            <div className="py-2" />
        </>}

        {selectedProgram === "Reading" &&
            <ReadingDonuts
                studentID={studentID}
                refresh={refreshDonuts}
                onLoaded={() => setDonutsAreLoading(false)}
            />
        }
        <Modal show={!!licenseModal} onHide={()=>setLicenseModal("")}>
            <Modal.Header closeButton>
                Assign License
            </Modal.Header>
            <Modal.Body className="text-center">
                <p>You currently have {licenseAmount} {licenseModal} license(s) to assign</p>
                <div className="my-3">
                    <Image src={flipperLicense} className="mb-3"/>
                    <div className="bg-light-blue d-inline-block rounded px-4">
                        <span>1 {licenseModal} License</span>
                        <i className="fa fa-arrow-right gl-blue p-3"></i>
                        <span>{studentName}</span>
                    </div>
                </div>
                <p className="my-4">
                    Would you like to assign a <strong>{licenseModal} License</strong>  to <strong>{studentName}?</strong>
                </p>
                <Button onClick={()=>setLicenseModal("")} variant="secondary" className="mx-3">Cancel</Button>
                <Button onClick={()=>assignLicense(licenseModal as studentsModel.ProgramsType)} size="sm" className="mx-3">Assign License</Button>
            </Modal.Body>
        </Modal>
        <PurchaseModal modalType={purchaseModal} setModalType={setPurchaseModal}/>
    </>)
}
