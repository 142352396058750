import React from 'react';
import { Link } from 'react-router-dom';
import message from '../../../components/message/message';
import getURLParameters from '../../../helpers/getURLParams';
import redirect from '../../../components/redirect/redirect';

class GenerateCodesSuccess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            created: "",
            students: "",
            expiration: "",
            codes: [],

            valid: true
        }
    }

    componentDidMount() {
        let params = getURLParameters(this.props);

        if (!params.name || !params.created || !params.students || !params.expiration || !params.codes) {
            this.setState({ valid: false });
            message.error("You seem to have navigated to this page accidentally. If you're trying to access this page, please proceed back to the administration page using the button below..")
            return;
        }

        this.setState({
            name: params.name,
            created: params.created,
            students: params.students,
            expiration: params.expiration,
            codes: params.codes
        })
    }

    sendToEmailActivation = () => {
        let redirectUrl = "/admin/activation-codes/send-email?name=" + this.state.name + "&"
        this.state.codes.forEach(element => {
            redirectUrl += ("codes[]=" + element + "&")
        });
        redirect.send(redirectUrl)
    }

    sendToForceActivate = () => {
        let redirectUrl = "/admin/activation-codes/force-activate?"
        this.state.codes.forEach(element => {
            redirectUrl += ("codes[]=" + element + "&")
        });
        redirect.send(redirectUrl)
    }

    render() {

        if (!this.state.valid) {
            return (
                <React.Fragment>
                    <div className="redirect-container">
                        <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                            <Link to="/admin" className="btn btn-secondary btn-lg">Return to Administration Tools</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="alert alert-info">
                    <h4>Details:</h4>

                    <p><label>Students:</label> {this.state.students}</p>
                    <p><label>Expiration:</label> {this.state.created}</p>
                </div>

                <div className="alert alert-info">
                    <h4>Code(s):</h4>

                    <ul className="list-unstyled">
                        {
                            this.state.codes.map((code) => {
                                return <li>{code}</li>
                            })
                        }
                    </ul>
                </div>

                <div className="alert alert-info">
                    <p>If you want to email these codes, you can click the button below to have the information from this page pre-filled.</p>

                    <button className="btn btn-secondary btn-lg disabled" onClick={this.sendToEmailActivation}>Email Activation Links</button>
                </div>

                <div className="alert alert-info">
                <p>If you want to force-activate these codes, you can click the button below to have the information from this page pre-filled.</p>

                    <button className="btn btn-secondary btn-lg" onClick={this.sendToForceActivate}>Force-Activate Codes</button>
                </div>

                <div className="row">
                    <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                        <button className="btn btn-secondary btn-lg" onClick={this.props.history.goBack}>Return to Code Creation</button>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

export default GenerateCodesSuccess;
