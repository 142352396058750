import React from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function Testimonials() {

    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css" href="/styles/about.css?version=8-28-2021" />

            <div className="about-title-image text-center">
                <Container>
                    <h1 className="about-title-main mt-5">Testimonials</h1>
                    <Row className="justify-content-center mt-5">
                        <Col md={4} xl={3} className="my-2">
                            <a href="#parents" className="text-white">
                                <Button variant="success" className="w-100">Parents</Button>
                            </a>
                        </Col>
                        <Col md={4} xl={3} className="my-2">
                            <a href="#teachers" className="text-white">
                                <Button variant="success" className="w-100">Teachers & Administrators</Button>
                            </a>
                        </Col>
                        <Col md={4} xl={3} className="my-2">
                            <a href="#tutors" className="text-white">
                                <Button variant="success" className="w-100">Tutors</Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>

            <span className="anchor" id="parents"></span>
            <Container id="research" fluid="lg" className="xxl">
                <div id="parents" >
                    <h1>Parents</h1>
                    <div className="testimonial">
                        <p className="testimonial-text">“When my daughter discovered that her subscription expired, she was shocked & dismayed. She grabbed a book,
                        made me set a timer, and after her read she wanted me to write her a list of words and phrases. It amazed me how she’d gone from being
                        convinced to include fluency work in her day to insisting on it!”</p>
                        <p className="testimonial-name">Alicia F.</p>
                        <p className="testimonial-details">Parent, Washington</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">"My child is excelling, and looks forward to his next steps in education in middle school. To say Great Leaps
                        has changed my son's life and future is an UNDERSTATEMENT... Just think, a child's success in life can be directly impacted by this program
                        in only fifteen minutes a day!"</p>
                        <p className="testimonial-name">Carolynn K.</p>
                        <p className="testimonial-details">Parent, Maryland</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“Just checking in to let you know (as you probably do) that my son has increased his fluency in the past 7
                        weeks by an unbelievable amount. Today he read at 107 WPM! And the story he was only reading for the second time and single word list for
                        the FIRST TIME!!! We religiously do GL, every day for 10 minutes, and I don't know what the magic sauce is, but thank you for the
                        encouragement to get started. He's so proud of himself.”</p>
                        <p className="testimonial-name">April B.</p>
                        <p className="testimonial-details">Parent, California</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“I have a sweet, active 8 year old, little girl who struggles with comprehension. In my head, we just needed
                        to read more and ask more questions… I had no idea there was more to comprehension than that. One of the many things that was identified
                        which affected her comprehension was that she was not self-correcting when she read. I never picked up on that and didn’t realize that was
                        playing a huge part in her lack of comprehension. After just 3 weeks with Great Leaps she is self-correcting when she reads. She loves
                        doing Great Leaps. Neither one of us are frustrated at the end and I am seeing incredible growth already!...</p>
                        <p className="testimonial-text">“I wrote an update about 4 weeks ago and just have to write another one! We started at the end of the school
                        year, had lots of family in town and went on a month long vacation as a family. Through all of that it has been so easy and fun to continue
                        Great Leaps. It’s even more fun because of the results I am seeing in Elsie. At 7 weeks I am seeing her finally pause at periods which
                        allows her to actually make sense of what she is reading. We’ve also seen her begin to tell stories and they have a beginning, middle and
                        end. Before she started Great Leaps I just thought “she’s not a story teller. She doesn’t like to talk about her day. She’d just rather be
                        playing than talking to me.” I honestly didn’t think to much about it and then this summer she is telling me what happened on a hike with
                        her dad. She told me the entire process of how she made a keychain with her Grammy. Before when I would ask her a question about what she
                        did or how she did something her response usually was, “I don’t know!” as she jumps up to go play. I don’t know if Great Leaps has just
                        given her the confidence to say what she wants to say or if what she is learning about reading and comprehending, has given her the ability
                        to recall and want to tell us about the things she is doing. Either way, I am so thankful for Great Leaps and the difference it is making in
                        my little girl’s life!”</p>
                        <p className="testimonial-name">Jessica H.</p>
                        <p className="testimonial-details">Parent, Florida</p>
                    </div>
                </div>

                <span className="anchor" id="teachers"></span>
                <div id="teachers" >
                    <h1>Teachers & Administrators</h1>
                    <div className="testimonial">
                        <p className="testimonial-text">“Just wanted to drop you a line to tell you how thrilled I am with Great Leaps. I've been working with Nelson 
                        for 3 weeks now and he's making consistent progress and we're both loving our time reading.”</p>
                        <p className="testimonial-name">Kim D.</p>
                        <p className="testimonial-details">IEP Teacher, New York</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“Dylan and I have really been enjoying the Great Leaps program. I must admit that I was skeptical at first. I 
                        am a Reading Recovery teacher and word lists in isolation stretch my philosophy. What I have loved about Great Leaps for Dylan is that it 
                        really forces him to look beyond first letter to know for sure. We have had lots of great conversations based around his attempts of words 
                        and his ability to confirm for himself. I also love that it lifts some of his confusions for me that aren't always present when reading in 
                        connected text. Dylan really reads for meaning so he shines when he can integrate sources but when all that is eliminated and he can only 
                        visually scan, it helps me to see areas of weakness in a sequential manner. My school psych often talks about rewiring the brain and 
                        connecting a loop or creating a pathway where there is not one. I think Great Leaps is helping Dylan's brain to do this!”</p>
                        <p className="testimonial-name">Mrs. S.</p>
                        <p className="testimonial-details">Teacher, Wisconsin</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“School is finally underway and the results have been astounding from the summer. Everyone has made progress 
                        (no summer slump) and it's showing with their confidence and reading levels! I was talking with one of my colleagues and we would love to 
                        put a proposal together to get a few of our kids accounts for the school year. Any chance you could send us a quote so we could propose it 
                        to our administrators?”</p>
                        <p className="testimonial-name">Naomi R.</p>
                        <p className="testimonial-details">Learning Specialist, California</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“I must reiterate to you how wonderful I think your program is. I have close to 30 years of teaching behind me 
                        and this is a program that I think is really worth every bit of hype it gets, and does what it sets out to do.”</p>
                        <p className="testimonial-name">Galia M.</p>
                        <p className="testimonial-details">Executive Director, A.H.A.V.A. English Literacy Program, Israel</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“I have a student during the corona virus who is a 4th grade special ed student reading on a 2nd grade level. In 
                        less then 2 months on digital Great Leaps and Zoom, he has made 1.5 years of growth and between 90/100% fluency. Today, this child who 'did 
                        not like to read' quietly asked me, 'Can I still work on this for the summer....and next year when we get back to school? I want to still do 
                        this.' He goes between two households due to divorce. Both sets of parents make sure he is on the platform such that he has been with me 4-5 
                        days a week since he started. They are amazed at his progress and cannot stop thanking me. He is so excited when he makes a goal and tells me 
                        'I am going to rock it tomorrow!' When the program is done with fidelity, it is just short of amazing. I want to bring this to other student 
                        at my school.”</p>
                        <p className="testimonial-name">Alessandra B.</p>
                        <p className="testimonial-details">Teacher, New York City</p>
                    </div>
                </div>

                <span className="anchor" id="tutors"></span>
                <div id="tutors" >
                    <h1>Tutors</h1>
                    <div className="testimonial">
                        <p className="testimonial-text">“Just sharing a Great Leaps success: I’ve been working with a middle-school student who had been struggling 
                        with the traditional dyslexia intervention I do that usually works well, because he has significant difficulty with memory and hearing 
                        sounds accurately. He made progress for a while but then hit a wall as there were more rules to learn and apply. We added Great Leaps in 
                        December and when I checked his progress in May, the only significant improvement was in his fluency, so I suggested to his parents that 
                        we drop everything else and just do Great Leaps 4 times a week. I re-checked his progress today on a graded word list and passage reading 
                        assessment and he has progressed almost a full year since I checked him in May. It was the most significant movement in a year and a half! 
                        Overall, since he’s started 31 weeks ago, he’s gained 3 years in the passage fluency. (He placed low because of his errors and tendency to
                        guess.) I’m looking forward to seeing his final results when we reach the finish.”</p>
                        <p className="testimonial-name">Dr. Janet M.</p>
                        <p className="testimonial-details">Dyslexia Reading Tutor, Pennsylvania</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“I am a Certified Barton Tutor and I use Great Leaps as a tool to help with fluency and accuracy. It has been 
                        a fantastic resource. Not only does it improve their fluency but it is a great confidence booster. They love to see how far they have come 
                        and how much faster they can read. It gives them such a great benchmark to see their progress.”</p>
                        <p className="testimonial-name">Tiffany V.</p>
                        <p className="testimonial-details">Certified Barton Tutor, Florida</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">“As a Reading Corps tutor, I use Great Leaps for students who are struggling in reading. Ever since the program 
                        was introduced to my students they have been nothing short of excited. The absolute joy and sense of accomplishment when they leap a page is 
                        proof that this program is needed in more reading classrooms across the country. The program provides many opportunities for praise, so even 
                        if students are not able to leap a page, they can still see the gains they make each day especially with the progress chart. As a future 
                        Reading Specialist, I am thrilled that I was introduced to this program that encompasses so many skills that unfortunately many kids struggle 
                        with today. I will definitely have in my professional library for years to come.”</p>
                        <p className="testimonial-name">Nandi-Ayanna M.</p>
                        <p className="testimonial-details">Tutor, District of Columbia</p>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )

}

export default Testimonials;
