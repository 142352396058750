


import React, { ElementType, useContext } from 'react'
import * as studentsModel from "../shared/students"
import { ReactTable, useModel } from '@stem-sims/nexus'
import BeatLoader from 'react-spinners/BeatLoader'
import Form from 'react-bootstrap/Form'
import { RecordContext } from '../pages/records/RecordsPage'
import { ChartType } from '../pages/records/components/Chart'

type StudentRosterProps = {
    Roster?: ElementType | undefined
    clickRow?: ((row:any, currentChart: ChartType) => void) | undefined
    validLicenses?: Array<"Math" | "Reading">
}

export function FilterByInstructor({instructors, setFilterValue}) {
    return(<>
        <Form.Control as="select" defaultValue={""} onChange={(e)=> setFilterValue(e.currentTarget.value)}>
            <option value={""} disabled>Select Instructor</option>
            <option value={""}>None</option>
            {instructors.map((instructor,i) => 
                <option value={instructor} key={instructor + i}>{instructor}</option>
            )}
        </Form.Control>
    </>)
}

export default function StudentRoster({Roster=undefined, clickRow=() => null, validLicenses=[]}: StudentRosterProps) {
    const context = useContext(RecordContext)

    const { response: studentList, loading: studentsLoading } = useModel({
        model: studentsModel.getAll
    })

    const columns = [
        {
            Header: 'Student Name',
            accessor: 'name',
        },
        {
            Header: 'Shared From',
            accessor: 'owner',
            maxWidth: 90,
            Cell: ({ row }) => {
                return <p>{row.original?.owner?.username ?? ""}</p>
            }
        },
    ]

    if(studentsLoading) {
        var render = <div className='text-center'><BeatLoader size={10} color={"#123abc"}/></div>
    }
    else if(!Roster) {
        if(clickRow) var classString = "cursor-pointer"
        var customClass = (row) => `${row.original.fidelityWarning && "fidelity-warning"} ${classString}`
        render = <ReactTable columns={columns} bulkActions={[]} data={studentList.data} sortBy={[{ id: "name" }]} clickRow={(row)=>clickRow(row,context.currentChart)} customClass={customClass} hidePagination/>
    }
    else {
        render = <Roster columns={columns} data={studentList.data} clickRow={(row)=>clickRow(row,context.currentChart)} validLicenses={validLicenses} />
    }

    return(<>
        {render}
    </>)
}
