import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { Error } from './types/error'
import { Subscription } from './subscriptions'
export interface Status extends Error {
    reading: LicenseStatus
    math: LicenseStatus,
    expiringSoon: Subscription[]
}
export interface LicenseStatus {
    active : number,
    used : number,
    available : number,
    delegated: number,
}

/**
 * Gets the subscription status of the account
 */
export function get(customerUsername?: string, groupName?: string) : Promise<Status> {
    return modelRequest.get('/api/status', {
        params: {
            customerUsername: customerUsername,
            group: groupName
        }
    })
}
