import React from 'react'
import axios from 'axios';
import StudentForm from './StudentForm'
import * as statusModel from '../../../models/status'
import { useModel } from '@stem-sims/nexus';
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify';
import { useRoster } from './StudentsContext';
import { ProgramsType } from '../../../shared/students';

type AddProps = {
    show: boolean
}

function Add(props: AddProps) {
    const roster = useRoster()
    return (<>
        <Modal show={props.show} onHide={roster.hideModals} size={"lg"} className="student-modal">
            <AddForm />
        </Modal>
    </>)
}

function AddForm() {
    const roster = useRoster()
    const { response: status, loading: statusLoading } = useModel({
        model: statusModel.get
    })

    const disabledPrograms: ProgramsType[] = []
    const defaultProgramsSelected: ProgramsType[] = []

    if (!statusLoading) {
        if (status.math.available === 0) {
            disabledPrograms.push("Math")
        }
        if (status.reading.available === 0) {
            disabledPrograms.push("Reading")
        }

        if (disabledPrograms.length === 1) {
            defaultProgramsSelected.push((disabledPrograms[0] === "Reading") ? "Math" : "Reading")
        }
        else if (disabledPrograms.length === 2) {
            toast.error("There aren't enough licenses to add a student")
            roster.refreshStudents()
            roster.hideModals()
        }
    }
    return (
        <StudentForm
                onSubmit={async (values) => {
                    try {
                        await axios.post('/api/students/create', values)
                        roster.refreshStudents()
                        roster.hideModals()
                        toast.success("Your student has been created")
                    }
                    catch(error) {
                        toast.error(error?.response?.data?.message ?? "There has been an error creating your student.")
                    }
                }}
                disabledPrograms={disabledPrograms}
                submitLabel="Add"
                initialValues={{
                    name: "",
                    birthDay: "",
                    programs: defaultProgramsSelected
                }}
            />
    )
}

export default Add
