import { modelRequest } from "@stem-sims/great-leaps-react-common"

export interface StatesReturns {
    State: string
    "Short Code": string
}

export function get(country: string): Promise<StatesReturns[]> {
    return modelRequest.get("/api/states?country=" + country)
}
