import Message from './message';
import Input from '../input';

function WarningMessage(text)
{
	this.parent.constructor.call(this, Input.warning, text);
}

WarningMessage.prototype = new Message();
WarningMessage.prototype.parent = Message.prototype;
WarningMessage.prototype.constructor = WarningMessage;

export default WarningMessage;
