import { modelRequest } from '@stem-sims/great-leaps-react-common'



interface PostPrams {
    /**
     * emails and usernames of users
     */
    entries: string[]
    group?: string
    permission?: 'Instructor' | 'Manager' | 'Licensee'
    studentLimit?: number
    organizationID?: string
}

/**
 * Invites users to organization
 */
export function post(parms: PostPrams) : Promise<{ valid: boolean, message: string }> {
    return modelRequest.post('/api/organizations/invite', parms)
}
