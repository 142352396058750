import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Spinner from "react-bootstrap/Spinner"
import Image from "react-bootstrap/Image"
import carolyn_testimonial from '../../content/images/carolyn-testimonial.png'
import galia_testimonial from '../../content/images/galia-testimonial.png'
import jessica_testimonial from '../../content/images/jessica-testimonial.png'
import john_testimonial from '../../content/images/john.jpg'
import dgl_with_boy from '../../content/images/dgl-with-boy.jpg'
import grade_graph from '../../content/images/grade-graph.png'
import logoDark from '../../content/images/logo_dark.svg'
import icon1 from '../../content/images/icon1.svg'
import icon2 from '../../content/images/icon2.svg'
import icon3 from '../../content/images/icon3.svg'
import blobLeft from '../../content/images/blob_left.svg'
import homeBanner from "../../content/images/home_banner.png"
import blobRight from "../../content/images/blob_right.png"

function LandingHeader({line1,line2,children}) {
    return(<>
      <div className='overlayLandingTitle me-md-n3 text-md-start ps-md-5 word-nowrap'>
        <img src={logoDark} className="header-logo img-fluid d-none d-md-block mb-3" alt="Great Leaps logo of two dolphins leaping out of water"/>
        <h1 className="h1 mb-3">{line1}<br/>{line2}</h1>
        <p className="lead pb-3">{children}</p>
      </div>
    </>)
}
export function PageSection(props) {
    return (<>
      <section className={`${props.sectionBreak && "section-break"} position-relative`}>
        <h2 className={`text-center ${!props.heading && "d-none"} pb-5`}>{props.heading}</h2>
        <Row className={`${props.reverse && "flex-row-reverse"} ${props.fullScreen && "full-screen"}  page-section row-cols-1 row-cols-md-2 justify-content-center`}>
          {props.children}
        </Row>
      </section>
    </>)
}

function ParentBlock({name, imgSrc, location, children}) {
  const [loaded,setLoaded] = useState(false)
  
  return (
      <div className="testimonial-item my-4 p-4">
        <Row>
          <p className="font-light text-start"><i className="fas fa-quote-left me-2" />{children}</p>
        </Row>
        <div className="crop-wrapper text-start px-2 pb-2">
          <img onLoad={() => setLoaded(true)} className="d-inline-block rounded-circle" src={imgSrc} alt={`picture of ${name}`} />
          {!loaded && 
            <Spinner animation="grow" variant="primary" aria-label="tutor profile is loading"/>
          }
          <Col className="d-inline-flex flex-column ">
            <span className='ms-3'>{name}</span>
            <span className='font-light ms-3'>{location}</span>
          </Col>
        </div>
      </div>
  )
}

function IconBlock({title, iconSrc, children, to=null, iconLabel}) {
    return (
      <Col md={4} className="icon-block move-forward">
        <div className="item mx-auto mb-5 mb-lg-3 h-100">
          {to !== null ?
            <a href={to}>
              <img src={iconSrc} className="icon" alt={iconLabel} />
              <h3 className="h5 pb-2 mt-4">{title}</h3>
              <p className="font-md">{children}</p>
            </a>
            :
            <>
              <img src={iconSrc} className="icon" alt={iconLabel} />
              <h3 className="h5 pb-2 mt-4">{title}</h3>
              <p className="font-md">{children}</p>
            </>
          }
        </div>
      </Col>
    )
  }

function Home() {
    return (<div id="home" data-testid="home-page-loaded">
        <link rel="stylesheet" type="text/css"/>
        <PageSection reverse>
            <Col lg={7} xl={6} className='d-flex mt-n2 g-0'>
                <Image src={blobRight}  className="blob right h-100 mt-n4" alt="green cartoon splash of water in background"/>
                <Image src={homeBanner}  className="img-fluid h-100 m-auto move-forward mt-n5 p-5" alt="dolphin with eye patch swimming above underwater book"/>
            </Col>
            <Col lg={4} className="m-auto pb-5">
                <LandingHeader 
                    line1={'Great Leaps Digital'} 
                    line2={'Reading Program'} 
                    >
                    Speed. Accuracy. Comprehension.
                </LandingHeader>
            </Col>
        </PageSection>
        <Image src={blobLeft} className="position-absolute img-fluid"/>
        <Container className='text-center'>
            <PageSection heading="Join over 600,000 students who have made Great Leaps with our programs!" sectionBreak>
                <IconBlock iconSrc={icon1} title={"Phonics"} iconLabel="A lowercase letter a">
                    Systematically teaches students essential phonics skills to full understanding (proficiency).
                </IconBlock>
                <IconBlock iconSrc={icon2} title={"Sight Phrases"} iconLabel="Two double quotes">
                    Builds reading speed and accuracy through eliminating high frequency word errors.
                </IconBlock>
                <IconBlock iconSrc={icon3} title={"Stories"} iconLabel="An open book">
                    Develops fluent reading with proper intonation and comprehension through fun and engaging age-appropriate stories.
                </IconBlock>
            </PageSection>
        </Container>
        <div className="section-pic-left mt-5">
            <div className="row">
                <div className="col-sm-12 col-md-6 vcenter py-5 pe-5">
                    <div className="circle-cropper ms-n5">
                        <img src={dgl_with_boy} className="home-circle rounded" alt="Boy being tutored using the Great Leaps program" />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex flex-column m-auto section-text">
                    <h2 className="h3 fw-bold mx-2 section-title">Digital Reading Intervention</h2>
                    <ul className="check-mark-list text-start">
                        <li>For schools, tutors or parents teaching K-12 students that read below the 5th Grade Level who may have learning disabilities such as dyslexia or ADHD</li>
                        <li><span>Well recognized for over 20 years as a premier </span>
                            <Link to="/about/research" style={{ textDecoration: "underline" }}>research and evidence</Link> 
                            <span> based reading intervention</span>
                        </li>
                        <li>All it takes is 15 minutes a day, three to five days per week with one on one instruction</li>
                    </ul>
                    <div>
                        <Link to="/about/the-program" className="btn btn-success">Learn More <span className="fas fa-chevron-right arrow-icon"></span></Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="content-section-w section-pic-right">
            <div className="row">
                <div className="col-md-6 vcenter section-text">
                    <h2 className="section-title">The Results</h2>
                    <ul className="check-mark-list text-start">
                        <li>Our students have averaged a full year of reading growth with just 22 total hours of instruction</li>
                        <li>Parents and teachers report a decrease in behavioral referrals and academic frustration in the home</li>
                        <li>Fluent reading generalizes to improvements in all other areas of academics and standardized testing</li>
                    </ul>
                    <div>
                        <Link to='/about/research' className="btn btn-success">Research & Evidence</Link>
                    </div>
                </div>
                <div className="col-md-6 vcenter">
                    <img src={grade_graph} id="home-grade-graph" alt="A graph showing reading growth vs. weeks in program"/>
                </div>
            </div>
        </div>
        <PageSection header={"What Parents Are Saying…"}>
          <Col xs={10} md={5} xl={4} className="me-md-3">
            <ParentBlock imgSrc={carolyn_testimonial} name="Carolynn K." location="Parent">
                &quot;My child is excelling, and looks forward to his next steps in education
                in middle school. To say Great Leaps has changed my son&apos;s life and
                future is an UNDERSTATEMENT... Just think, a child&apos;s success in life
                can be directly impacted by this program in only fifteen minutes a day!&quot;
            </ParentBlock>
            <ParentBlock imgSrc={galia_testimonial} name="Galia M." location="Executive Director, A.H.A.V.A. English Literacy Program">
            &#x201C;I must reiterate to you how wonderful I think your program is.
                I have close to 30 years of teaching behind me and this is a program that
                I think is really worth every bit of hype it gets, and does what it sets
                out to do.&#x201D;            
            </ParentBlock>
          </Col>
          <Col xs={10} md={5} xl={4} className="mt-md-6 mb-4 ms-md-3">
            <ParentBlock imgSrc={jessica_testimonial} name="Jessica H." location="Parent">
                &#x201C;My 8 year old has been doing Great Leaps for 7 weeks now. We started
                at the end of the school year, had lots of family in town and went on a
                month long vacation as a family. Through all of that it has been so easy
                and fun to continue Great Leaps. It&#x2019;s even more fun because of the
                results I am seeing in Elsie. At 3 weeks I was seeing her self correct.
                I was ecstatic!&#x201D;
            </ParentBlock>
            <ParentBlock imgSrc={john_testimonial} name="John M." location="Parent">
              Asher quite literally changed the course of our son’s life. He was a tutor and a role model, switching struggles from frustrations into challenges, and challenges into proud moments. Catching up on skill and confidence in reading has shifted his relationship to school and all classes, and online tutoring has helped deepen his investment and understanding of remote learning. Without this experience, there’s really no telling how much farther behind he would’ve fallen, or how upset with himself he might have become.
            </ParentBlock>
          </Col>
        </PageSection>
        <div className="row alt-background-color">
            <div className="text-center my-4"> 
                <Link to="/about/testimonials" className="btn btn-success">View Testimonials <span className="fas fa-chevron-right arrow-icon"></span></Link>
            </div>
        </div>
        <div className="content-section-w section-mid youtube-section">
        <h1 className="text-center h2 fw-bold">Testimonials</h1>
            <div className="row text-center mt-4">
                <div className="col-sm-6 col-md-4 mt-md-1">
                    <h2>Ashley's Story</h2>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube-nocookie.com/embed/fuWnr3ZBUFk" allowFullScreen title="Ashley's Story"></iframe>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 mt-md-1">
                    <h2>Camille's Story</h2>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube-nocookie.com/embed/UFIpvXehIJg" allowFullScreen title="Rachel's Story"></iframe>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 mt-sm-3 mt-md-1">
                    <h2>Reading Crisis</h2>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube-nocookie.com/embed/h70sQ5CXFtw" allowFullScreen title="Lily's Story"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

export default Home;
