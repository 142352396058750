import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { Error } from './types/error'
import { Permission } from './organizationJoinGroup'

export type OrgUser = {
    permission: Permission,
    studentLimit: number,
    groupName: string
}
export interface Instructor extends OrgUser {
    name: string,
    username: string,
}
export interface Invite extends OrgUser {
    email: string
}
export interface Group {
    name: string,
    studentLimit: number | null,
    instructors: Instructor[]
}
export interface OrgUsersResponse extends Error {
    licencees: Instructor[],
    groups: Group[],
    invites: Invite[]
}

/**
 * Gets the organization users
 */
export function get() : Promise<OrgUsersResponse> {
    return modelRequest.get('/api/organizations/users')
}
