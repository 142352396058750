import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import buildQuery from '../../helpers/buildQuery'
import { useHistory, useLocation } from 'react-router-dom'
import crab from "../../content/images/crab.svg"
import star from "../../content/images/star_goal.svg"
import dolphin from "../../content/images/dolphin_approach.svg"
import Container from 'react-bootstrap/Container'
import ConfettiExplosion from 'react-confetti-explosion';

export default function Goal() {
    const history = useHistory()
    const location = useLocation<{nextScreen}>()
    /** 
    * @abstract Redirects to probe list
    */
    const redirectProbeList = async (redirect="/student/dashboard") => {
        history.push(redirect)
    }
    return(<div id="goal-page">
    <Container className="">
        <Card id="probe" className='shadow goal-card py-5 mx-auto'>
            <ConfettiExplosion className='confetti-center'/>       
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                <Image src={star} className="star img-fluid mb-4 me-n2" />
                <h2 className='fw-bold'>You've Reached a Goal!</h2>
                <p className='lead'>
                    Congrats!
                </p>
                <Button className='px-5' onClick={()=> redirectProbeList(location.state?.nextScreen)}>Continue</Button>
            </div>
        </Card>
        <Image src={crab} width={100} className="img-fluid crab-goal" />
        <Image src={dolphin} width={400} className="img-fluid dolphin-goal" />
    </Container>
    </div>)
}
