import React from 'react'
import { Link } from 'react-router-dom'

class NotFound extends React.Component {
    render() {
        return (
        <React.Fragment>
            <div className="alert alert-danger">The page you requested could not be found.</div>
            <p className='text-center'>You can now proceed back to our <Link to = "/">home page</Link>, or use any of the links in the navigation bar above to continue exploring our website.</p>
        </React.Fragment> )
    }
}

export default NotFound
