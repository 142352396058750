import Message from './message';

function NoMessage()
{
	this.parent.constructor.call(this, "", "");
}

NoMessage.prototype = new Message();
NoMessage.prototype.parent = Message.prototype;
NoMessage.prototype.constructor = NoMessage;

export default NoMessage;
