/** eventsService */
export const eventManager = {
    callbacks: {},
    nameCounts: new Map<String, Number>(),
  
    /**
     * Trigger all events with eventName
     * 
     * @param {string} eventName
     * @param {*} data
     */
    triggerEvent(eventName: string, data = null) {
      if (this.callbacks[eventName]) {
        Object.keys(this.callbacks[eventName]).forEach((id) => {
          this.callbacks[eventName][id](data);
        });
      }
    },
    
    /**
     * Trigger event that was most recently added
     * 
     * @param {string} eventName
     * @param {*} data 
     */
    triggerRecentEvent(eventName: string, data = null) {
      if (this.callbacks[eventName]?.[this.nameCounts[eventName] - 1]) {
        this.callbacks[eventName][this.nameCounts[eventName] - 1](data);
      }
    },
  
    /**
     * @param {string} eventName name of event
     * @param {Function} callback
     * @returns {number} callback identifier
     */
    listenEvent(eventName: string, callback: () => void) {
      if (!this.callbacks[eventName])
      {
        this.callbacks[eventName] = {}
        this.nameCounts[eventName] = 0
      }
      let id = this.nameCounts[eventName]

      this.callbacks[eventName][id] = callback;
      this.nameCounts[eventName] = id + 1
      return id
    },
  
    /**
     * @param {string} eventName name of event
     * @param {number} id callback identifier
     */
    unlistenEvent(eventName: string, id: number) {
      delete this.callbacks[eventName][id]
      this.nameCounts[eventName] -= 1
    },
  };
export default eventManager