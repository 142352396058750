import React from 'react'
import * as organizationGroups from '../../../models/organizationGroups'
import Form from '../../../components/forms/Form'
import SubmitButton from '../../../components/forms/SubmitButton'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'
import getURLParams from '../../../helpers/getURLParams'

export default class RemoveGroup extends React.Component<{ updatePermissions: () => Promise<void> }, { studentName: string }> {

    state = {
        studentName: ''
    }

    componentDidMount() {
        let props: { name: string } = getURLParams(this.props)
        this.setState({ studentName: props.name })
    }

    submit = (values) => {
        return organizationGroups.remove(this.state.studentName)
        .then((response) => {
            if (!response.valid) {
                return message.error(response.message)
            }
            
            redirect.send('/manage', this.props)
        })
    }

    render() {
        return (<div className='mt-4 text-center'>
            <h1>Remove Group</h1>
            <Form submit={this.submit}>
                {submitting => {
                    return (<>
                        <div className = "mb-3 row">
                            <label className = "d-none d-sm-inline col-sm-6 control-label text-end">Name</label>
                            <div className = "col-sm-6">
                                <p className = "form-control-static text-start text-center-xs break-words">{this.state.studentName}</p>
                            </div>
                        </div>
                        <SubmitButton submitting={submitting}>Remove</SubmitButton>
                    </>)
                }}
            </Form>
        </div>)
    }

}
