import { modelRequest } from "@stem-sims/great-leaps-react-common"

/**
 * Logs session into a given username
 * @param username user to login to
 */
export function forceLogin(username: string) {
    return modelRequest.post("/api/admin/force-login", {
        username: username
    })
}

