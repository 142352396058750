import React from 'react'
import Selection from '../forms/Selection'
import * as status from '../../models/status'

export default class StudentLimitSelection extends React.Component<{
    submitting: boolean,
    className?: string,
    value?: number,
    studentsAvailable?: number
}, {
    loading: boolean,
    studentsAvailable: number
}> {
    state = {
        loading: false,
        studentsAvailable: null
    }
    componentDidMount() {
        status.get()
        .then((status) => {
            this.setState({ studentsAvailable: status.reading.available })
        })
    }

    render() {
        let opts = ['No Limit']

        for (let i = 0; i < this.state.studentsAvailable ?? 0; i++) {
            opts.push(String(i + 1))
        }

        return <Selection
                    name="Student Limit"
                    className={this.props.className}
                    longName="Student Limit"
                    submitting={this.props.submitting || this.state.loading}
                    options={opts}
                    defaultValue={String(this.props.value)}
                />
    }
}
