import { modelRequest } from '@stem-sims/great-leaps-react-common'
import { Error } from './types/error'

export type Permission = 'Instructor' | 'Manager' | 'Licensee'

/**
 * Joins a group
 * 
 * @param groupName name of group
 * @param usernames usernames of who to join
 * @param permission permission of user
 */
export function post(groupName: string, usernames: string[], permission: Permission = 'Instructor') : Promise<Error> {
    return modelRequest.post('/api/organizations/groups/join', {
        name: groupName,
        usernames: usernames,
        permission: permission
    })
}
