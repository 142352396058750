import BasicEvent from './basic-event';

function TimerEvent(type)
{
	this.parent.constructor.call(this, type);
}

TimerEvent.TIMER_FIRE = "com.athenaed.timerFire";
TimerEvent.TIMER_COMPLETE = "com.athenaed.timerComplete";

TimerEvent.prototype = new BasicEvent();
TimerEvent.prototype.parent = BasicEvent.prototype;
TimerEvent.prototype.constructor = TimerEvent;

export default TimerEvent;
